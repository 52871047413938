<template>
    <div>
        <project-search-comp
                :projects="projects" :loading="loading" :pagination="pagination" :pages="pages"
                @setPage="setPage" @getProjects="getProjects" @getMoreProjects="getMoreProjects"
                :az-ops-title="$t('project.participant.search.noMyProjects.title')"
                :az-ops-message="$t('project.participant.search.noMyProjects.message')"></project-search-comp>
    </div>
</template>

<script>
    import _ from 'lodash'
    import {mapActions, mapMutations, mapState} from 'vuex'
    import {actionTypes, mutationTypes} from '@/commons/constants'
    import ProjectSearchComp from './ProjectSearchComp'


    export default {
        name: 'participant-my-project-search',
        components: {ProjectSearchComp},
        data() {
            return {
                loading: false,
                internalFilters: _.cloneDeep(this.$store.state.projectSearch.filters),
                pagination: {page: 1, rowsPerPage: 3, sortBy: 'createdAt', descending: true},
                pages: 0,
                projects: undefined
            }
        },
        computed: {
            ...mapState(['company'])
        },
        methods: {
            async getMoreProjects() {
                this.loading = true
                this.pagination.page = this.pagination.page + 1
                this.setProjectSearchPagination(this.pagination)
                const result = await this.findProjects()
                this.projects = this.projects.concat(result.content)
                this.pages = result.pages
                this.loading = false
            },
            async getProjects() {
                this.internalFilters.status.value = 'PUBLISHED'
                this.internalFilters.companyId.value = this.company._id
                this.setProjectSearchFilters(this.internalFilters)
                this.setProjectSearchPagination(this.pagination)
                const result = await this.findProjects()
                this.projects = result.content
                this.pages = result.pages
            },
            setPage(page) {
                this.pagination.page = page
            },
            ...mapActions([actionTypes.FIND_PROJECTS]),
            ...mapMutations([
                mutationTypes.SET_PROJECT_SEARCH_FILTERS,
                mutationTypes.SET_PROJECT_SEARCH_PAGINATION
            ])
        },
        async mounted() {
            await this.getProjects()
        }
    }
</script>

<style lang="stylus">
</style>
