<template>
    <div class="mt-3 pb-3">
        <div class="az-form-content" v-if="hasDropouts">
            <v-container fluid grid-list-lg>
                <project-dropouts-table :dropouts="dropouts"/>
            </v-container>
        </div>
        <az-ops
            :title="$t('project.admin.edit.tabs.dropouts.noDropoutsFound')"
            message=""
            v-else/>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import {actionTypes, mutationTypes} from '@/commons/constants'
    import ProjectDropoutsTable from './ProjectDropoutsTable'

    export default {
        components: {ProjectDropoutsTable},
        name: 'edit-project-dropouts',
        data() {
            return {
                dropouts: []
            }
        },
        computed: {
            ...mapState(['project']),
            hasDropouts() {
                return this.project.dropouts && this.project.dropouts.length > 0
            }
        },
        async mounted() {
            this.cleanProjectData()
            await this.findProjectById()
            await this.findProjectDropouts()
        },
        methods: {
            cleanProjectData() {
                this.$store.commit(mutationTypes.SET_PROJECT, {
                    calendar: {},
                    categories: [],
                    locations: [],
                    termsAndConditions: [],
                    participants: [],
                    dropouts: []
                })
            },
            async findProjectById() {
                try {
                    await this.$store.dispatch(actionTypes.FIND_PROJECT_BY_ID, this.$route.params.id)
                } catch (e) {
                    this.showErrorFrontEnd('project.admin.edit.errors.projectNotFound')
                    this.$router.push({name: 'projects'})
                }
            },
            async findProjectDropouts() {
                this.dropouts = await this.$store.dispatch(actionTypes.FIND_PROJECT_DROPOUTS, this.$route.params.id)
            }
        }
    }
</script>