<template>
    <div>
        <az-back-button :route="{name: 'customers'}" :text="$t('customer.edit.back')"/>
        <az-container>
            <div>
                <div class="az-form-content">
                    <customer-form
                        :editing="editing"
                        @save="update"
                        @cancel="cancel"
                        v-model="customer"/>
                </div>
            </div>
        </az-container>
    </div>
</template>

<script>
    import {actionTypes} from '@/commons/constants'
    import CustomerForm from './CustomerForm'

    export default {
        name: 'edit-customer',
        components: {
            CustomerForm
        },
        data() {
            return {
                editing: false,
                customerId: null,
                customer: {
                    logoSlug: '',
                    address: {city: ''}
                }
            }
        },
        async mounted() {
            this.setViewMode()
            await this.loadCustomer()
            this.closeAsideMenu()
        },
        methods: {
            cancel() {
                this.$router.push({name: 'customers'})
            },
            async loadCustomer() {
                this.customerId = this.$route.params.id
                this.customer = await this.$store.dispatch(actionTypes.FIND_CUSTOMER_BY_ID, this.customerId)
                this.setCustomerCity()
            },
            setCustomerCity() {
                this.customer.address = this.customer.address ? this.customer.address : {city: ''}
            },
            setViewMode() {
                this.editing = `${this.$route.query.editing}` === 'true'
            },
            async update() {
                const isFormValid = await this.$validator._base.validateAll()
                if (!isFormValid) {
                    return
                }
                try {
                    await this.$store.dispatch(actionTypes.UPDATE_CUSTOMER, {
                        customerId: this.customerId,
                        customer: this.customer
                    })
                    await this.loadCustomer()
                    this.showSuccess()
                } catch (e) {
                    this.showError(e)
                }
            }
        }
    }
</script>