<template>
    <div class="fa-company-form-representatives-table">
        <v-flex xs12>
            <span class="body-2 subtitulo">
                {{$t('company.edit.form.fields.representatives.tableDescription')}}
            </span>
        </v-flex>
        <v-flex xs12 class="table">
            <v-data-table
                    :headers="headers"
                    :items="value.representatives"
                    :loading="false"
                    hide-default-footer
                    disable-pagination
                    class="az-table-list"
                    item-key="name"
            >
                <template v-slot:item="props">
                    <tr>
                        <td>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">
                                        {{ (props.item.fullName || props.item.name) | azClipText(30)}}
                                    </span>
                                </template>
                                <span>{{props.item.fullName || props.item.name}}</span>
                            </v-tooltip>
                        </td>
                        <td>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">
                                        {{ (props.item.email || props.item.name) | azClipText(30)}}
                                    </span>
                                </template>
                                <span>{{props.item.email || props.item.name}}</span>
                            </v-tooltip>
                        </td>
                        <td>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">
                                        {{checkUndefinedField(props.item.phoneNumber1) | phone-filter(getPhoneMask())}}
                                    </span>
                                </template>
                                <span>
                                    {{checkUndefinedField(props.item.phoneNumber1) | phone-filter(getPhoneMask())}}
                                </span>
                            </v-tooltip>
                        </td>
                        <td>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">
                                        {{checkUndefinedField(props.item.phoneNumber2) |  phone-filter(getPhoneMask())}}
                                    </span>
                                </template>
                                <span>
                                    {{checkUndefinedField(props.item.phoneNumber2) |  phone-filter(getPhoneMask())}}
                                </span>
                            </v-tooltip>
                        </td>
                        <td>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                            v-on="on"
                                            class="button-remove"
                                            color="#ccc"
                                            :disabled="canIEdit(props.item)"
                                            @click.prevent="remove(props.index)"
                                    >
                                        cancel
                                    </v-icon>
                                </template>
                                <span>
                                    {{$t('company.edit.form.fields.representatives.removeLabel')}}
                                </span>
                            </v-tooltip>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-flex>
    </div>
</template>

<script>
    export default {
        name: 'company-form-representatives-table',
        props: {
            loggedUser: {
                type: Object,
                default: null
            },
            editing: {
                type: Boolean,
                default: true
            },
            value: {}
        },
        data() {
            return {
                headers: [
                    {
                        text: this.$t('company.edit.form.fields.representatives.table.headers.name'),
                        align: 'left',
                        sortable: false,
                        class: 'primary--text',
                        width: '25%'
                    },
                    {
                        text: this.$t('company.edit.form.fields.representatives.table.headers.email'),
                        align: 'left',
                        sortable: false,
                        class: 'primary--text',
                        width: '25%'
                    },
                    {
                        text: this.$t('company.edit.form.fields.representatives.table.headers.phoneNumber1'),
                        align: 'left',
                        sortable: false,
                        class: 'primary--text',
                        width: '20%'
                    },
                    {
                        text: this.$t('company.edit.form.fields.representatives.table.headers.phoneNumber2'),
                        align: 'left',
                        sortable: false,
                        class: 'primary--text',
                        width: '20%'
                    },
                    {
                        text: this.$t('company.edit.form.fields.representatives.table.headers.action'),
                        align: 'left',
                        sortable: false,
                        class: 'primary--text',
                        width: '10%'
                    }
                ]
            }
        },
        methods: {
            canIEdit(user) {
                return !(this.isLogged() && (this.isAdmin() || !this.isCurrentParticipant(user._id)) && this.editingEnabled())
            },
            checkUndefinedField(field) {
                if (field) {
                    return field
                } else {
                    return ''
                }
            },
            getPhoneMask() {
                return JSON.parse(this.$t('company.edit.form.fields.representatives.table.masks.phone'))
            },
            isAdmin() {
                return this.loggedUser.isAdmin
            },
            isLogged() {
                return this.loggedUser
            },
            isCurrentParticipant(id) {
                return this.loggedUser._id === id
            },
            editingEnabled() {
                return this.editing
            },
            remove(index) {
                this.value.representatives.splice(index, 1)
            }
        }
    }
</script>

<style lang="stylus">
    .fa-company-form-representatives-table
        .subtitulo
            color #757575

        .table
            max-height: 250px
            padding: 20px !important
            margin-bottom: 10px !important
            width: 100% !important
            overflow-y: auto

        .button-remove
            cursor: pointer
</style>