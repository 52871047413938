<template>
    <div class="ml-4 detail-project-quotation">
        <v-flex xs12 style="width:100%; border-bottom: 1px solid #ddd" class="py-3 text-md-center">
            <span class="title font-weight-bold primary--text">{{$t('project.participant.detail.quotation.title')}}</span>
            -
            <span class="body-2">({{$t('project.participant.detail.quotation.finishAt')}}: {{project.calendar.additionalDocuments | azDate('DD/MM/YYYY HH:mm')}})</span>
        </v-flex>
        <v-flex xs12 class="text-md-center py-5 px-5" v-if="!fileQuotationSlug">
            <span class="subheading grey--text lighten-1">{{$t('project.participant.detail.quotation.description')}}</span>
        </v-flex>
        <v-flex xs12 class="text-md-center" v-if="!fileQuotationSlug">
            <a :href="fileLinkProposta" target="_blank" class="link-download-modelo">
                {{$t('project.participant.detail.quotation.downloadExampleHere')}}
            </a>
        </v-flex>

        <v-flex xs12 class="text-md-center py-5 px-5" v-if="!fileQuotationSlug">
            <span class="subheading grey--text lighten-1">{{$t('project.participant.detail.quotation.thenLabel')}}:</span>
        </v-flex>
        <v-flex xs12 sm12 v-if="!fileQuotationSlug" class="px-5">
            <az-file-upload
                repository=""
                ref="azFileUploadQuotation"
                style="margin-left: 10px; margin-right: 20px;"
                @error="treatFileUploadError">
                <span class="subheading grey--text lighten-1" style="cursor:pointer;" @click="$refs.azFileUploadQuotation.openFileSelector()">
                    {{$t('project.participant.detail.quotation.dragAndDropFilesHere')}}
                </span>
            </az-file-upload>
        </v-flex>

        <v-flex xs12 v-if="fileQuotationSlug" class="text-md-center mt-5">
            <div class="title green--text lighten-1 mb-2">
                <v-icon color="green lighten-1">check_circle_outline</v-icon>
                {{$t('project.participant.detail.quotation.fileUploaded')}}
            </div>
            <div>
                <a :href="fileLinkQuotation" download class="grey--text lighten-2">
                    {{$t('project.participant.detail.quotation.downloadQuotationFile')}}
                </a>
            </div>
            <div class="mt-5">
                <a @click="updateQuotationInProject(null)" class="grey--text lighten-2">
                    {{$t('project.participant.detail.quotation.resend')}}
                </a>
            </div>
        </v-flex>
    </div>
</template>

<script>
    import _ from 'lodash'
    import {mapState} from 'vuex'
    import {actionTypes} from '@/commons/constants'

    export default {
        name: "detail-project-quotation",
        props: ['project'],
        data() {
            return {
                fileQuotationSlug: null,
                fileLinkProposta: null,
                fileLinkQuotation: null
            }
        },
        computed: {
            ...mapState(['company', 'loki'])
        },
        watch: {
            'loki.uploadedFiles'() {
                const uploadedFiles = this.loki.uploadedFiles
                const file = uploadedFiles[uploadedFiles.length - 1]
                if (file) {
                    this.updateQuotationInProject(file.filename)
                    uploadedFiles.splice(uploadedFiles.length - 1, 1)
                    this.$store.commit('SET_UPLOADED_FILES', uploadedFiles)
                }
            }
        },
        methods: {
            buildFileLinkProposta() {
                let slugProposta = null
                this.project.termsAndConditions.forEach(t => {
                    if (t.type === 'proposta') {
                        slugProposta = t.slug
                    }
                })
                if (slugProposta) {
                    this.fileLinkProposta = this.buildDownloadLink(slugProposta)
                }
            },
            setQuotationSlug() {
                this.project.participants.forEach(p => {
                    if (p.companyId === this.company._id) {
                        this.fileQuotationSlug = p.quotationSlug
                    }
                })
            },
            buildFileLinkQuotation() {
                if (this.fileQuotationSlug) {
                    let downloadLink = this.buildDownloadLink(this.fileQuotationSlug)
                    downloadLink = downloadLink.replace('/true', '')
                    this.fileLinkQuotation = downloadLink
                }
            },
            async updateQuotationInProject(quotationSlug) {
                const project = _.clone(this.project)
                const participantIdx = this.project.participants.findIndex(p => p.companyId === this.company._id)
                project.participants[participantIdx].quotationSlug = quotationSlug
                await this.$store.dispatch(actionTypes.UPDATE_PARTICIPANT, {
                    projectId: this.project._id,
                    companyId: this.project.participants[participantIdx].companyId,
                    participant: project.participants[participantIdx]
                });
                await this.$store.dispatch(actionTypes.FIND_PROJECT_BY_ID, project._id)
                this.showSuccess()
                this.$emit('updatePhases')
                this.setQuotationSlug()
                this.buildFileLinkQuotation()
            }
        },
        mounted() {
            this.buildFileLinkProposta()
            this.setQuotationSlug()
            this.buildFileLinkQuotation()
        }
    }
</script>

<style scoped lang="stylus">
    .detail-project-quotation
        .link-download-modelo
            background-color: #4CAF50 !important
            padding 10px 20px !important
            border-radius 2px
            font-size 16px
            color: white
            &:hover
                background-color: #66BB6A !important
</style>
