import {mutationTypes} from '@/commons/constants'
import store from '@/commons/store'

class SuccessHandler {

    request(config) {
        store.commit(mutationTypes.LOKI.SET_GLOBAL_LOADING, true)
        return config
    }

    response(response) {
        store.commit(mutationTypes.LOKI.SET_GLOBAL_LOADING, false)
        store.commit(mutationTypes.LOKI.SET_LOADING_MESSAGE, store.state.defaultLoadingMessage)
        return response
    }

}

const successHandler = new SuccessHandler()
export default successHandler
