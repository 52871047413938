<template>
    <v-dialog
        :value=true
        persistent
        scrollable
        width="800">
        <v-card>
            <v-card-text>
                <span v-html="email.html"/>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray" text @click="$emit('close')">
                    {{$t('project.admin.edit.close')}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    export default {
        name: 'log-email-detail',
        props: ['email']
    }
</script>