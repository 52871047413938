<template>
    <div>
        <az-toolbar>
            <az-call-to-action style="font-size: 14px" active slot="actions" @click="addCompany">
                <v-icon small dense>add_circle</v-icon>
                {{ $t('company.search.actions.add') }}
            </az-call-to-action>
            <az-search
                    slot="simpleSearch"
                    style="font-size: 14px"
                    :filter="activeFilters"
                    @simple-search="simpleSearchEvent"
                    @remove-filter="removeFilterEvent"
                    :simple-search-placeholder="$t('company.search.searchPlaceholder')">
            </az-search>
        </az-toolbar>
        <company-search-table
                :itens="companies"
                :pages="pages"
                :total-itens="totalItens"
                :pagination="companySearch.pagination"
                @paginate="paginateEvent"
                @view="viewCompanyDetailEvent"
                @edit="editCompanyEvent"
                @delete="deleteCompanyEvent"
                @status="changeStatusEvent"
                v-if="hasCompanies"/>
        <az-ops
                :title="$t('company.search.noProjectsCreated.title')"
                :message="$t('company.search.noProjectsCreated.message')"
                v-else/>
    </div>
</template>

<script>
    import _ from 'lodash'
    import {mapActions, mapMutations, mapState} from 'vuex'
    import {filters} from '@azinformatica/loki'
    import i18n from '@/commons/i18n'
    import {actionTypes, mutationTypes} from '@/commons/constants'
    import CompanySearchTable from './CompanySearchTable'

    export default {
        components: {CompanySearchTable},
        name: 'company-search',
        data() {
            return {
                internalFilters: _.cloneDeep(this.$store.state.companySearch.filters),
                pages: 0,
                companies: undefined,
                totalItens: 0
            }
        },
        computed: {
            ...mapState(['companySearch']),
            activeFilters() {
                const allFilters = _.cloneDeep(this.companySearch.filters)
                const activeFilters = {}
                for (let [key, val] of Object.entries(allFilters)) {
                    if (allFilters[key].value) {
                        val.valueTruncated = filters.azClipText(val.value, 10)
                        activeFilters[key] = val
                    }
                }
                return activeFilters
            },
            hasCompanies() {
                return this.companies !== undefined && this.companies.length > 0
            }
        },
        async mounted() {
            await this.executeSearch()
            this.openAsideMenu()
        },
        methods: {
            ...mapActions([actionTypes.FIND_COMPANIES]),
            ...mapMutations([
                mutationTypes.CLEAN_COMPANY_SEARCH_FILTERS,
                mutationTypes.SET_COMPANY_SEARCH_FILTERS,
                mutationTypes.SET_COMPANY_SEARCH_PAGINATION
            ]),
            addCompany() {
                this.$router.push({name: 'addCompany'})
            },
            async changeStatusEvent(company) {
                company.status = !company.status
                await this.$store.dispatch(actionTypes.UPDATE_COMPANY, {
                    companyId: company._id,
                    company
                })
                this.executeSearch()
                this.showSuccess()
            },
            async deleteCompanyEvent(companyId) {
                await this.$store.dispatch(actionTypes.REMOVE_COMPANY, companyId)
                await this.executeSearch()
            },
            editCompanyEvent(id) {
                this.$router.push({name: 'editCompany', params: {id}, query: {editing: true}})
            },
            async executeSearch() {
                this.setCompanySearchFilters(this.internalFilters)
                this.setLoadingMessage(i18n.t('company.search.loadingProjects'))
                const result = await this.findCompanies()
                this.companies = result.content
                this.pages = result.pages
                this.totalItens = result.totalElements
            },
            paginateEvent(pagination) {
                this.setCompanySearchPagination(pagination)
                this.executeSearch()
            },
            removeFilterEvent() {
                this.cleanCompanySearchFilters()
                this.internalFilters = _.cloneDeep(this.companySearch.filters)
                this.executeSearch()
            },
            simpleSearchEvent(value) {
                this.internalFilters.nameDoc = {
                    value: value,
                    label: i18n.t('company.search.filterLabel')
                }
                this.executeSearch()
            },
            viewCompanyDetailEvent(id) {
                this.$router.push({name: 'editCompany', params: {id}})
            }
        }
    }
</script>