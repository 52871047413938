<template>
    <div>
        <user-back/>
        <az-container>
            <div class="az-form-content">
                <user-form :editing="editing" v-model="user"/>
                <user-actions @save="save" @cancel="cancel"/>
            </div>
        </az-container>
        <modal-view
                :openModal="openModal"
                :title="$t('users.search.modals.add.title')"
                :textPrimary="$t('users.search.modals.add.text1')"
                :textSecondary="$t('users.search.modals.add.text2')"
                :argument="savedUser.password"
                :acceptText="$t('users.search.modals.add.options.accept')"
                @accept="hideModal"
        />
    </div>
</template>

<script>
    import AbstractUser from './AbstractUser'
    import {actionTypes} from '@/commons/constants'
    import ModalView from '@/views/pages/commons/ModalView'

    export default {
        name: 'user-add',
        extends: AbstractUser,
        components: {ModalView},
        data() {
            return {
                editing: true,
                openModal: false,
                user: {
                    name: '',
                    email: '',
                    phoneNumber1: '',
                    phoneNumber2: '',
                    source: 'internal',
                    isAdmin: false
                },
                savedUser: {}
            }
        },
        mounted() {
            this.closeAsideMenu()
        },
        methods: {
            async insertOnRemoteServer() {
                const user = this.preparedModel(this.user)
                const userSaved = await this.$store.dispatch(actionTypes.INSERT_USER, user)
                return userSaved
            },
            hideModal() {
                this.openModal = false
                const id = this.savedUser._id
                this.goToEditPage(id)
            },
            async save() {
                if (await this.hasValidationErrors()) return
                try {
                    this.savedUser = await this.insertOnRemoteServer()
                    this.showSuccess()
                    this.showModal()
                } catch (e) {
                    this.showError(e)
                }
            },
            showModal() {
                this.openModal = true
            }
        }
    }
</script>
