<template>
    <v-flex xs12 sm12 class="project-participants-table">
        <v-data-table
                :headers="headers"
                :items="participants"
                :loading="false"
                hide-default-footer
                disable-pagination
                class="az-table-list">
            <template v-slot:item="props">
                <tr>
                    <td>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <span v-on="on">
                                    {{ props.item.company.name | azClipText(30)}}
                                </span>
                            </template>
                            <span>{{props.item.company.name}}</span>
                        </v-tooltip>
                    </td>
                    <td>
                        <v-icon
                                v-if="acceptedTermsAndConditions(props.item)"
                                color="green" class="ml-2" size="15">
                            check
                        </v-icon>
                        <v-icon color="red" class="ml-2" size="15" v-else>close</v-icon>
                    </td>
                    <td>
                        <v-icon v-if="acceptedVisitation(props.item)"
                                color="green" class="ml-2" size="15">
                            thumb_up
                        </v-icon>
                        <v-icon v-else-if="declinedVisitation(props.item)"
                                color="green" class="ml-2" size="15">
                            thumb_down
                        </v-icon>
                        <v-icon color="red" class="ml-2" size="15" v-else>close</v-icon>
                    </td>
                    <td>
                        <a @click.prevent="$emit('documents', props.item)">
                            <v-icon color="green" class="ml-2" size="15"
                                    v-if="hasAllRequiredDocs(props.item)">
                                check
                            </v-icon>
                            <v-icon color="red" class="ml-2" size="15" v-else>close</v-icon>
                        </a>
                    </td>
                    <td>
                        <download-link
                                :slug="props.item.quotationSlug"
                                v-if="sentQuotation(props.item)">
                            <v-icon color="green" class="ml-2" size="15">check</v-icon>
                        </download-link>
                        <v-icon color="red" class="ml-2" size="15" v-else>close</v-icon>
                    </td>
                    <td>
                        <span v-if="projectFinished || !editing">
                            {{getParticipantStatusLabel(props.item)}}
                        </span>
                        <v-select
                                class="pt-0"
                                item-text="text"
                                item-value="value"
                                :items="participantStatus"
                                @change="updateStatus(props.item)"
                                v-model="props.item.status"
                                v-else/>
                    </td>
                    <td>
                        <download-link
                                :slug="props.item.negotiation.fileSlug"
                                v-if="acceptedNegociation(props.item)">
                            <v-icon color="green" class="ml-2" size="15">check</v-icon>
                        </download-link>
                        <v-icon color="red" class="ml-2" size="15" v-else>close</v-icon>
                    </td>
                    <td class="table-actions text-sm-right">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <a target="_blank" @click="download(props.item)">
                                    <v-icon v-on="on">get_app</v-icon>
                                </a>
                            </template>
                            <span>{{$t('project.admin.edit.tabs.participants.tableColumns.actions.download')}}</span>
                        </v-tooltip>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-flex>
</template>

<script>
    import DownloadLink from '@/views/components/DownloadLink'

    export default {
        components: {DownloadLink},
        name: 'project-participants-table',
        props: {
            participants: {
                type: Array,
                required: true
            },
            editing: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                headers: [
                    {
                        text: this.$t('project.admin.edit.tabs.participants.tableColumns.name'),
                        align: 'left',
                        sortable: false,
                        class: 'primary--text',
                        width: '25%'
                    },
                    {
                        text: this.$t('project.admin.edit.tabs.participants.tableColumns.termsAndConditions'),
                        align: 'left',
                        sortable: false,
                        class: 'primary--text'
                    },
                    {
                        text: this.$t('project.admin.edit.tabs.participants.tableColumns.visitation'),
                        align: 'left',
                        sortable: false,
                        class: 'primary--text'
                    },
                    {
                        text: this.$t('project.admin.edit.tabs.participants.tableColumns.documents'),
                        align: 'left',
                        sortable: false,
                        class: 'primary--text'
                    },
                    {
                        text: this.$t('project.admin.edit.tabs.participants.tableColumns.quotation'),
                        align: 'left',
                        sortable: false,
                        class: 'primary--text'
                    },
                    {
                        text: this.$t('project.admin.edit.tabs.participants.tableColumns.approved'),
                        align: 'left',
                        sortable: false,
                        class: 'primary--text'
                    },
                    {
                        text: this.$t('project.admin.edit.tabs.participants.tableColumns.negociationTerm'),
                        align: 'left',
                        sortable: false,
                        class: 'primary--text'
                    },
                    {
                        text: this.$t('project.admin.edit.tabs.participants.tableColumns.actions.name'),
                        sortable: false,
                        align: 'right',
                        width: '120px',
                        class: 'primary--text'
                    }
                ]
            }
        },
        computed: {
            participantStatus() {
                return Object.values(this.$t('project.admin.edit.tabs.participants.status'))
            },
            projectFinished() {
                return this.$store.state.project.status === 'FINISHED'
            }
        },
        methods: {
            acceptedNegociation(participant) {
                return participant.negotiation && participant.negotiation.agreed
            },
            acceptedVisitation(participant) {
                return participant.visitation && participant.visitation.agreed
            },
            acceptedTermsAndConditions(participant) {
                return participant.termsAndConditions && participant.termsAndConditions.agreed
            },
            declinedVisitation(participant) {
                return participant.visitation && !participant.visitation.agreed
            },
            download(participant) {
                if (participant.totalDocs === 0) {
                    return this.showErrorFrontEnd('project.admin.edit.tabs.participants.participantDoesNotHaveDocumentsError')
                }
                const projectId = this.$store.state.project._id
                window.open(`/api/projects/${projectId}/participants/${participant._id}/documents`)
            },
            getParticipantStatusLabel(participant) {
                const status = this.participantStatus.find(s => s.value === participant.status)
                return status.text
            },
            hasAllRequiredDocs(participant) {
                return participant.totalDocsRequired === participant.totalDocsValid
            },
            sentQuotation(participant) {
                return participant.quotationSlug
            },
            async updateStatus(participant) {
                this.$nextTick(() => {
                    this.$emit('changeStatus', {id: participant._id, status: participant.status})
                })
            }
        }
    }
</script>
