<template>
    <div>
        <az-container>
            <az-form>
                <v-data-table
                        class="az-table-list"
                        item-key="user.email"
                        hide-default-footer
                        disable-pagination
                        v-model="selected"
                        :headers="columns"
                        :items="itens"
                        :show-select="enableMultipleSelect"
                        @update:pagination="updatePagination">

                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="item in items" :key="item.user.email">
                            <td v-if="enableMultipleSelect">
                                <v-checkbox
                                        class="pb-2"
                                        :value="item"
                                        v-model="selected"
                                        color="primary"
                                        hide-details
                                />
                            </td>
                            <td>{{ item.user.name }}</td>
                            <td>{{ item.user.email }}</td>
                            <td>{{ item.user.isAdmin | boolean-filter }}</td>
                            <td>{{ item.company ? item.company.name : '' }}</td>
                            <td>{{ item.user.emailSubscriber | boolean-filter }}</td>
                            <td>{{ item.user.createdAt | azDate('DD/MM/YYYY HH:mm') }}</td>
                            <td class="text-xs-right" @click.stop="">
                                <v-menu bottom left>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" dark icon text depressed color="primary">
                                            <v-icon>more_vert</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item @click.prevent.stop="$emit('view', item.user._id)">
                                            <v-list-item-title>
                                                <v-icon size="18px" class="mr-1">search</v-icon>
                                                {{ $t('users.search.actions.view') }}
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="$emit('edit', item.user._id)">
                                            <v-list-item-title>
                                                <v-icon size="18px" class="mr-1">edit</v-icon>
                                                {{ $t('users.search.actions.edit') }}
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="showResetPasswordDialog(item.user._id)">
                                            <v-list-item-title>
                                                <v-icon size="18px" class="mr-1">lock</v-icon>
                                                {{ $t('users.search.actions.resetPassword') }}
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="showDeleteDialog(item.user._id)">
                                            <v-list-item-title>
                                                <v-icon size="18px" class="mr-1">delete</v-icon>
                                                {{ $t('users.search.actions.delete') }}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-data-table>
                <div class="az-pagination">
                    <v-pagination v-model="internalPagination.page" :length="pages"/>
                    <div class="az-select-pagination">
                        <span>{{ $t('users.search.rowsPerPage') }}</span>
                        <v-select :items="rowsPerPage" v-model="internalPagination.rowsPerPage"/>
                    </div>
                </div>
                <dialog-view
                        :openDialog="openResetPasswordDialog"
                        :title="$t('users.search.dialogs.resetPassword.title')"
                        :text="$t('users.search.dialogs.resetPassword.text')"
                        :rejectText="$t('users.search.dialogs.resetPassword.options.reject')"
                        :acceptText="$t('users.search.dialogs.resetPassword.options.accept')"
                        @reject="hideResetPasswordDialog"
                        @accept="resetPasswordOnRemoteServer"
                />
                <dialog-view
                        :openDialog="openDeleteDialog"
                        :title="$t('users.search.dialogs.delete.title')"
                        :text="$t('users.search.dialogs.delete.text')"
                        :rejectText="$t('users.search.dialogs.delete.options.reject')"
                        :acceptText="$t('users.search.dialogs.delete.options.accept')"
                        @reject="hideDeleteDialog"
                        @accept="deleteOnRemoteServer"
                />
            </az-form>
        </az-container>
    </div>
</template>

<script>
    import _ from 'lodash'
    import i18n from '@/commons/i18n'
    import DialogView from '@/views/pages/commons/DialogView'

    export default {
        name: 'user-search-table',
        props: ['itens', 'pagination', 'pages', 'totalItens', 'enableMultipleSelect'],
        components: {DialogView},
        data() {
            return {
                columns: [
                    {
                        text: i18n.t('users.search.tableColumns.name'),
                        value: 'name',
                        align: 'left',
                        sortable: true,
                        width: '20%',
                        class: 'primary--text'
                    },
                    {
                        text: i18n.t('users.search.tableColumns.email'),
                        align: 'left',
                        sortable: false,
                        width: '25%',
                        class: 'primary--text'
                    },
                    {
                        text: i18n.t('users.search.tableColumns.admin'),
                        align: 'left',
                        sortable: false,
                        width: '5%',
                        class: 'primary--text'
                    },
                    {
                        text: i18n.t('users.search.tableColumns.company'),
                        align: 'left',
                        sortable: false,
                        width: '20%',
                        class: 'primary--text'
                    },
                    {
                        text: i18n.t('users.search.tableColumns.emailSubscriber'),
                        align: 'left',
                        sortable: false,
                        width: '5%',
                        class: 'primary--text'
                    },
                    {
                        text: i18n.t('users.search.tableColumns.createdAt'),
                        value: 'createdAt',
                        align: 'left',
                        sortable: true,
                        width: '15%',
                        class: 'primary--text'
                    },
                    {
                        text: i18n.t('users.search.tableColumns.actions'),
                        align: 'right',
                        sortable: false,
                        width: '1%',
                        class: 'primary--text'
                    }
                ],
                selected: [],
                userId: null,
                internalPagination: this.pagination,
                rowsPerPage: [5, 10, 25, 50, 100],
                openResetPasswordDialog: false,
                openDeleteDialog: false
            }
        },
        watch: {
            'internalPagination.page': {
                handler() {
                    this.$emit('paginate', this.internalPagination)
                }
            },
            'internalPagination.rowsPerPage': {
                handler() {
                    this.internalPagination.page = 1
                    this.$emit('paginate', this.internalPagination)
                }
            }
        },
        methods: {
            deleteOnRemoteServer() {
                this.$emit('delete', this.userId)
                this.hideDeleteDialog()
            },
            resetPasswordOnRemoteServer() {
                this.$emit('resetPassword', this.userId)
                this.hideResetPasswordDialog()
            },
            hideDeleteDialog() {
                this.openDeleteDialog = false
                this.setUserIdOnState(null)
            },
            hideResetPasswordDialog() {
                this.openResetPasswordDialog = false
                this.setUserIdOnState(null)
            },
            setUserIdOnState(id) {
                this.userId = id
            },
            showDeleteDialog(id) {
                this.openDeleteDialog = true
                this.setUserIdOnState(id)
            },
            showResetPasswordDialog(id) {
                this.openResetPasswordDialog = true
                this.setUserIdOnState(id)
            },
            updatePagination(payload) {
                if (!payload.sortBy) {
                    this.internalPagination.sortBy = 'name'
                    this.internalPagination.descending = true
                } else {
                    this.internalPagination.sortBy = payload.sortBy
                    this.internalPagination.descending = payload.descending
                }
                this.$emit('paginate', this.internalPagination)
            },
            getSelectedUsers() {
                return _.cloneDeep(this.selected)
            },
            resetSelectedUsers() {
                this.selected = []
            }
        }
    }
</script>

<style lang="stylus">
    .az-table-list
        .v-input__slot
            margin 0
            border none
            border-radius 0
            padding 0
</style>
