import store from '@/commons/store'
import {actionTypes} from '@/commons/constants'
import router from '@/views/router'

class GetLoggedUser {
    async execute() {
        try {
            const token = sessionStorage.getItem('jwt')
            if (token) {
                await store.dispatch(actionTypes.VERIFY_TOKEN, token)
                await store.dispatch(actionTypes.GET_COMPANY)
            }
        } catch (e) {
            store.dispatch(actionTypes.LOGOUT)
            router.push({
                name: 'logout',
                query: {
                    lostSession: true
                }
            })
        }
    }
}

export default new GetLoggedUser()
