import Login from '@/views/pages/external/login/Login'
import Logout from '@/views/pages/commons/Logout'
import Register from '@/views/pages/external/register/Register'
import ContinueRegister from '@/views/pages/external/continue-register/ContinueRegister'
import ForgetPassword from '@/views/pages/external/password/ForgetPassword'
import UpdatePassword from '@/views/pages/external/password/UpdatePassword'
import Unsubscribe from '@/views/pages/external/unsubscribe/Unsubscribe'
import Success from '@/views/pages/commons/Success'
import Error from '@/views/pages/commons/Error'
import Page404 from '@/views/pages/commons/Page404'

export default [
    {
        path: 'login',
        name: 'login',
        component: Login
    },
    {
        path: 'register',
        name: 'register',
        component: Register
    },
    {
        path: 'continue-register',
        name: 'continueRegister',
        component: ContinueRegister
    },
    {
        path: 'forget-password',
        name: 'forgetPassword',
        component: ForgetPassword
    },
    {
        path: 'unsubscribe/:emailUser',
        name: 'unsubscribe',
        component: Unsubscribe
    },
    {
        path: 'update-password',
        name: 'updatePassword',
        component: UpdatePassword
    },
    {
        path: 'success/:action',
        name: 'success',
        component: Success
    },
    {
        path: 'error/:action',
        name: 'error',
        component: Error
    },
    {
        path: 'logout',
        name: 'logout',
        component: Logout
    },
    {
        path: '*',
        component: Page404
    }
]
