<template>
    <v-container fluid grid-list-lg>
        <v-layout row wrap align-center>
            <v-layout>
                <v-flex style="width:100%">
                    <v-flex xs12>
                        <v-text-field
                            :name="$t('customer.edit.form.fields.name.name')"
                            :label="$t('customer.edit.form.fields.name.label')"
                            :placeholder="$t('customer.edit.form.fields.name.placeholder')"
                            :error-messages="errors.collect(`${$t('customer.edit.form.fields.name.name')}`)"
                            v-model="value.name"
                            v-if="editing"
                            v-validate="'required'"/>
                        <az-text-view
                            :label="$t('customer.edit.form.fields.name.label')"
                            :text="value.name"
                            v-else/>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            :name="$t('customer.edit.form.fields.document.name')"
                            :label="$t('customer.edit.form.fields.document.label')"
                            :placeholder="$t('customer.edit.form.fields.document.placeholder')"
                            :mask="$t('customer.edit.form.fields.document.mask')"
                            v-model="value.document"
                            v-if="editing"/>
                        <az-text-view
                            :label="$t('customer.edit.form.fields.document.label')"
                            :text="value.document | mask-filter($t('customer.edit.form.fields.document.mask'))"
                            v-else/>
                    </v-flex>
                </v-flex>
                <v-flex style="width:200px">
                    <profile-picture
                        ref="profilePicture"
                        :slug="value.logoSlug"
                        :editing="editing"
                        @change="setLogoSlug">
                        <div slot="upload-area">
                            <v-icon large @click="$refs.profilePicture.openFileSelector()">
                                business
                            </v-icon>
                            <p>{{$t('customer.edit.form.fields.logo.placeholder')}}</p>
                        </div>
                    </profile-picture>
                </v-flex>
            </v-layout>
            <v-flex xs12>
                <v-autocomplete
                    :name="$t('customer.edit.form.fields.city.name')"
                    :label="$t('customer.edit.form.fields.city.label')"
                    :placeholder="$t('customer.edit.form.fields.city.placeholder')"
                    :items="getLocationOptions()"
                    v-model="value.address.city"
                    v-if="editing"/>
                <az-text-view
                    :label="$t('customer.edit.form.fields.city.label')"
                    :text="getCityLabel()"
                    v-else/>
            </v-flex>
            <v-flex xs12>
                <multi-string
                    :name="$t('customer.edit.form.fields.emails.name')"
                    :label="$t('customer.edit.form.fields.emails.label')"
                    :placeholder="$t('customer.edit.form.fields.emails.placeholder')"
                    :add-label="$t('customer.edit.form.fields.emails.addLabel')"
                    :remove-label="$t('customer.edit.form.fields.emails.removeLabel')"
                    :empty-data-text="$t('customer.edit.form.fields.emails.emptyEmailList')"
                    :items="localEmails"
                    :editing="editing"
                    @add="addEmail"
                    @remove="removeEmail"/>
            </v-flex>
            <v-flex xs12>
                <multi-string
                    :name="$t('customer.edit.form.fields.phones.name')"
                    :label="$t('customer.edit.form.fields.phones.label')"
                    :placeholder="$t('customer.edit.form.fields.phones.placeholder')"
                    :mask="JSON.parse($t('customer.edit.form.fields.phones.mask'))"
                    :add-label="$t('customer.edit.form.fields.phones.addLabel')"
                    :remove-label="$t('customer.edit.form.fields.phones.removeLabel')"
                    :empty-data-text="$t('customer.edit.form.fields.phones.emptyPhoneList')"
                    :items="localPhoneList"
                    :editing="editing"
                    @add="addPhone"
                    @remove="removePhone"/>
            </v-flex>

        </v-layout>
        <div class="az-actions-form" v-if="editing">
            <div class="align-left"></div>
            <div class="align-right">
                <button class="action-secundary" @click.prevent="$emit('cancel')">
                    {{$t('customer.edit.cancel')}}
                </button>
                <button class="action-primary" @click.prevent="save">
                    {{$t('customer.edit.save')}}
                </button>
            </div>
        </div>
    </v-container>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {actionTypes} from '@/commons/constants'
    import {TextUtils} from '@/commons/utils'
    import MultiString from '@/views/components/MultiString'
    import ProfilePicture from '@/views/components/ProfilePicture'

    export default {
        name: 'customer-form',
        components: {
            ProfilePicture,
            MultiString
        },
        props: {
            editing: {
                type: Boolean,
                default: false
            },
            value: {
                required: true
            }
        },
        data() {
            return {
                localEmails: [],
                localPhoneList: []
            }
        },
        async mounted() {
            await this.loadCities()
        },
        watch: {
            'value.emails'() {
                this.localEmails = []
                this.value.emails.map(email => this.localEmails.push({value: email}))
            },
            'value.phones'() {
                this.localPhoneList = []
                this.value.phones.map(phone => this.localPhoneList.push({value: phone}))
            }
        },
        methods: {
            ...mapGetters(['isCitiesLoaded', 'getLocationOptions']),
            addEmail() {
                this.localEmails.push({value: ''})
            },
            addPhone() {
                this.localPhoneList.push({value: ''})
            },
            createEmails() {
                const emails = []
                this.localEmails.map(item => {
                    if (item.value) {
                        emails.push(item.value)
                    }
                })
                return emails
            },
            createPhoneList() {
                const phoneList = []
                this.localPhoneList.map(item => {
                    if (item.value) {
                        const unmaskedValue = TextUtils.removeSpecialCharacters(item.value)
                        phoneList.push(unmaskedValue)
                    }
                })
                return phoneList
            },
            getCityLabel() {
                if (this.value.address && this.value.address.city) {
                    const city = this.value.address.city.name
                    const state = this.value.address.city.state
                    return `${city} - ${state}`
                }
                return this.$t('customer.edit.form.fields.city.cityNotInformed')
            },
            async loadCities() {
                if (!this.isCitiesLoaded()) {
                    await this.$store.dispatch(actionTypes.LOAD_CITIES)
                }
            },
            removeEmail(index) {
                this.localEmails.splice(index, 1)
            },
            removePhone(index) {
                this.localPhoneList.splice(index, 1)
            },
            save() {
                this.value.emails = this.createEmails()
                this.value.phones = this.createPhoneList()
                if (this.value.document) {
                    this.value.document = TextUtils.removeSpecialCharacters(this.value.document)
                }
                this.$emit('save')
            },
            setLogoSlug(slug) {
                this.value.logoSlug = slug
            }
        }
    }
</script>
