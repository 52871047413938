<template>
    <div>
        <user-back/>
        <az-container>
            <div class="az-form-content">
                <user-form :editing="editing" v-model="user"/>
                <user-actions @save="save" @cancel="cancel"/>
            </div>
        </az-container>
    </div>
</template>

<script>
    import AbstractUser from './AbstractUser'
    import {actionTypes} from '@/commons/constants'

    export default {
        name: 'user-edit',
        extends: AbstractUser,
        data() {
            return {
                editing: true,
                userId: null,
                user: {
                    name: '',
                    email: '',
                    phoneNumber1: '',
                    phoneNumber2: ''
                }
            }
        },
        async mounted() {
            this.closeAsideMenu()
            this.getUserIdFromUrl()
            await this.getFromRemoteServer()
        },
        methods: {
            async save() {
                if (await this.hasValidationErrors()) return
                try {
                    await this.updateOnRemoteServer()
                    await this.getFromRemoteServer()
                    this.showSuccess()
                } catch (e) {
                    this.showError(e)
                }
            },
            async updateOnRemoteServer() {
                const userId = this.userId
                const user = this.preparedModel(this.user)
                await this.$store.dispatch(actionTypes.UPDATE_USER, {userId, user})
            }
        }
    }
</script>
