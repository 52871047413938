import {mutationTypes} from '@/commons/constants'

export default {
    methods: {
        closeAsideMenu() {
            this.$store.commit(mutationTypes.LOKI.SET_ASIDE, true)
        },
        openAsideMenu() {
            this.$store.commit(mutationTypes.LOKI.SET_ASIDE, false)
        }
    }
}