import '@babel/polyfill'
import './engine/Register'
import triggerEvents from './engine/TriggerEvent'
import rootPageCreator from './engine/RootPageCreator'


export default class Application {

    static run() {
        triggerEvents.triggerOnStartEvents()
            .then(() => {
                rootPageCreator.createInstance()
            })
            .catch(() => {
                rootPageCreator.createBootstrapError()
            })
    }

}
