import ParticipantProjectSearch from '@/views/pages/internal/participant/project/search/ProjectSearch'
import ParticipantMyProjectSearch from '@/views/pages/internal/participant/project/search/MyProjectSearch'
import DetailProject from '@/views/pages/internal/participant/project/detail/DetailProject'
import ParticipantDocumentSearch from '@/views/pages/internal/participant/document/DocumentSearch'
import AddMyCompany from '@/views/pages/internal/participant/company/AddCompanyPage'
import EditMyCompany from '@/views/pages/internal/participant/company/EditCompanyPage'
import RepresentativeSearch from '@/views/pages/internal/participant/representative/RepresentativeSearch'

export default [
    {
        path: '/:lang/participant',
        name: 'participant',
        redirect: '/:lang/participant/projects'
    },
    {
        path: '/:lang/participant/myprojects',
        name: 'participantMyProjects',
        component: ParticipantMyProjectSearch,
        meta: {
            menu: {
                title: 'Meus Projetos',
                icon: 'inbox'
            },
            page: {
                title: 'Meus Projetos',
                subtitle: 'Lista dos projetos que estou participando'
            },
            requiresAuth: true,
            authorities: ['participant']
        }
    },
    {
        path: '/:lang/participant/projects',
        name: 'participantProjects',
        component: ParticipantProjectSearch,
        meta: {
            menu: {
                title: 'Todos os Projetos',
                icon: 'folder_open'
            },
            page: {
                title: 'Todos os Projetos',
                subtitle: 'Lista dos projetos publicados'
            },
            requiresAuth: true,
            authorities: ['participant']
        }
    },
    {
        path: '/:lang/participant/projects/:id/detail',
        name: 'participantDetailProject',
        component: DetailProject,
        meta: {
            page: {
                title: 'Projeto',
                subtitle: 'Detalhe'
            },
            requiresAuth: true,
            authorities: ['participant']
        }
    },
    {
        path: '/:lang/participant/documents',
        name: 'participantDocuments',
        component: ParticipantDocumentSearch,
        meta: {
            menu: {
                title: 'Documentos',
                icon: 'description'
            },
            page: {
                title: 'Documentos',
                subtitle: 'Lista de documentos e certidões'
            },
            requiresAuth: true,
            authorities: ['participant']
        }
    },
    {
        path: '/:lang/participant/company/add',
        name: 'addMyCompany',
        component: AddMyCompany,
        meta: {
            page: {
                title: 'MyCompany',
                subtitle: 'Meus dados pessoais'
            },
            requiresAuth: true,
            authorities: ['participant']
        }
    },
    {
        path: '/:lang/participant/company',
        name: 'editMyCompany',
        component: EditMyCompany,
        meta: {
            page: {
                title: 'MyCompany',
                subtitle: 'Meus dados pessoais'
            },
            requiresAuth: true,
            authorities: ['participant']
        }
    },
    {
        path: '/:lang/participant/representatives',
        name: 'representative',
        component: RepresentativeSearch,
        meta: {
            menu: {
                title: 'Representantes',
                icon: 'group'
            },
            page: {
                title: 'Representantes',
                subtitle: 'Lista de representantes'
            },
            requiresAuth: true,
            authorities: ['participant']
        }
    },
]
