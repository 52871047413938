<template>
    <v-dialog
        :value=true
        persistent
        width="500">
        <v-card>
            <v-card-title class="primary white--text" primary-title>
                <v-layout row wrap>
                    <v-flex xs12>
                        <span primary-title class="headline white--text">
                            {{$t('project.admin.edit.tabs.participants.documents.analysis.title')}}
                        </span>
                    </v-flex>
                    <v-flex xs12>
                        <span class="body-2 white--text">
                            {{$t(`project.requiredDocuments.${document.type}`)}}
                        </span>
                    </v-flex>
                </v-layout>
            </v-card-title>
            <v-card-title class="body-2 orange lighten-1 white--text">
                <v-icon color="white" class="mr-2">report_problem</v-icon>
                {{$t('project.admin.edit.tabs.participants.documents.analysis.operationCannotBeUndone')}}
            </v-card-title>
            <v-card-text>
                <v-layout row wrap>
                    <v-flex xs12>
                        <v-radio-group
                            mandatory
                            name="Status"
                            :error-messages="errors.collect('Status')"
                            v-validate="'required'"
                            v-model="document.status">
                            <v-layout>
                                <v-flex xs3 class="ml-4">
                                    <v-radio
                                        :label="$t('project.admin.edit.tabs.participants.documents.status.APPROVED')"
                                        value="APPROVED"/>
                                </v-flex>
                                <v-flex>
                                    <v-radio
                                        :label="$t('project.admin.edit.tabs.participants.documents.status.REPROVED')"
                                        value="REPROVED"/>
                                </v-flex>
                            </v-layout>
                        </v-radio-group>
                    </v-flex>
                    <v-flex xs12 class="ml-2 mr-2">
                        <v-textarea
                            :label="$t('project.admin.edit.tabs.participants.documents.analysis.notes')"
                            :name="$t('project.admin.edit.tabs.participants.documents.analysis.notes')"
                            :error-messages="errors.collect(`${$t('project.admin.edit.tabs.participants.documents.analysis.notes')}`)"
                            v-validate="analysisNotesRequired"
                            v-model="document.analysisNotes"/>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="text--secondary" text @click="$emit('cancel')">
                    {{$t('project.admin.edit.cancel')}}
                </v-btn>
                <v-btn color="primary" text @click="validateBeforeSave">
                    {{$t('project.admin.edit.save')}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'project-participant-doc-analysis',
        props: {
            document: {
                required: true
            }
        },
        computed: {
            analysisNotesRequired() {
                return this.document.status === 'REPROVED' ? 'required' : ''
            }
        },
        mounted() {
            this.document.status = null
        },
        methods: {
            async validateBeforeSave() {
                const valid = await this.$validator.validateAll()
                if (valid) {
                    this.$emit('save', this.document)
                }
            }
        }
    }
</script>