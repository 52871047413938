<template>
    <v-dialog v-model="openModal" persistent maxWidth="600">
        <v-card>
            <v-card-title class="headline">{{title}}</v-card-title>
            <v-card-text>{{textPrimary}} <strong>{{argument}}</strong></v-card-text>
            <v-card-text>{{textSecondary}}</v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="text--secondary" text @click="$emit('accept')">
                {{acceptText}}
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'modal-view',
    props: {
        openModal: {
            type: Boolean,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        textPrimary: {
            type: String,
            required: true
        },
        textSecondary: {
            type: String,
            required: true
        },
        argument: {
            type: String,
            default: '',
            required: false
        },
        acceptText: {
            type: String,
            required: true
        }
    }
}
</script>
