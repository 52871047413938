import location from './location'
import {mutationTypes} from '@/commons/constants'
import {geterror} from '@/commons/utils'
import i18n from '@/commons/i18n'
import store from '@/commons/store'

function isDisconnected(error) {
    return error.response
}

function isStatusCode(error, code) {
    return error.response.status === code
}

function handleDisconnected() {
    handleDefault('ERRO_SEM_CONEXAO_INTERNET')
}

function handleStatusCode500(error) {
    const message = geterror(error)
    handleDefault(message)
}

function handleStatusCode401() {
    handleDefault('ERRO_PERDA_SESSAO')
    location.reload()
}

function handleStatusCode404(error) {
    handleStatusCode500(error)
    location.redirect('/')
}

function handleDefault(key) {
    const fullkey = `exceptions.${key}`
    const translatedMessage = i18n.t(fullkey)
    store.commit(mutationTypes.LOKI.SHOW_ALERT, {
        message: translatedMessage,
        type: 'error'
    })
}

class ErrorHandler {

    requestOrResponse(error) {
        store.commit(mutationTypes.LOKI.SET_GLOBAL_LOADING, false)
        store.commit(mutationTypes.LOKI.SET_LOADING_MESSAGE, store.state.defaultLoadingMessage)

        if (isDisconnected(error)) {
            handleDisconnected()

        } else if (isStatusCode(error, 401)) {
            handleStatusCode401()

        } else if (isStatusCode(error, 404)) {
            handleStatusCode404(error)

        } else if (isStatusCode(error, 500)) {
            handleStatusCode500(error)

        } else {
            handleDefault('ERRO_DEFAULT')
        }

        return Promise.reject(error)
    }
}

export default new ErrorHandler()

