<template>
    <div>
        <v-text-field
                :name="$t('company.edit.form.fields.document.name')"
                :label="$t('company.edit.form.fields.document.label')"
                :placeholder="$t('company.edit.form.fields.document.placeholder')"
                :error-messages="errors.collect(`${$t('company.edit.form.fields.document.name')}`)"
                :mask="$t('company.edit.form.fields.document.mask')"
                v-model="value.document"
                v-if="editing"
                v-validate="'required'"/>
        <az-text-view
                :label="$t('company.edit.form.fields.document.label')"
                :text="value.document | mask-filter($t('company.edit.form.fields.document.mask'))"
                v-else/>
    </div>
</template>
<script>
    export default {
        name: 'company-form-document',
        props: {
            editing: {
                type: Boolean,
                default: true
            },
            value: {
            }
        }
    }
</script>
