<template>
    <div style="padding: 20px" class="fa-detail-project-negotiation">
        <v-flex xs12 style="width:100%; border-bottom: 1px solid #ddd" class="py-3 text-md-center">
            <span class="title font-weight-bold primary--text">{{$t('project.participant.detail.negotiation.title')}}</span>
            -
            <span class="body-2">({{$t('project.participant.detail.negotiation.finishAt')}}: {{project.calendar.negociation | azDate('DD/MM/YYYY HH:mm')}})</span>
        </v-flex>
        <v-flex xs12 class="text-md-center pt-5" v-if="thisParticipant.status === 'NOT_DEFINED'" style="margin-top: 14px">
            <v-icon x-large color="grey lighten-1">update</v-icon>
        </v-flex>
        <v-flex xs12 class="text-md-center" v-if="thisParticipant.status === 'NOT_DEFINED'" style="margin-top: 14px">
            <span class="title grey--text lighten-1 font-weight-bold">Por favor aguarde</span>
        </v-flex>
        <v-flex xs12 class="text-md-center" v-if="thisParticipant.status === 'NOT_DEFINED'" style="margin-top: 14px">
            <span class="subheading grey--text lighten-1">{{$t('project.participant.detail.negotiation.notDefineMessage')}}</span>
        </v-flex>


        <v-flex xs12 class="text-md-center pt-5" v-if="thisParticipant.status === 'REPROVED'" style="margin-top: 14px">
            <v-icon x-large color="grey lighten-1">error</v-icon>
        </v-flex>
        <v-flex xs12 class="text-md-center" v-if="thisParticipant.status === 'REPROVED'" style="margin-top: 14px">
            <span class="title grey--text lighten-1 font-weight-bold">Não classificado</span>
        </v-flex>
        <v-flex xs12 class="text-md-center" v-if="thisParticipant.status === 'REPROVED'" style="margin-top: 14px">
            <span class="title grey--text lighten-1">{{$t('project.participant.detail.negotiation.reprovedMessage1')}}</span>
            <br>
            <span class="subheading font-weight-bold red--text lighten-1">{{thisParticipant.reprovalNotes.toLowerCase()}}</span>
            <br>
            <span class="title grey--text lighten-1">{{$t('project.participant.detail.negotiation.reprovedMessage2')}}</span>
        </v-flex>


        <v-flex xs12 class="text-md-center pt-5" v-if="thisParticipant.status === 'APPROVED' && !fileNegotiationSlug">
            <span class="subheading grey--text">{{$t('project.participant.detail.negotiation.approvedMessage')}}</span>
        </v-flex>
        <v-flex xs12 class="text-md-center mb-5" v-if="thisParticipant.status === 'APPROVED' && !fileNegotiationSlug">
            <span class="subheading orange--text font-weight-bold">ASSINAR, CARIMBAR E DIGITALIZAR.</span>
        </v-flex>
        <v-flex xs12 class="text-md-center mb-5" v-if="thisParticipant.status === 'APPROVED' && !fileNegotiationSlug">
            <a :href="fileLinkNegociacao" class="link-download-modelo" download>
                {{$t('project.participant.detail.negotiation.downloadExampleHere')}}
            </a>
        </v-flex>
        <v-flex xs12 class="text-md-center pt-5" v-if="thisParticipant.status === 'APPROVED' && !fileNegotiationSlug">
            <span class="subheading grey--text">em seguida</span>
        </v-flex>
        <v-flex xs12 class="text-md-center" v-if="thisParticipant.status === 'APPROVED' && !fileNegotiationSlug" style="margin: 14px;">
            <az-file-upload
                repository=""
                ref="azFileUploadNegotiation"
                @error="treatFileUploadError">
                <span class="subheading grey--text">{{$t('project.participant.detail.negotiation.dragAndDropFilesHere')}}</span>
            </az-file-upload>
        </v-flex>
        <v-flex xs12 class="text-md-center" v-if="fileNegotiationSlug">
            <v-icon x-large>update</v-icon>
        </v-flex>
        <v-flex xs12 class="text-md-center" v-if="fileNegotiationSlug">
            <span>{{$t('project.participant.detail.negotiation.negotiationCompletedMessage')}}</span>
        </v-flex>
        <v-flex xs12 class="text-md-center" v-if="fileNegotiationSlug">
            <span>{{negotiationDate | azDate}}</span>
        </v-flex>
        <v-flex xs12 class="text-md-center" v-if="fileNegotiationSlug">
            <v-btn color="primary" href="https://leilao.faciles.com.br/">
                {{$t('project.participant.detail.negotiation.enterNegotiationSystemBtn')}}
            </v-btn>
        </v-flex>
        <v-flex xs12 v-if="fileNegotiationSlug" class="text-md-center">
            <v-chip color="teal" text-color="white">
                <v-avatar>
                    <v-icon>check_circle</v-icon>
                </v-avatar>
                {{$t('project.participant.detail.negotiation.fileUploaded')}}
            </v-chip>
        </v-flex>
        <v-flex xs12 v-if="fileNegotiationSlug" class="text-md-center">
            <v-tooltip top>
                <template slot="activator">
                    <v-btn color="error" @click="updateNegotiationInProject(null)" fab small dark>
                        <v-icon>close</v-icon>
                    </v-btn>
                </template>
                <span>{{$t('project.participant.detail.negotiation.removeFile')}}</span>
            </v-tooltip>
        </v-flex>
        <v-flex xs12 sm12 v-if="fileNegotiationSlug" class="text-md-center">
            <a :href="fileLinkNegotiation" download>
                {{$t('project.participant.detail.negotiation.downloadNegotiationFile')}}
            </a>
        </v-flex>
    </div>
</template>

<script>
    import _ from 'lodash'
    import {mapState} from 'vuex'
    import {actionTypes} from '@/commons/constants'

    export default {
        name: "detail-project-negotiation",
        props: ['project'],
        data() {
            return {
                fileLinkNegociacao: null,
                fileNegotiationSlug: null,
                fileLinkNegotiation: null,
                negotiationDate: null
            }
        },
        computed: {
            thisParticipant() {
                let participant = {}
                this.project.participants.forEach(p => {
                    if (p.companyId === this.company._id) {
                        participant = p
                    }
                })
                return participant
            },
            ...mapState(['company', 'loki'])
        },
        watch: {
            'loki.uploadedFiles'() {
                const uploadedFiles = this.loki.uploadedFiles
                const file = uploadedFiles[uploadedFiles.length - 1]
                if (file) {
                    this.updateNegotiationInProject(file.filename)
                    uploadedFiles.splice(uploadedFiles.length - 1, 1)
                    this.$store.commit('SET_UPLOADED_FILES', uploadedFiles)
                }
            }
        },
        methods: {
            buildFileLinkNegociacao() {
                let slugNegotiation = null
                this.project.termsAndConditions.forEach(t => {
                    if (t.type === 'termoNegociacao') {
                        slugNegotiation = t.slug
                    }
                })
                if (slugNegotiation) {
                    let downloadLink = this.buildDownloadLink(slugNegotiation)
                    downloadLink = downloadLink.replace('/true', '')
                    this.fileLinkNegociacao = downloadLink
                }
            },
            setNegotiationSlugAndDate() {
                this.project.participants.forEach(p => {
                    if (p.companyId === this.company._id && p.negotiation) {
                        this.fileNegotiationSlug = p.negotiation.fileSlug
                        this.negotiationDate = p.negotiation.date
                    }
                })
            },
            buildFileLinkNegotiation() {
                if (this.fileNegotiationSlug) {
                    let downloadLink = this.buildDownloadLink(this.fileNegotiationSlug)
                    downloadLink = downloadLink.replace('/true', '')
                    this.fileLinkNegotiation = downloadLink
                }
            },
            async updateNegotiationInProject(negotiationSlug) {
                const project = _.clone(this.project)
                const participantIdx = this.project.participants.findIndex(p => p.companyId === this.company._id)
                const agreed = !!negotiationSlug
                project.participants[participantIdx].negotiation = {date: new Date, fileSlug: negotiationSlug, agreed: agreed}
                await this.$store.dispatch(actionTypes.UPDATE_PARTICIPANT, {
                    projectId: this.project._id,
                    companyId: this.project.participants[participantIdx].companyId,
                    participant: project.participants[participantIdx]
                })
                await this.$store.dispatch(actionTypes.FIND_PROJECT_BY_ID, project._id)
                this.showSuccess()
                this.$emit('updatePhases')
                this.setNegotiationSlugAndDate()
                this.buildFileLinkNegotiation()
            }
        },
        mounted() {
            this.buildFileLinkNegociacao()
            this.setNegotiationSlugAndDate()
            this.buildFileLinkNegotiation()
        }
    }
</script>

<style scoped lang="stylus">

    .link-download-modelo
        background-color: #66bb6a !important
        color: white !important
        padding 10px 15px
</style>
