import store from '@/commons/store'
import {mutationTypes} from '@/commons/constants'
import packageJson from '../../../package'

class SetProduct {
    async execute() {
        store.commit(mutationTypes.LOKI.SET_TIMEZONE, 'America/Sao_Paulo')
        return await store.commit(mutationTypes.SET_PRODUCT, packageJson)
    }
}

export default new SetProduct()
