<template>
    <v-card color="primary" flat>
        <v-container>
            <v-layout align-center justify-center fill-height>
                <img width="50%" src="img/main-logo-landscape.svg"/>
            </v-layout>
        </v-container>
    </v-card>
</template>

<script>
    export default {
    }
</script>
