import store from '@/commons/store'

class InativeCompany {

    shouldExecute(to) {
        const isAdmin = store.state.loggedUser.isAdmin
        const company = store.state.company
        return (!isAdmin && !company.active) && (to.name !== 'addMyCompany')
    }

    execute(to, from, next) {
        const params = to.params
        params.action = 'COMPANY_INACTIVE'
        next({name: 'error', params})
    }
}

export default new InativeCompany()
