<template>
    <v-container fluid grid-list-lg>
        <v-layout row wrap align-center>
            <v-flex xs12>
                <v-alert v-model="showAlert" type="warning">
                    {{$t('company.edit.form.alert')}}
                </v-alert>
            </v-flex>
            <v-layout>
                <v-flex style="width:100%">
                    <v-flex xs12>
                        <company-form-name :editing="editing" v-model="value"/>
                    </v-flex>
                    <v-flex xs12>
                        <company-form-document :editing="editing" v-model="value"/>
                    </v-flex>
                </v-flex>
                <v-flex style="width:200px">
                    <company-form-logo :editing="editing" v-model="value"/>
                </v-flex>
            </v-layout>
            <v-flex xs12>
                <company-form-categories :editing="editing" v-model="value"/>
            </v-flex>
            <v-flex xs8>
                <company-form-address-line1 :editing="editing" v-model="value"/>
            </v-flex>
            <v-flex xs4>
                <company-form-address-line2 :editing="editing" v-model="value"/>
            </v-flex>
            <v-flex xs8>
                <company-form-city :editing="editing" v-model="value"/>
            </v-flex>
            <v-flex xs4>
                <company-form-state :editing="editing" v-model="value"/>
            </v-flex>
            <v-flex xs6>
                <company-form-zipcode :editing="editing" v-model="value"/>
            </v-flex>
            <v-flex xs6>
                <company-form-country :editing="editing" v-model="value"/>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import CompanyFormName from './CompanyFormName'
    import CompanyFormDocument from './CompanyFormDocument'
    import CompanyFormLogo from './CompanyFormLogo'
    import CompanyFormCategories from './CompanyFormCategories'
    import CompanyFormAddressLine1 from './CompanyFormAddressLine1'
    import CompanyFormAddressLine2 from './CompanyFormAddressLine2'
    import CompanyFormCity from './CompanyFormCity'
    import CompanyFormState from './CompanyFormState'
    import CompanyFormZipcode from './CompanyFormZipcode'
    import CompanyFormCountry from './CompanyFormCountry'

    export default {
        name: 'company-form',
        components: {
            CompanyFormName,
            CompanyFormDocument,
            CompanyFormLogo,
            CompanyFormCategories,
            CompanyFormAddressLine1,
            CompanyFormAddressLine2,
            CompanyFormCity,
            CompanyFormState,
            CompanyFormZipcode,
            CompanyFormCountry
        },
        props: {
            editing: {
                type: Boolean,
                default: true
            },
            showAlert: {
                type: Boolean,
                default: false
            },
            value: {
                required: true
            }
        }
    }
</script>
