<template>
    <az-formbar>
        <div :class="inserindo?'hide':'show'">
            <div class="itens">
                <div class="item">
                    <label>{{$t('project.admin.edit.summary.createdAt')}}</label>
                    <span>{{project.createdAt | azDate}}</span>
                </div>
                <div class="item">
                    <label>Status</label>
                    <span>{{project.status | project-status}}</span>
                </div>
            </div>
            <div class="actions" v-if="editing && !loki.isLoading">
                <div class="actions" v-if="isPublished">
                    <az-call-to-action
                        dark
                        :active=false
                        :outline=true
                        @click="confirmBeforeFinish">
                        {{$t('project.admin.edit.summary.finish')}}
                    </az-call-to-action>
                </div>
                <div class="actions" v-if="isDraft">
                    <az-call-to-action
                        dark
                        :active="isAbleToPublish"
                        :outline="!isAbleToPublish"
                        @click="confirmBeforePublish">
                        {{$t('project.admin.edit.summary.publish')}}
                    </az-call-to-action>
                </div>
                <v-menu offset-y left>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" dark icon>
                            <v-icon>more_vert</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="confirmBeforeRemove">
                            <v-list-item-title style="color: #777777">
                                <v-icon size="large" class="pb-1">delete</v-icon>
                                {{$t('project.admin.edit.summary.remove')}}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </div>
        <az-confirm
            :max-width="430"
            v-if="confirm.show"
            :label-confirm="$t('confirmation.yes')"
            :label-decline="$t('confirmation.no')"
            @onConfirm="confirm.onConfirm"
            @onDecline="confirm.onDecline">
            <span class="text-uppercase error--text mt-5" style="display: inline-block;">{{$t('general.caution')}}</span><br/>
            <span>{{confirm.message}}</span>
        </az-confirm>
    </az-formbar>
</template>

<script>
    import {mapState} from 'vuex'
    import {actionTypes, termTypes} from '@/commons/constants'

    export default {
        name: 'summary-bar',
        props: {
            inserindo: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                confirm: {
                    show: false,
                    onConfirm: null,
                    onDecline: this.hideConfirmationDialog,
                    message: ''
                },
                editing: false
            }
        },
        computed: {
            ...mapState(['project', 'loki']),
            isAbleToPublish() {
                return this.hasNeededTermsAndConditions()
            },
            isDraft() {
                return this.project.status === 'DRAFT'
            },
            isPublished() {
                return this.project.status === 'PUBLISHED'
            }
        },
        mounted() {
            this.setViewMode()
        },
        methods: {
            confirmBeforeFinish() {
                this.confirm.show = true
                this.confirm.onConfirm = this.finish
                this.confirm.message = this.$t('project.admin.edit.summary.confirmFinish')
            },
            confirmBeforePublish() {
                if (!this.isAbleToPublish) {
                    return this.showErrorFrontEnd('project.admin.edit.errors.publishWithoutTermsAndConditions')
                }
                this.confirm.show = true
                this.confirm.onConfirm = this.publish
                this.confirm.message = this.$t('project.admin.edit.summary.confirmPublish')
            },
            confirmBeforeRemove() {
                this.confirm.show = true
                this.confirm.onConfirm = this.remove
                this.confirm.message = this.$t('project.admin.edit.summary.confirmRemove')
            },
            async finish() {
                this.hideConfirmationDialog()
                await this.$store.dispatch(actionTypes.FINISH_PROJECT, this.project._id)
                await this.$store.dispatch(actionTypes.FIND_PROJECT_BY_ID, this.project._id)
                this.showSuccess()
            },
            hasNeededTermsAndConditions() {
                const neededTerm = termTypes[0]
                let canPublish = false
                for (let term of this.project.termsAndConditions) {
                    if (term.type === neededTerm) {
                        canPublish = true
                        break
                    }
                }
                return canPublish
            },
            hideConfirmationDialog() {
                this.confirm.show = false
            },
            async publish() {
                this.hideConfirmationDialog()
                await this.$store.dispatch(actionTypes.PUBLISH, this.project._id)
                await this.$store.dispatch(actionTypes.FIND_PROJECT_BY_ID, this.project._id)
                this.showSuccess()
            },
            async remove() {
                try {
                    await this.$store.dispatch(actionTypes.REMOVE_PROJECT, this.project._id)
                    this.showSuccess()
                    this.$router.push({name: 'projects'})
                } catch (e) {
                    this.confirm.show = false
                }
            },
            setViewMode() {
                this.editing = `${this.$route.query.editing}` === 'true'
            }
        }
    }
</script>