<template>
    <div class="fa-edit-project-documents">
        <div class="az-form-content">
            <v-container fluid grid-list-lg>
                <v-layout row wrap align-center>
                    <v-flex v-for="document in documents" :key="document.type" md6>
                        <v-checkbox :label="translateType(document.type)"
                                    v-model="document.mandatory" :disabled="!editing"
                                    hide-details height="20" class="accept" color="primary"/>
                    </v-flex>
                </v-layout>
                <div class="az-actions-form" v-if="editing">
                    <div class="align-left"></div>
                    <div class="align-right">
                        <button class="action-secundary mr-2" @click.prevent="cancel">
                            {{$t('project.admin.edit.cancel')}}
                        </button>
                        <button class="action-primary" @click.prevent="update">
                            {{$t('project.admin.edit.save')}}
                        </button>
                    </div>
                </div>
            </v-container>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import {actionTypes, mutationTypes} from '@/commons/constants'

    export default {
        name: 'edit-project-documents',
        data() {
            return {
                documents: [],
                editing: false,
            }
        },
        computed: {
            ...mapState(['project'])
        },
        watch: {
            'project.requiredDocuments'() {
                this.documents = this.project.requiredDocuments
            },
            'project'() {
                if (this.project.status === 'FINISHED') {
                    this.setViewMode(false)
                }
            }
        },
        async mounted() {
            this.setViewMode(`${this.$route.query.editing}` === 'true')
            this.cleanProjectData()
            await this.findProjectById()
        },
        methods: {
            cancel() {
                this.$router.push({name: 'projects'})
            },
            cleanProjectData() {
                this.$store.commit(mutationTypes.SET_PROJECT, {
                    calendar: {},
                    categories: [],
                    locations: [],
                    termsAndConditions: []
                })
            },
            async findProjectById() {
                try {
                    await this.$store.dispatch(actionTypes.FIND_PROJECT_BY_ID, this.$route.params.id)
                } catch (e) {
                    this.showErrorFrontEnd('project.admin.edit.errors.projectNotFound')
                    this.$router.push({name: 'home'})
                }
            },
            setViewMode(editing) {
                this.editing = editing
            },
            async update() {
                await this.$store.dispatch(actionTypes.UPDATE_REQUIRED_DOCUMENTS, {
                    id: this.$route.params.id,
                    requiredDocuments: this.documents
                })
                await this.findProjectById()
                this.showSuccess()
            },
            translateType(type) {
                const key = `project.requiredDocuments.${type}`
                return this.$t(key)
            }
        }
    }
</script>

<style lang="stylus">
    .fa-edit-project-documents
        .accept label
            font-size 13px
</style>
