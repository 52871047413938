<template>
    <v-dialog :value=true persistent width="500">
        <v-card>
            <v-card-title class="primary white--text" primary-title>
                <v-layout row wrap>
                    <v-flex xs12>
                        <span primary-title class="headline white--text">
                            {{$t('project.participant.search.confirmDropoutProjectTitle')}}
                        </span>
                    </v-flex>
                </v-layout>
            </v-card-title>
            <v-card-title class="orange body-1 lighten-1 white--text">
                <v-icon color="white" class="mr-2">report_problem</v-icon>
                {{$t('project.participant.search.confirmDropoutProject')}}
            </v-card-title>
            <v-card-text>
                <v-layout row wrap>
                    <v-flex xs12 class="mr-2 ml-2">
                        <v-textarea
                                :label="$t('project.participant.search.confirmDropoutTextAreaLabel')"
                                :name="$t('project.participant.search.confirmDropoutTextAreaLabel')"
                                :error-messages="errors.collect(`${$t('project.participant.search.confirmDropoutTextAreaLabel')}`)"
                                v-validate="'required'"
                                v-model="dropoutReason"/>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="text--secondary" text @click="$emit('cancel')">
                    {{$t('project.participant.search.cancelDropout')}}
                </v-btn>
                <v-btn color="primary" text @click="validateBeforeSave">
                    {{$t('project.participant.search.confirmDropout')}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'dropout-project-modal',
        data() {
            return {
                dropoutReason: ''
            }
        },
        methods: {
            async validateBeforeSave() {
                const valid = await this.$validator.validateAll()
                if (valid) {
                    this.$emit('save', this.dropoutReason)
                }
            }
        }
    }
</script>

<style scoped>

</style>
