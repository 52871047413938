<template>
    <v-dialog v-model="openModal" persistent max-width="600px">
        <v-card>
            <v-card-title>
                <h4 class="title mb-1">
                    {{$t('representatives.search.modals.add.title')}}
                </h4>
                <span class="body-2 grey--text text--darken-1">
                    {{$t('representatives.search.modals.add.subtitle')}}
                </span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-form v-on:submit.prevent="save" autocomplete="off" class="mt-5">
                    <v-text-field prepend-icon="person" type="text" maxlength="255"
                                  :name="$t('representatives.search.modals.add.form.fields.name.name')"
                                  :label="$t('representatives.search.modals.add.form.fields.name.label')"
                                  :placeholder="$t('representatives.search.modals.add.form.fields.name.placeholder')"
                                  :error-messages="errors.collect(`${$t('representatives.search.modals.add.form.fields.name.name')}`)"
                                  v-model="value.name"
                                  v-validate="'required'"/>

                    <v-text-field prepend-icon="email" type="email" maxlength="255"
                                  :name="$t('representatives.search.modals.add.form.fields.email.name')"
                                  :label="$t('representatives.search.modals.add.form.fields.email.label')"
                                  :placeholder="$t('representatives.search.modals.add.form.fields.email.placeholder')"
                                  :error-messages="errors.collect(`${$t('representatives.search.modals.add.form.fields.email.name')}`)"
                                  v-model="value.email"
                                  v-validate="'email|required'"/>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text
                       @click="cancel">
                    {{$t('representatives.search.modals.add.form.actions.cancel')}}
                </v-btn>
                <v-btn text color="primary"
                       :disabled="(submited && errors.any())"
                       @click="save">
                    {{$t('representatives.search.modals.add.form.actions.save')}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'representative-add-modal',
        data() {
            return {
                submited: false
            }
        },
        props: {
            value: {
                type: Object,
                required: true
            },
            openModal: {
                type: Boolean,
                required: true
            }
        },
        methods: {
            async save() {
                this.submited = true
                const isValid = await this.$validator.validateAll()
                if (isValid) {
                    this.$emit('save')
                    this.resetForm()
                }
            },
            cancel() {
                this.$emit('cancel')
                this.resetForm()
            },
            resetForm() {
                this.$validator.reset()
            }
        }
    }
</script>
