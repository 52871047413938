<template>
    <v-dialog
        :value=true
        persistent
        scrollable
        width="800">
        <component
            :is="getComponent()"
            :visitors="visitors"
            @back="back"
            @next="next"
            @close="$emit('close')"/>
    </v-dialog>
</template>

<script>
    import VisitorsList from './VisitorsList'
    import SendEmail from './SendEmail'

    export default {
        name: 'visitors',
        props: {
            visitors: {
                type: Array,
                required: true
            }
        },
        components: {
            VisitorsList,
            SendEmail
        },
        data() {
            return {
                steps: {0: VisitorsList, 1: SendEmail},
                currentStep: 0
            }
        },
        methods: {
            back() {
                this.currentStep--
                if (this.currentStep < 0) {
                    this.$emit('close')
                }
            },
            getComponent() {
                return this.steps[this.currentStep]
            },
            next() {
                this.currentStep++
            }
        }
    }
</script>