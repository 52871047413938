<template>
    <container>
        <v-card class="text-md-center">
            <headerbar/>
            <div style="padding: 20px">
                <div v-if="!unsubscribed">
                    {{$t('unsubscribe.subscribedTitle')}}
                </div>
                <div v-if="unsubscribed">
                    {{$t('unsubscribe.unsubscribedTitle')}}
                </div>
                <v-btn v-if="!unsubscribed" color="red" dark depressed="" class="mt-3" @click="unsubscribe">
                    {{$t('unsubscribe.unsubscribeBtn')}}
                </v-btn>
            </div>

            <footerbar target="login"/>
        </v-card>
    </container>
</template>

<script>
    import {actionTypes} from '@/commons/constants'
    import Container from '../../commons/Container'
    import Headerbar from '../../commons/Headerbar'
    import Footerbar from '../../commons/Footerbar'

    export default {
        components: {Footerbar, Container, Headerbar},
        data() {
            return {
                unsubscribed: false
            }
        },
        methods: {
            async unsubscribe() {
                try {
                    await this.$store.dispatch(actionTypes.UNSUBSCRIBE_USER, {email: this.$route.params.emailUser})
                    this.unsubscribed = true
                    this.showSuccess()
                } catch (err) {
                    this.showError(err)
                }
            }
        }
    }
</script>
