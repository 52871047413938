import store from '@/commons/store'
import {actionTypes} from '@/commons/constants'

class GetCities {
    async execute() {
        return  await store.dispatch(actionTypes.LOAD_CITIES)
    }
}

export default new GetCities()
