import stringmask from 'string-mask'

function simplePhone(mask) {
    return typeof mask === 'string'
}

function defineMaskType(value, mask) {
    if (simplePhone(mask)) {
        return mask
    }
    let appropriate = ''
    for (let i in Object.keys(mask)) {
        const maskType = mask[i]
        const maskLength = maskType.match(/#/g).length
        if (value.length === maskLength) {
            appropriate = maskType
        }
    }
    return appropriate
}

const phoneFilter = function (value, mask = '') {
    const maskType = defineMaskType(value, mask)
    return stringmask.apply(value, maskType)
}

export default phoneFilter