<template>
    <div>
        <az-ops :title="azOpsTitle" :message="azOpsMessage" v-if="!hasProjects"/>
        <v-layout row wrap class="pa-2" v-if="hasProjects">
            <project-card v-for="project in projects"
                          v-bind:key="project.id"
                          :project="project"
                          @viewProject="viewProject(project._id)"
                          @giveUpProject="confirmBeforeGiveUp(project)">
            </project-card>
        </v-layout>
        <v-layout row wrap justify-center align-center v-if="hasProjects">
            <v-btn
                    v-if="pagination.page < pages"
                    :loading="loading"
                    :disabled="loading"
                    color="#153c5c"
                    class="white--text"
                    @click="$emit('getMoreProjects')"
            >
                {{$t('project.participant.search.seeMore')}}
                <span slot="loader" class="custom-loader">
                    <v-icon light>cached</v-icon>
                </span>
            </v-btn>
        </v-layout>
        <dropout-project-modal
                v-if="dialogGiveUp"
                @save="giveUpProject"
                @cancel="hideDialogGiveUp"
        ></dropout-project-modal>
    </div>
</template>

<script>
    import ProjectCard from './ProjectCard'
    import DropoutProjectModal from './DropoutProjectModal'
    import {actionTypes} from '@/commons/constants'
    import {mapState} from 'vuex'
    import _ from 'lodash'

    export default {
        name: 'project-search-comp',
        components: {DropoutProjectModal, ProjectCard},
        props: ['projects', 'loading', 'pagination', 'pages', 'azOpsTitle', 'azOpsMessage'],
        data() {
            return {
                dialogGiveUp: false,
                projectToGiveUp: undefined
            }
        },
        computed: {
            hasProjects() {
                return this.projects !== undefined && this.projects.length > 0
            },
            ...mapState(['company'])
        },
        methods: {
            viewProject(id) {
                this.$router.push({name: 'participantDetailProject', params: {id: id}})
            },
            hideDialogGiveUp() {
                this.dialogGiveUp = false
            },
            confirmBeforeGiveUp(project) {
                this.projectToGiveUp = project
                this.dialogGiveUp = true
            },
            async giveUpProject(dropoutReason) {
                const project = _.clone(this.projectToGiveUp)
                const participantDroupout = {
                    companyId: this.company._id,
                    reason: dropoutReason
                }
                const participantIdx = project.participants.findIndex(p => p.companyId === this.company._id)
                project.participants.splice(participantIdx, 1);
                project.dropouts.push(participantDroupout)
                await this.$store.dispatch(actionTypes.UPDATE_PROJECT, {
                    id: project._id,
                    project: project
                })
                this.$emit('setPage', 1)
                this.$emit('getProjects')
                this.showSuccess()
                this.hideDialogGiveUp()
            }
        }
    }
</script>

<style scoped>

</style>
