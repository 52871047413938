import router from '@/views/router'

class Location {
    reload() {
        setTimeout(() => window.location.reload(), 2000)
    }

    redirect(path) {
        router.push({path: path})
    }
}

const location = new Location()
export default location
