import Vue from 'vue'
import alert from './alert'
import asideMenu from './aside-menu'
import file from './file'
import loading from './loading'

Vue.mixin(alert)
Vue.mixin(asideMenu)
Vue.mixin(file)
Vue.mixin(loading)

