<template>
    <container>
        <v-card>
            <login-header/>
            <login-form @submit="login"/>
            <login-footer/>
        </v-card>
    </container>
</template>

<script>
    import Container from '../../commons/Container'
    import LoginHeader from './LoginHeader'
    import LoginFooter from './LoginFooter'
    import LoginForm from './LoginForm'
    import {actionTypes} from '@/commons/constants'
    import {query} from '@/commons/utils'
    import menuBuilder from '@/commons/utils/MenuBuilder'

    export default {
        components: {
            Container,
            LoginFooter,
            LoginHeader,
            LoginForm
        },
        beforeRouteEnter(to, from, next) {
            next(async vm => {
                const sucesso = await vm.loginFacebook()
                if (!sucesso) {
                    await vm.confirmEmailAndLogin()
                    await vm.continueRegister()
                }
            })
        },
        mounted() {
            if (this.$route.query.lostSession) {
                this.showErrorFrontEnd('exceptions.ERRO_PERDA_SESSAO')
            }
        },
        methods: {
            async login(credentials) {
                try {
                    await this.$store.dispatch(actionTypes.LOGIN, credentials)
                    await this.redirectToHome()
                } catch (err) {
                    this.showError(err)
                }
            },
            async loginFacebook() {
                const code = query('code')
                if (code) {
                    await this.$store.dispatch(actionTypes.LOGIN_FACEBOOK, code)
                    await this.redirectToHome()
                    return true
                }
                return false
            },
            async confirmEmailAndLogin() {
                const emailConfirmedToken = query('emailConfirmed')
                if (emailConfirmedToken) {
                    await this.$store.dispatch(actionTypes.CONFIRM_EMAIL, emailConfirmedToken)
                    await this.redirectToHome()
                }
            },
            async continueRegister() {
                const userInvitedToken = query('userInvited')
                if (userInvitedToken) {
                    this.redirectToContinueRegister()
                }
            },
            async redirectToHome() {
                this.setMenuActions()
                const isAdmin = this.$store.state.loggedUser.isAdmin
                if (this.$route.query.redirect) {
                    this.$router.push({path: this.$route.query.redirect})
                } else if (isAdmin) {
                    this.$router.push({name: 'projects'})
                } else {
                    this.$router.push({name: 'participant'})
                }
            },
            redirectToContinueRegister() {
                this.$router.push({name: 'continueRegister'})
            },
            setMenuActions() {
                const lang = this.$route.params.lang
                menuBuilder.build(this.$store, this.$router, lang)
            }
        }
    }
</script>
