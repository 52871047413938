import _ from 'lodash'
import i18n from '@/commons/i18n'

class MenuBuilder {

    build(store, router, lang = 'pt') {
        const actions = []
        router.options.routes[0].children.forEach((route) => {
            translateRouteInfo(route)
            if (shouldShowInMenu(store, route)) {
                let action = createAction(route, lang)
                if (hasChildren(route)) {
                    action.expanded = false
                    action.children = createSubActions(store, route, lang)
                }
                actions.push(action)
            }
        })
        store.commit('SET_MENU_ACTIONS', actions)
    }
}

function createAction(route, lang) {
    return {
        name: i18n.t(`menu.${route.name}.title`),
        icon: route.meta.menu.icon,
        path: route.path.replace(':lang', lang),
        selected: false
    }
}

function createSubActions(store, route, lang) {
    let subactions = []
    route.children.forEach((subRoute) => {
        if (shouldShowInMenu(store, subRoute)) {
            let child = createAction(subRoute, lang)
            subactions.push(child)
        }
    })
    return subactions
}

function shouldShowInMenu(store, route) {
    return route.meta && route.meta.menu && hasAuthorities(store, route)
}

function hasChildren(route) {
    return route.children && route.children.length > 0
}

function hasAuthorities(store, route) {
    let allowed = true
    if (!_.isEmpty(route.meta.authorities)) {
        const userAuthorities = store.state.loki.user.authorities
        const expectedAuthorities = convertToAuthoritiesObject(route.meta.authorities, store.state.loki.product.id)
        allowed = isAllowed(userAuthorities, expectedAuthorities)
    }
    return allowed
}

function isAllowed(sourceAuthorities, expectedAuthorities) {
    let allowed = false
    _.forEach(sourceAuthorities, (authority) => {
        if (_.findIndex(expectedAuthorities, authority) > -1) {
            return allowed = true
        }
    })
    return allowed
}

function convertToAuthoritiesObject(authoritiesSimpleArray, productId) {
    let authoritiesObject = []
    _.forEach(authoritiesSimpleArray, (value) => {
        authoritiesObject.push({name: value, hasAccess: true, produtoId: productId})
    })
    return authoritiesObject
}

function translateRouteInfo(route) {
    if (route.meta && route.meta.page) {
        route.meta.page.title = i18n.t(`menu.${route.name}.title`)
        route.meta.page.subtitle = i18n.t(`menu.${route.name}.subtitle`)
    }
}

export default new MenuBuilder()
