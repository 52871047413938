import i18n from '@/commons/i18n'

const categories = function (categories) {
    let categoriesStr = ''
    categories.forEach((category, index) => {
        if (index < categories.length - 2)
            categoriesStr += i18n.t(`project.categories.${category}`) + ', '
        else if (index === categories.length - 1 && categories.length > 1)
            categoriesStr += ' ' + i18n.t('general.and') + ' ' + i18n.t(`project.categories.${category}`)
        else
            categoriesStr += i18n.t(`project.categories.${category}`)
    })
    return categoriesStr
}

export default categories
