<template>
    <v-flex xs12 sm12>
        <v-data-table
            v-if="hasTerms"
            :headers="headers"
            :items="terms"
            :loading="false"
            hide-default-footer
            disable-pagination
            class="az-table-list mb-4">
            <template v-slot:item="props">
                <tr>
                    <td class="table-status__error">
                        <div v-if="!props.item.type">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" size="18" color="secondary">error_outline</v-icon>
                                </template>
                                <span>
                                    {{$t('project.admin.edit.tabs.termsAndConditions.tableColumns.typeRequired')}}
                                </span>
                            </v-tooltip>
                        </div>
                    </td>
                    <td>
                        <v-select
                            class="pt-0"
                            :items="translatedTermTypes"
                            v-model="props.item.type"
                            v-if="isTypeEditable(props.item)"/>
                        <span v-else>{{$t(`project.termTypes.${props.item.type}`)}}</span>
                    </td>
                    <td>{{ props.item.name }}</td>
                    <td class="table-actions">
                        <div>
                            <a target="_blank" class="az-action-link-icon"
                               :href="buildDownloadLink(props.item.slug)"
                               v-if="props.item._id">
                                <v-icon>get_app</v-icon>
                            </a>
                            <a @click.prevent="$emit('remove', props.item)" v-if="editing">
                                <v-icon>close</v-icon>
                            </a>
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-flex>
</template>

<script>
    import {termTypes} from '@/commons/constants'

    export default {
        name: 'terms-and-conditions-table',
        props: {
            terms: {
                type: Array,
                required: true
            },
            editing: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                headers: [
                    {
                        text: '',
                        align: 'left',
                        sortable: false,
                        width: '2%'
                    },
                    {
                        text: this.$t('project.admin.edit.tabs.termsAndConditions.tableColumns.type'),
                        align: 'left',
                        sortable: false,
                        width: '20%',
                        class: 'primary--text'
                    },
                    {
                        text: this.$t('project.admin.edit.tabs.termsAndConditions.tableColumns.name'),
                        sortable: false,
                        align: 'left',
                        class: 'primary--text'
                    },
                    {
                        text: this.$t('project.admin.edit.tabs.termsAndConditions.tableColumns.actions'),
                        sortable: false,
                        align: 'center',
                        width: '120px',
                        class: 'primary--text'
                    }
                ],
                translatedTermTypes: []
            }
        },
        computed: {
            hasTerms() {
                return this.terms.length > 0
            }
        },
        mounted() {
            this.translateTermTypes()
        },
        methods: {
            isTypeEditable(term) {
                return !term._id
            },
            translateTermTypes() {
                termTypes.map(term => {
                    const key = `project.termTypes.${term}`
                    this.translatedTermTypes.push({text: this.$t(key), value: term})
                })
            }
        }
    }
</script>