<template>
    <div>
        <az-container v-if="hasDocuments">
            <az-form>
                <v-data-table
                        :headers="headers"
                        :items="documents"
                        :loading="false"
                        hide-default-footer
                        disable-pagination
                        class="az-table-list">
                    <template v-slot:item="props">
                        <tr>
                            <td></td>
                            <td>
                                <span>{{$t(`project.requiredDocuments.${props.item.type}`)}}</span>
                            </td>
                            <td :class="getExpirationDateClass(props.item)">
                                <span v-if="props.item.expiresAt">{{props.item.expiresAt | azDate}} </span>
                                <span v-if="props.item.expiresAt">(</span>
                                <span>{{getExpirationDateLabel(props.item)}}</span>
                                <span v-if="props.item.expiresAt">)</span>
                            </td>
                            <td>
                                <span v-if="!props.item.exemption" v-bind:class="{'reproved': props.item.status === 'REPROVED',
                                'approved': props.item.status === 'APPROVED',
                                'analysing': props.item.status === 'ANALYSING'}">
                                    {{$t(`project.participant.documents.status.${props.item.status}`)}}
                                </span>
                                <span v-if="props.item.exemption" class="analysing">
                                    {{$t('project.participant.documents.status.EXEMPT')}}
                                </span>
                            </td>
                            <td class="table-actions">
                                <div>
                                    <a target="_blank" class="az-action-link-icon"
                                    :href="buildDownloadLink(props.item.filename)">
                                        <v-icon>get_app</v-icon>
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </az-form>
        </az-container>
    </div>
</template>

<script>
    import moment from 'moment-timezone'
    import {requiredDocuments} from '@/commons/constants'

    export default {
        name: 'document-search-table',
        props: {
            documents: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                headers: [
                    {
                        text: '',
                        align: 'left',
                        sortable: false,
                        width: '2%'
                    },
                    {
                        text: this.$t('project.participant.documents.tableColumns.type'),
                        align: 'left',
                        sortable: false,
                        width: '40%',
                        class: 'primary--text'
                    },
                    {
                        text: this.$t('project.participant.documents.tableColumns.expiresAt'),
                        sortable: false,
                        align: 'left',
                        class: 'primary--text'
                    },
                    {
                        text: this.$t('project.participant.documents.tableColumns.status'),
                        sortable: false,
                        align: 'left',
                        class: 'primary--text'
                    },
                    {
                        text: this.$t('project.participant.documents.tableColumns.actions'),
                        sortable: false,
                        align: 'center',
                        width: '120px',
                        class: 'primary--text'
                    }
                ],
                translatedDocumentTypes: []
            }
        },
        computed: {
            hasDocuments() {
                return this.documents.length
            }
        },
        watch: {
            'documents'() {
                this.translateDocumentTypes()
            }
        },
        mounted() {
            this.translateDocumentTypes()
        },
        methods: {
            getExpirationDateClass(item) {
                if (item.status === 'REPROVED' || this.isExpired(item.expiresAt)) {
                    return 'red--text font-weight-bold'
                }
            },
            getExpirationDateLabel(item) {
                if (!this.hasExpirationDate(item.expiresAt)) {
                    return item.exemption || !item.status ? '-' : this.$t('project.participant.documents.indefinitePeriod')
                } else if (this.isExpired(item.expiresAt)) {
                    return this.$t('project.participant.documents.expired')
                } else {
                    const today = moment().endOf('day')
                    const endOfExpirationDate = moment(item.expiresAt).endOf('day')
                    const daysLeft = Math.abs(today.diff(endOfExpirationDate, 'days'))
                    const template = this.$t('project.participant.documents.expiresIn')
                    return template.replace('$_X_DAYS_$', daysLeft)
                }
            },
            hasExpirationDate(expDate) {
                return expDate
            },
            isExpired(exp) {
                const now = moment()
                const expDate = moment(exp)
                return now.isAfter(expDate)
            },
            containsInDocuments(type) {
                return this.documents.filter(e => e.type === type).length > 0
            },
            translateDocumentTypes() {
                this.translatedDocumentTypes = []
                requiredDocuments.map(doc => {
                    const key = `project.requiredDocuments.${doc}`
                    if (!this.containsInDocuments(doc)) {
                        this.translatedDocumentTypes.push({text: this.$t(key), value: doc})
                    }
                })
                this.translatedDocumentTypes.sort((a, b) => a.text.localeCompare(b.text))
            }
        }
    }
</script>

<style lang="stylus">
    .reproved
        width 120px
        background-color red
        color white
        padding 2px 10px
        border-radius 20px
        text-align center

    .approved
        width 120px
        background-color green
        color white
        padding 2px 10px
        border-radius 20px
        text-align center

    .analysing
        width 120px
        background-color #777
        color white
        padding 2px 10px
        border-radius 20px
        text-align center
</style>
