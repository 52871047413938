import configureFileManager from './ConfigureFileManager'
import getCities from './GetCities'
import getLoggedUser from './GetLoggedUser'
import setProduct from './SetProduct'
import setMenuAvatar from './SetMenuAvatar'

export default {
    setProduct,
    setMenuAvatar,
    getLoggedUser,
    getCities,
    configureFileManager
}
