export default {
    CLEAN_COMPANY_SEARCH_FILTERS: 'cleanCompanySearchFilters',
    CLEAN_CUSTOMER_SEARCH_FILTERS: 'cleanCustomerSearchFilters',
    CLEAN_PROJECT_SEARCH_FILTERS: 'cleanProjectSearchFilters',
    CLEAN_USER_SEARCH_FILTERS: 'cleanUserSearchFilters',
    CLEAN_LOG_EMAIL_SEARCH_FILTERS: 'cleanLogEmailSearchFilters',
    LOKI: {
        DISABLE_GLOBAL_LOADING: 'disableGlobalLoading',
        ENABLE_GLOBAL_LOADING: 'enableGlobalLoading',
        SET_ASIDE: 'SET_ASIDE',
        SET_GLOBAL_LOADING: 'setGlobalLoading',
        SET_LOADING_MESSAGE: 'setLoadingMessage',
        SHOW_ALERT: 'showAlert',
        SET_FILES_CONFIG: 'SET_FILES_CONFIG',
        SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
        SET_TIMEZONE: 'setTimezone'
    },
    SET_CITIES: 'setCities',
    SET_COMPANY: 'setCompany',
    SET_GLOBAL_LOADING : 'setGlobalLoading',
    SET_LOGGED_USER: 'setLoggedUser',
    SET_MENU_AVATAR: 'setMenuAvatar',
    SET_PRODUCT: 'setProduto',
    SET_PROJECT: 'setProject',
    SET_COMPANY_SEARCH_FILTERS: 'setCompanySearchFilters',
    SET_CUSTOMER_SEARCH_FILTERS: 'setCustomerSearchFilters',
    SET_PROJECT_SEARCH_FILTERS: 'setProjectSearchFilters',
    SET_LOG_EMAIL_SEARCH_FILTERS: 'setLogEmailSearchFilters',
    SET_USER_SEARCH_FILTERS: 'setUserSearchFilters',
    SET_COMPANY_SEARCH_PAGINATION: 'setCompanySearchPagination',
    SET_CUSTOMER_SEARCH_PAGINATION: 'setCustomerSearchPagination',
    SET_PROJECT_SEARCH_PAGINATION: 'setProjectSearchPagination',
    SET_LOG_EMAIL_SEARCH_PAGINATION: 'setLogEmailSearchPagination',
    SET_USER_SEARCH_PAGINATION: 'setUserSearchPagination',
    SET_TOKEN: 'setToken'
}
