<template>
    <div>
        <v-text-field
                :name="$t('users.edit.form.fields.phone2.name')"
                :label="$t('users.edit.form.fields.phone2.label')"
                :placeholder="$t('users.edit.form.fields.phone2.placeholder')"
                :error-messages="errors.collect(`${$t('users.edit.form.fields.phone2.name')}`)"
                v-model="value.phoneNumber2"
                v-mask="JSON.parse($t('users.edit.form.fields.phone1.mask'))"
                v-if="editing">
        </v-text-field>
        <az-text-view
                :label="$t('users.edit.form.fields.phone2.label')"
                :text="showPhoneNumber(value.phoneNumber2) |  phone-filter(getPhoneMask())"
                v-else/>
    </div>
</template>
<script>
    export default {
        name: 'user-form-phone2',
        props: {
            editing: {
                type: Boolean,
                default: true
            },
            value: {
            }
        },
        methods: {
            showPhoneNumber(phoneNumber) {
                if (phoneNumber) {
                    return phoneNumber
                } else {
                    return ''
                }
            },
            getPhoneMask() {
                return JSON.parse(this.$t('company.edit.form.fields.representatives.table.masks.phone'))
            }
        }
    }
</script>
