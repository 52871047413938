import Vue from 'vue'
import {mutationTypes} from '@/commons/constants'

export default {
    [mutationTypes.CLEAN_COMPANY_SEARCH_FILTERS](state) {
        state.companySearch.filters = {
            nameDoc: {value: null, default: null, label: 'Nome'}
        }
    },

    [mutationTypes.CLEAN_CUSTOMER_SEARCH_FILTERS](state) {
        state.customerSearch.filters = {
            nameDoc: {value: null, default: null, label: 'Nome'}
        }
    },

    [mutationTypes.CLEAN_PROJECT_SEARCH_FILTERS](state) {
        state.projectSearch.filters = {
            nameCustomer: {value: null, default: null, label: 'Nome'}
        }
    },

    [mutationTypes.CLEAN_LOG_EMAIL_SEARCH_FILTERS](state) {
        state.logEmailSearch.filters = {
            searchedText: {value: null, default: null, label: 'Pesquisa'}
        }
    },

    [mutationTypes.CLEAN_USER_SEARCH_FILTERS](state) {
        state.userSearch.filters = {
            nameEmail: {value: null, default: null, label: 'Nome'},
            source: {value: null, default: null, label: 'Source'}
        }
    },

    [mutationTypes.SET_CITIES](state, cities) {
        state.cities = cities
    },

    [mutationTypes.SET_COMPANY](state, company) {
        state.company = company
    },

    [mutationTypes.SET_GLOBAL_LOADING](state, isLoading) {
        state.loading = isLoading
    },

    [mutationTypes.SET_LOGGED_USER](state, user) {
        const newUser = {
            id: user.id,
            name: user.email,
            fullName: user.name,
            phoneNumber1: user.phoneNumber1,
            phoneNumber2: user.phoneNumber2,
            photo: user.photo,
            source: user.source,
            isAdmin: user.isAdmin,
            authorities: [
                {name: 'admin', hasAccess: user.isAdmin},
                {name: 'participant', hasAccess: !user.isAdmin}
            ]
        }
        Vue.set(state.loki, 'user', newUser)
        Vue.set(state, 'loggedUser', newUser)
    },

    [mutationTypes.SET_MENU_AVATAR](state, actions) {
        Vue.set(state.loki, 'avatarActions', actions)
    },

    [mutationTypes.SET_PRODUCT](state, packageJson) {
        state.loki.product.name = 'Faciles'
        state.loki.product.mainLogo = '/img/main-logo.svg'
        state.loki.product.symbolLogo = '/img/symbol-logo.svg'
        state.loki.product.version = packageJson.version
        state.loki.product.copywrite = 'Todos os direitos reservados'
        state.loki.product.logoutUrl = '#/pt/logout'
    },

    [mutationTypes.SET_PROJECT](state, project) {
        Vue.set(state, 'project', project)
        Vue.set(state.project.calendar, 'kickoff', project.calendar.kickoff)
        Vue.set(state.project.calendar, 'basicDocuments', project.calendar.basicDocuments)
        Vue.set(state.project.calendar, 'additionalDocuments', project.calendar.additionalDocuments)
        Vue.set(state.project.calendar, 'visitation', project.calendar.visitation)
        Vue.set(state.project.calendar, 'negociation', project.calendar.negociation)
    },

    [mutationTypes.SET_COMPANY_SEARCH_FILTERS](state, filters) {
        state.companySearch.filters = filters
    },

    [mutationTypes.SET_CUSTOMER_SEARCH_FILTERS](state, filters) {
        state.customerSearch.filters = filters
    },

    [mutationTypes.SET_PROJECT_SEARCH_FILTERS](state, filters) {
        state.projectSearch.filters = filters
    },

    [mutationTypes.SET_LOG_EMAIL_SEARCH_FILTERS](state, filters) {
        state.logEmailSearch.filters = filters
    },

    [mutationTypes.SET_USER_SEARCH_FILTERS](state, filters) {
        state.userSearch.filters = filters
    },

    [mutationTypes.SET_COMPANY_SEARCH_PAGINATION](state, pagination) {
        state.companySearch.pagination = pagination
    },

    [mutationTypes.SET_CUSTOMER_SEARCH_PAGINATION](state, pagination) {
        state.customerSearch.pagination = pagination
    },

    [mutationTypes.SET_PROJECT_SEARCH_PAGINATION](state, pagination) {
        state.projectSearch.pagination = pagination
    },

    [mutationTypes.SET_LOG_EMAIL_SEARCH_PAGINATION](state, pagination) {
        state.logEmailSearch.pagination = pagination
    },

    [mutationTypes.SET_USER_SEARCH_PAGINATION](state, pagination) {
        state.userSearch.pagination = pagination
    },

    [mutationTypes.SET_TOKEN](state, token) {
        state.token = token
    }
}
