<template>
    <div>
        <az-toolbar>
            <az-call-to-action style="height: 30px; font-size: 14px" active slot="actions" @click="showAddModal">
                <v-icon size="18px">add_circle</v-icon>
                {{ $t('representatives.search.actions.add') }}
            </az-call-to-action>
        </az-toolbar>
        <representative-search-table
                :representatives="representatives"
                v-if="representatives.length"/>
        <az-ops :title="$t('representatives.search.noRepresentativesFound.title')"
                :message="$t('representatives.search.noRepresentativesFound.message')"
                v-if="representatives.length === 0"/>
        <representative-add-modal
                v-model="form.representative"
                :openModal="openAddModal"
                @cancel="closeAddModal"
                @save="save"/>
    </div>
</template>

<script>
    import RepresentativeSearchTable from './RepresentativeSearchTable'
    import RepresentativeAddModal from './components/RepresentativeAddModal'
    import {actionTypes} from '@/commons/constants'
    import {mapState} from 'vuex'

    export default {
        name: 'representative-search',
        components: {RepresentativeSearchTable, RepresentativeAddModal},
        data() {
            return {
                representatives: [],
                openAddModal: false,
                form: {
                    representative: {
                        name: '',
                        email: ''
                    }
                }
            }
        },
        computed: {
            ...mapState(['company'])
        },
        methods: {
            async findRepresentativesByCompanyId() {
                try {
                    this.representatives = await this.$store.dispatch(actionTypes.FIND_REPRESENTATIVES_BY_COMPANY_ID, this.company._id)
                } catch (e) {
                    this.showErrorFrontEnd('representatives.search.errors.findRepresentatives')
                }
            },
            showAddModal() {
                this.openAddModal = true
            },
            closeAddModal() {
                this.openAddModal = false
                this.form.representative = {name: '', email: ''}
            },
            async save() {
                await this.$store.dispatch(actionTypes.INVITE_REPRESENTATIVE, {companyId: this.company._id, representative: this.form.representative})

                this.showSuccess()
                this.closeAddModal()
                await this.findRepresentativesByCompanyId()
            }
        },
        mounted() {
            this.findRepresentativesByCompanyId()
            this.openAsideMenu()
        }
    }
</script>
