import axios from 'axios'
import {AzSearchUrlBuilder} from '@azinformatica/loki'
import {actionTypes, mutationTypes} from '@/commons/constants'

export default {
    async [actionTypes.ADD_PARTICIPANT_TO_DROPOUTS](context, {projectId, companyId, reason}) {
        await axios.post(`api/projects/${projectId}/dropouts/${companyId}`, {reason})
    },

    async [actionTypes.ADD_TERMS_AND_CONDITIONS](context, {id, termsAndConditions}) {
        await axios.post(`api/projects/${id}/termsAndConditions`, {termsAndConditions})
    },

    [actionTypes.ADD_TOKEN](context, token) {
        sessionStorage.setItem('jwt', token)
    },

    async [actionTypes.AFTER_LOGIN]({dispatch, commit}, data) {
        dispatch(actionTypes.ADD_TOKEN, data.token)
        commit(mutationTypes.SET_TOKEN, data.token)
        commit(mutationTypes.SET_LOGGED_USER, data.payload)
        await dispatch(actionTypes.GET_COMPANY)
    },

    async [actionTypes.CONFIRM_EMAIL]({dispatch}, emailConfirmedToken) {
        const {data} = await axios.get(`api/auth/confirm/${emailConfirmedToken}`)
        await dispatch(actionTypes.AFTER_LOGIN, data)
    },

    async [actionTypes.CONFIRM_USER_INVITED]({dispatch}, {id, user}) {
        const {data} = await axios.put(`api/auth/confirm/${id}/invite`, user)
        await dispatch(actionTypes.AFTER_LOGIN, data)
    },

    async [actionTypes.CREATE_COMPANY](context, company) {
        const {data} = await axios.post(`api/companies`, company)
        return data
    },

    async [actionTypes.CREATE_CUSTOMER](context, customer) {
        const {data} = await axios.post(`api/customers`, customer)
        return data
    },

    async [actionTypes.CREATE_DOCUMENT](context, {documents, companyId}) {
        documents.forEach(doc => {
            doc.companyId = companyId
        })
        const {data} = await axios.post(`api/companies/:companyId/documents`, {documents})
        return data
    },

    async [actionTypes.CREATE_PROJECT](context, project) {
        const {data} = await axios.post(`api/projects`, project)
        return data
    },

    async [actionTypes.FIND_ALL_VISITORS](context, projectId) {
        const {data} = await axios.get(`api/projects/${projectId}/visitors`)
        return data
    },

    async [actionTypes.FIND_COMPANIES]({state}) {
        const filters = state.companySearch.filters
        const pagination = state.companySearch.pagination
        const url = AzSearchUrlBuilder.build('api/companies', filters, pagination)
        const {data} = await axios.get(url)
        return data
    },

    async [actionTypes.FIND_COMPANY_BY_ID](context, companyId) {
        const {data} = await axios.get(`api/companies/${companyId}`)
        return data
    },

    async [actionTypes.FIND_CUSTOMERS]({state}) {
        const filters = state.customerSearch.filters
        const pagination = state.customerSearch.pagination
        const url = AzSearchUrlBuilder.build('api/customers', filters, pagination)
        const {data} = await axios.get(url)
        return data
    },

    async [actionTypes.FIND_CUSTOMER_BY_ID](context, customerId) {
        const {data} = await axios.get(`api/customers/${customerId}`)
        return data
    },

    async [actionTypes.FIND_DOCUMENTS_BY_COMPANY_ID](context, companyId) {
        const {data} = await axios.get(`api/documents/byCompany/${companyId}`)
        return data
    },

    async [actionTypes.FIND_LATEST_VERSION_OF_DOCUMENTS](context, {companyId, types}) {
        const {data} = await axios.get(`api/companies/${companyId}/documents/latest?types=${types}`)
        return data
    },

    async [actionTypes.FIND_PROJECT_BY_ID]({commit}, id) {
        const {data} = await axios.get(`api/projects/${id}`)
        data.locations.forEach(location => location.formattedLocation = `${location.name} - ${location.state}`)
        data.calendar = data.calendar ? data.calendar : {}
        data.termsAndConditions = data.termsAndConditions ? data.termsAndConditions : []
        commit(mutationTypes.SET_PROJECT, data)
    },

    async [actionTypes.FIND_PROJECT_DROPOUTS](context, projectId) {
        const {data} = await axios.get(`api/projects/${projectId}/dropouts`)
        return data
    },

    async [actionTypes.FIND_PROJECT_PARTICIPANTS](context, projectId) {
        const {data} = await axios.get(`api/projects/${projectId}/participants`)
        return data
    },

    async [actionTypes.FIND_PROJECTS]({state}) {
        const filters = state.projectSearch.filters
        const pagination = state.projectSearch.pagination
        const url = AzSearchUrlBuilder.build('api/projects', filters, pagination)
        const {data} = await axios.get(url)
        return data
    },

    async [actionTypes.FIND_USER_BY_ID](context, id) {
        const {data} = await axios.get(`api/users/detail/${id}`)
        return data
    },

    async [actionTypes.FIND_USERS]({state}) {
        const filters = state.userSearch.filters
        const pagination = state.userSearch.pagination
        const url = AzSearchUrlBuilder.build('api/users', filters, pagination)
        const {data} = await axios.get(url)
        return data
    },

    async [actionTypes.FIND_REPRESENTATIVES_BY_COMPANY_ID](context, companyId) {
        const {data} = await axios.get(`api/companies/${companyId}/representatives`)
        return data
    },

    async [actionTypes.FIND_LOG_EMAIL]({state}) {
        const filters = state.logEmailSearch.filters
        const pagination = state.logEmailSearch.pagination
        const url = AzSearchUrlBuilder.build('api/logemails', filters, pagination)
        const {data} = await axios.get(url)
        return data
    },

    async [actionTypes.FINISH_PROJECT](context, projectId) {
        await axios.put(`api/projects/${projectId}/finish`)
    },

    async [actionTypes.FORGET_PASSWORD](context, email) {
        const {data} = await axios.post(`api/auth/password/${email}`)
        return data
    },

    async [actionTypes.GET_COMPANY]({state, commit}) {
        const userId = state.loggedUser.id
        const {data} = await axios.get(`api/companies/representatives/${userId}`)
        if (data.length === 0) {
            commit(mutationTypes.SET_COMPANY, {})
        } else {
            commit(mutationTypes.SET_COMPANY, data[0])
        }
    },

    async [actionTypes.GET_USER](context, email) {
        return await axios.get(`api/users/${email}`)
    },

    async [actionTypes.INSERT_USER](context, user) {
        const {data} = await axios.post(`api/users`, user)
        return data
    },

    async [actionTypes.INVITE_REPRESENTATIVE](context, {companyId, representative}) {
        await axios.post(`api/companies/${companyId}/representatives`, representative)
    },

    async [actionTypes.LOAD_CITIES]({commit}) {
        const {data} = await axios.get(`api/cities`)
        commit(mutationTypes.SET_CITIES, data)
    },

    async [actionTypes.LOGIN]({dispatch}, credencials) {
        const {data} = await axios.post(`api/auth/login`, credencials)
        await dispatch(actionTypes.AFTER_LOGIN, data)
    },

    async [actionTypes.LOGIN_FACEBOOK]({dispatch}, code) {
        const {data} = await axios.get(`api/auth/login/facebook?code=${code}`)
        await dispatch(actionTypes.AFTER_LOGIN, data)
    },

    async [actionTypes.LOGOUT]({dispatch, commit}) {
        dispatch(actionTypes.REMOVE_TOKEN)
        commit(mutationTypes.SET_TOKEN, '')
        commit(mutationTypes.SET_LOGGED_USER, '')
    },

    async [actionTypes.PUBLISH](context, projectId) {
        await axios.put(`api/projects/${projectId}/publish`)
    },

    async [actionTypes.REINVITE_USERS](context, ids) {
        await axios.put(`api/auth/reinvite`, {ids})
    },

    async [actionTypes.REGISTER](context, userData) {
        const {data} = await axios.post(`api/auth/register`, userData)
        return data
    },

    async [actionTypes.REMOVE_COMPANY](context, companyId) {
        await axios.delete(`api/companies/${companyId}`)
    },

    async [actionTypes.REMOVE_DOCUMENT](context, {companyId, documentId}) {
        await axios.delete(`api/companies/${companyId}/documents/${documentId}`)
    },

    async [actionTypes.REMOVE_LOG_EMAILS](context, ids) {
        await axios.delete(`api/logemails?ids=${ids}`)
    },

    async [actionTypes.REMOVE_PARTICIPANT](context, {projectId, companyId}) {
        await axios.delete(`api/projects/${projectId}/participants/${companyId}`)
    },

    async [actionTypes.REMOVE_PROJECT](context, id) {
        await axios.delete(`api/projects/${id}`)
    },

    async [actionTypes.REMOVE_TERM](context, {projectId, termId}) {
        await axios.delete(`api/projects/${projectId}/termsAndConditions/${termId}`)
    },

    [actionTypes.REMOVE_TOKEN]() {
        sessionStorage.removeItem('jwt')
    },

    async [actionTypes.REMOVE_USER](context, userId) {
        await axios.delete(`api/users/${userId}/participants`)
    },

    async [actionTypes.RESET_USER_PASSWORD](context, userId) {
        const {data} = await axios.put(`api/users/${userId}/password`)
        return data
    },

    async [actionTypes.SEND_VISITORS_LIST_EMAIL](context, {projectId, emails}) {
        await axios.post(`api/projects/${projectId}/visitorsNotification`, {emails})
    },

    async [actionTypes.UNSUBSCRIBE_USER](context, {email}) {
        return await axios.put(`api/unsubscribeUser/${email}`)
    },

    async [actionTypes.UPDATE_COMPANY](context, {companyId, company}) {
        return await axios.put(`api/companies/${companyId}`, company)
    },

    async [actionTypes.UPDATE_CUSTOMER](context, {customerId, customer}) {
        const {data} = await axios.put(`api/customers/${customerId}`, customer)
        return data
    },

    async [actionTypes.UPDATE_DOCUMENT](context, {companyId, documentId, document}) {
        await axios.put(`api/companies/${companyId}/documents/${documentId}`, {document})
    },

    async [actionTypes.UPDATE_DOCUMENT_STATUS](context, {companyId, documentId, document, project}) {
        await axios.put(`api/companies/${companyId}/documents/${documentId}/status`, {document, project})
    },

    async [actionTypes.UPDATE_PARTICIPANT](context, {projectId, companyId, participant}) {
        await axios.put(`api/projects/${projectId}/participants/${companyId}`, {participant})
    },

    async [actionTypes.UPDATE_PARTICIPANT_STATUS](context, {projectId, participantId, status, reprovalNotes}) {
        await axios.put(`api/projects/${projectId}/participants/${participantId}/status`, {status, reprovalNotes})
    },

    async [actionTypes.UPDATE_PASSWORD]({dispatch}, credencials) {
        const {data} = await axios.put(`api/auth/password`, credencials)
        await dispatch(actionTypes.AFTER_LOGIN, data)
    },

    async [actionTypes.UPDATE_PROJECT](context, {id, project}) {
        await axios.put(`api/projects/${id}`, {project})
    },

    async [actionTypes.UPDATE_REQUIRED_DOCUMENTS](context, {id, requiredDocuments}) {
        await axios.put(`api/projects/${id}/requiredDocuments`, {requiredDocuments})
    },

    async [actionTypes.UPDATE_USER](context, {userId, user}) {
        const {data} = await axios.put(`api/users/${userId}`, user)
        return data
    },

    async [actionTypes.VERIFY_TOKEN]({commit}, token) {
        const {data} = await axios.get(`api/auth/verify/${token}`)
        commit(mutationTypes.SET_TOKEN, token)
        commit(mutationTypes.SET_LOGGED_USER, data)
        return data
    }
}
