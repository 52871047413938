<template>
    <div class="fa-company-form-representatives">
        <v-flex xs12>
            <h2 class="secondary--text font-weight-bold subtitle-1 mb-0">
                {{$t('company.edit.form.fields.representatives.title')}}
            </h2>
            <span class="font-weight-regular subtitle-2 subtitulo">
                {{$t('company.edit.form.fields.representatives.subtitle')}}
            </span>
        </v-flex>
        <v-flex xs12 class="form-container ml-3">
            <v-layout row wrap class="form">
                <v-flex md8>
                    <v-autocomplete
                            :name="$t('company.edit.form.fields.representatives.nameOrEmail.name')"
                            :label="$t('company.edit.form.fields.representatives.nameOrEmail.label')"
                            :placeholder="$t('company.edit.form.fields.representatives.nameOrEmail.placeholder')"
                            :error-messages="errors.collect(`general.${$t('company.edit.form.fields.representatives.nameOrEmail.name')}`)"
                            :data-vv-scope="validatorScope"
                            :items="users"
                            :search-input.sync="userSearch"
                            :loading="isLoadingUsers"
                            :no-data-text="$t('company.edit.form.fields.representatives.nameOrEmail.noDataText')"
                            :rules="[rules.user]"
                            :error="!rules.user"
                            return-object
                            v-model="userSelected"
                            v-if="editing"/>
                </v-flex>
                <v-flex md4 class="text-xs-center">
                    <button @click.prevent="add()" class="button-add"  :disabled="!userSelected">
                        <v-icon color="green">add_circle</v-icon>
                        {{$t('company.edit.form.fields.representatives.addLabel')}}
                    </button>
                </v-flex>
            </v-layout>
        </v-flex>
    </div>
</template>
<script>
    import {actionTypes, mutationTypes} from '@/commons/constants'
    import _ from 'lodash'
    export default {
        name: 'company-form-representatives-search',
        props: {
            editing: {
                type: Boolean,
                required: true
            },
            value: {
            }
        },
        data() {
            return {
                users: [],
                userSearch: null,
                isLoadingUsers: false,
                userSelected: null,
                rules: {
                    user: true
                },
                validatorScope: 'general'
            }
        },
        watch: {
            async 'userSearch'(value) {
                if (this.hasContent(value) && this.hasMinLength(value)) {
                    return
                }
                this.users = await this.findUsers(value)
            }
        },
        methods: {
            add() {
                if (this.isFoundUserAlreadyAddedInList()) {
                    this.showErrorMessage()
                    return
                }
                this.addFoundUserInRepresentativeList()
                this.clearForm()
            },
            addFoundUserInRepresentativeList() {
                this.value.representatives.push(this.userSelected.value)
            },
            getFoundUsersList(foundUsers) {
                const users = []
                foundUsers.content.forEach(item => users.push({
                    text: item.user.name,
                    value: item.user
                }))
                return users
            },
            clearForm() {
                this.users = []
                this.userSearch = null
                this.isLoadingUsers = false
                this.userSelected = null
                this.rules.user = true
            },
            changeLoadingUsersStatus(status) {
                this.isLoadingRepresentatives = status
            },
            async findUsers(searchCriteria) {
                this.disableGlobalLoading()
                this.changeLoadingUsersStatus(true)
                const foundUsers = await this.findUsersByNameOrEmail(searchCriteria)
                const users = this.getFoundUsersList(foundUsers)
                this.changeLoadingUsersStatus(false)
                this.enableGlobalLoading()
                return users
            },
            async findUsersByNameOrEmail(searchCriteria) {
                const filters = _.clone(this.$store.state.userSearch.filters)
                filters.nameEmail.value = searchCriteria
                this.$store.commit(mutationTypes.SET_USER_SEARCH_FILTERS, filters)
                return await this.$store.dispatch(actionTypes.FIND_USERS)
            },
            hasContent(value) {
                return value
            },
            hasMinLength(value) {
                return value.length < 3
            },
            isFoundUserAlreadyAddedInList() {
                let userAdded = false
                this.value.representatives.forEach(representative => {
                    if (representative._id === this.userSelected.value._id) {
                        userAdded = true
                    }
                })                
                return userAdded
            },
            showErrorMessage() {
                this.rules.user = this.$t('company.edit.form.fields.representatives.email.errors.userAlreadyAddedInList')
            }
        }
    }
</script>
<style lang="stylus">
.fa-company-form-representatives
    .subtitulo
        color #757575
    .form-container
        margin-bottom 20px
    .form
        align-items center
        justify-content center
    .button-add
        display flex
        align-items center
        margin 0 auto
        color green
        &:disabled
            cursor not-allowed
        & i
            margin-right 5px
</style>
