import {mapMutations} from 'vuex'
import {mutationTypes} from '@/commons/constants'

export default {
    methods: {
        ...mapMutations([
            mutationTypes.LOKI.DISABLE_GLOBAL_LOADING,
            mutationTypes.LOKI.ENABLE_GLOBAL_LOADING,
            mutationTypes.LOKI.SET_LOADING_MESSAGE])
    }
}
