<template>
    <div>
        <v-text-field
                :name="$t('users.edit.form.fields.email.name')"
                :label="$t('users.edit.form.fields.email.label')"
                :placeholder="$t('users.edit.form.fields.email.placeholder')"
                :error-messages="errors.collect(`${$t('users.edit.form.fields.email.name')}`)"
                v-model="value.email"
                v-if="editing"
                v-validate="'required'">
        </v-text-field>
        <az-text-view
                :label="$t('users.edit.form.fields.email.label')"
                :text="value.email"
                v-else/>
    </div>
</template>
<script>
    export default {
        name: 'user-form-email',
        props: {
            editing: {
                type: Boolean,
                default: true
            },
            value: {
            }
        }
    }
</script>
