export default [
    'licensaOperacao',
    'declaracaoRegimeTributario',
    'declaracaoLucroReal',
    'inscricaoEstadual',
    'certificadoVerificacao',
    'alvaraPrefeitura',
    'autoVistoriaCorpoBombeiro',
    'certificadoMovimentacaoResiduoInteresseAmbiental',
    'parecerDesfavoravelCertificadoMovimentacaoResiduosInteresseAmbiental',
    'certificadoRegularidadeCR',
    'iso9001',
    'iso14001',
    'certificadoDispensaLicensa',
    'anexo2'
]
