import pt from 'vuetify/es5/locale/pt'
import en from 'vuetify/es5/locale/en'

export default {
    pt: {
        $vuetify: pt,
        general: {
            yes: 'Sim',
            no: 'Não',
            caution: 'Atenção',
            and: 'e'
        },
        exceptions: {
            ERRO_401: 'Sua sessão expirou. Você será redirecionado para o login...',
            ERRO_DEFAULT: 'Ocorreu um erro ao realizar a operação.',
            ERRO_SEM_CONEXAO_INTERNET: 'Verifique a conexão com a internet.',
            ERRO_PERDA_SESSAO: 'Desculpe, você perdeu a sessão e sua página será recarregada.',
            UNSPECIFIED_TOKEN: 'O token não foi especificado. Você será redirecionado para a página de login...',
            SUCESSO_DEFAULT: 'Operação realizada com sucesso.',
            CANOT_CHANGE_USER_FROM_SOCIAL_NETWORK: 'Ops, seu usuário foi criado a partir de uma rede social, não é possível alterar a senha por aqui.',
            CANOT_CREATE_THUMBNAL: 'Ops, infelizmente não conseguimos criar uma imagem minificada. Por favor, informe outra imagem.',
            DUPLICATE_REGISTER: 'Ops, este email já foi inserido antes, por favor, verifique a opção esqueci senha',
            EMAIL_NOT_EXISTS: 'Ops, verificamos que este email não existe em nosso cadastro.',
            FILE_CORRUPTED: 'Ops, não conseguimos recuperar este arquivo',
            FILE_NOT_FOUND: 'Ops, não conseguimos encontrar este arquivo no nosso cadastro',
            WRONG_PASSWORD: 'Ops, sua senha ou email são inválidos, por favor, tente novamente.',
            WAITING_PROCESSING: 'Ops, ainda não conseguimos emitir sua certidão, aguarde o fim do processamento.',
            USER_UPDATED: 'Seus dados pessoais foram atualizados com sucesso.',
            COMPANY_WITH_DUPLICATED_NAME: 'Já existe uma companhia cadastrada com o mesmo nome.',
            COMPANY_WITH_DUPLICATED_DOCUMENT: 'Já existe uma companhia cadastrada com o mesmo CNPJ.',
            REPRESENTATIVE_ALREADY_INVITED: 'O representante já faz parte da empresa.',
            CUSTOMER_WITH_DUPLICATED_NAME: 'Já existe um cliente cadastrado com o mesmo nome.',
            CUSTOMER_WITH_DUPLICATED_DOCUMENT: 'Já existe um cliente cadastrado com o mesmo CNPJ.',
            FILE_BIGGER_THAN_ACCEPTED: 'O arquivo selecionado deve ter no máximo 20Mb.'
        },
        login: {
            forgetPassword: 'Esqueceu sua senha?',
            login: 'Entrar',
            email: 'Email',
            password: 'Senha',
            register: 'Cadastre-se',
            inviteToRegister: 'Ainda não criou sua conta?',
            inviteToAgreeTerms: 'Ao prosseguir você aceita nossos',
            termsAndPolicy: 'termos de uso'
        },
        register: {
            title: 'Crie sua conta grátis',
            subtitle: 'Preencha os dados abaixo',
            submit: 'Crie sua conta',
            fullname: 'Nome completo*',
            email: 'Email*',
            phones: {
                number1: 'Telefone Comercial*',
                number2: 'Telefone Pessoal',
                mask: JSON.stringify(['(##) #####-####','(##) ####-####'])
            },
            password: 'Senha*',
            confirmation: 'Confirmação de senha*'
        },
        forgetPassword: {
            title: 'Esqueceu sua senha?',
            subtitle: 'Informe seu email e enviaremos as instruções para redefinir sua senha',
            email: 'Email',
            submit: 'Redefinir senha'
        },
        updatePassword: {
            title: 'Continuar redefinição de senha',
            subtitle: 'Informe uma nova senha e confirme',
            newpassword: 'Nova senha',
            confirmation: 'Confirmação nova senha',
            submit: 'Redefinir senha'
        },
        unsubscribe: {
            subscribedTitle: 'Confirme abaixo para não receber mais nossos e-mails. Esta ação não poderá ser desfeita.',
            unsubscribedTitle: 'Você não receberá mais nossos e-mails, obrigado!',
            unsubscribeBtn: 'Não Quero Mais Receber E-mails'
        },
        success: {
            title: 'Bom trabalho! Deu tudo certo.',
            registerInstructions: 'Para concluir o cadastro da sua conta clique no link de confirmação do email que acabamos de te enviar.',
            forgetPasswordInstructions: 'Para continuar a redefinição da sua senha clique no link de confirmação do email que acabamos de te enviar.'
        },
        error: {
            title: 'Ops, algo deu errado...',
            participantWithoutCompany: 'Tivemos um problema ao definir a empresa que o seu usuário está vinculado. Por favor, entre em contato através do telefone (19) 3305-0700 / (19) 3305-0701 para solucionar este problema.',
            companyInactive: 'Sua empresa foi inativada. Por favor, entre em contato através do telefone (19) 3305-0700 / (19) 3305-0701 para solucionar este problema.'
        },
        menu: {
            projects: {
                title: 'Projetos',
                subtitle: 'Lista geral dos projetos disponíveis'
            },
            addProject: {
                title: 'Projeto',
                subtitle: 'Novo'
            },
            projectGeneralInfo: {
                title: 'Projeto',
                subtitle: 'Dados Gerais do Projeto'
            },
            projectTermsAndConditions: {
                title: 'Projeto',
                subtitle: 'Editais e Termos do processo'
            },
            projectDocuments: {
                title: 'Projeto',
                subtitle: 'Documentação Requerida dos Participantes'
            },
            projectParticipants: {
                title: 'Projeto',
                subtitle: 'Lista dos participantes que enviaram proposta'
            },
            projectDropouts: {
                title: 'Projeto',
                subtitle: 'Lista de desistentes'
            },
            participantProjects: {
                title: 'Todos os Projetos',
                subtitle: 'Lista de todos os projetos publicados'
            },
            participantMyProjects: {
                title: 'Meus Projetos',
                subtitle: 'Lista dos projetos que estou participando'
            },
            participantDetailProject: {
                title: 'Projeto',
                subtitle: 'Detalhe'
            },
            participantDocuments: {
                title: 'Documentos',
                subtitle: 'Lista de documentos e certidões'
            },
            customers: {
                title: 'Clientes',
                subtitle: 'Lista geral dos clientes'
            },
            addCustomer: {
                title: 'Clientes',
                subtitle: 'Novo'
            },
            editCustomer: {
                title: 'Cliente',
                subtitle: 'Dados do cliente'
            },
            companies: {
                title: 'Compradores',
                subtitle: 'Lista geral dos compradores'
            },
            addCompany: {
                title: 'Compradores',
                subtitle: 'Novo'
            },
            addMyCompany: {
                title: 'Minha empresa',
                subtitle: 'Dados cadastrais da minha empresa'
            },
            editCompany: {
                title: 'Compradores',
                subtitle: 'Dados do comprador'
            },
            editMyCompany: {
                title: 'Minha empresa',
                subtitle: 'Dados cadastrais da minha empresa'
            },
            logemails: {
                title: 'Emails enviados',
                subtitle: 'Lista dos emails enviados pelo sistema'
            },
            users: {
                title: 'Usuários',
                subtitle: 'Lista geral dos usuários do sistema'
            },
            addUser: {
                title: 'Usuários',
                subtitle: 'Novo'
            },
            editUser: {
                title: 'Usuários',
                subtitle: 'Dados do usuário'
            },
            viewUser: {
                title: 'Usuários',
                subtitle: 'Dados do usuário'
            },
            representative: {
                title: 'Representantes',
                subtitle: 'Lista de representantes da minha empresa'
            }
        },
        confirmation: {
            yes: 'Sim',
            no: 'Não',
        },
        project: {
            status: {
                DRAFT: 'Rascunho',
                PUBLISHED: 'Publicado',
                FINISHED: 'Finalizado'
            },
            categories: {
                iron: 'Sucatas ferrosas',
                ironless: 'Sucatas não ferrosas',
                platic: 'Plástico',
                rubber: 'Borracha',
                wood: 'Madeira',
                paperboard: 'Papelão',
                eletronic: 'Eletrônicos',
                oil: 'Óleo e Lubrificantes'
            },
            termTypes: {
                edital: 'Anexo I - Edital',
                termoPagamento: 'Anexo II - Termo de Pagamento',
                proposta: 'Anexo III - Proposta',
                fotos: 'Anexo IV - Fotos',
                termoNegociacao: 'Anexo V - Termos e Condições Gerais de Negociação'
            },
            requiredDocuments: {
                licensaOperacao: 'Licença de operação',
                declaracaoRegimeTributario: 'Declaração de regime tributário',
                declaracaoLucroReal: 'Declaração de lucro real',
                inscricaoEstadual: 'Inscrição Estadual',
                certificadoVerificacao: 'Certificado de verificação',
                alvaraPrefeitura: 'Alvará prefeitura',
                autoVistoriaCorpoBombeiro: 'Auto de vistoria do corpo de bombeiros',
                certificadoMovimentacaoResiduoInteresseAmbiental: 'Certificado de movimentação de resíduos de interesse ambiental',
                parecerDesfavoravelCertificadoMovimentacaoResiduosInteresseAmbiental: 'Parecer desfavorável do certificado de movimentação de resíduos de interesse ambiental',
                certificadoRegularidadeCR: 'Certificado de regularidade CR',
                iso9001: 'Certificado ISO 9001',
                iso14001: 'Certificado ISO 14001',
                certificadoDispensaLicensa: 'Certificado de dispensa de licença',
                anexo2: 'Anexo II'
            },
            admin: {
                search: {
                    loadingProjects: 'Carregando projetos...',
                    searchPlaceholder: 'Informe o nome ou cliente',
                    filterLabel: 'Nome/Cliente',
                    noProjectsFound: 'Nenhum projeto encontrado.',
                    noProjectsCreated: {
                        title: 'Ops, nenhum projeto encontrado...',
                        message: 'Adicione projetos através do botão Novo.'
                    },
                    tableColumns: {
                        createdAt: 'Criado em',
                        customer: 'Cliente',
                        name: 'Nome',
                        locations: 'Planta(s)',
                        status: 'Status',
                        actions: 'Ações'
                    },
                    actions: {
                        add: 'Novo',
                        view: 'Visualizar',
                        edit: 'Editar',
                        participants: 'Participantes'
                    },
                    rowsPerPage: 'Linhas por página'
                },
                edit: {
                    errors: {
                        projectNotFound: 'Projeto não encontrado.',
                        categoriesNotInformed: 'Informe a(s) categoria(s) do projeto.',
                        locationsNotInformed: 'Informe a(s) planta(s) do projeto.',
                        termTypesNotInformed: 'Informe o tipo do edital(is).',
                        publishWithoutTermsAndConditions: 'Faça o upload do Edital para que seja possível efetuar a publicação.'
                    },
                    summary: {
                        createdAt: 'Criado em',
                        status: 'Status',
                        publish: 'Publicar',
                        remove: 'Excluir',
                        finish: 'Finalizar',
                        confirmPublish: 'Tem certeza que deseja publicar o projeto?',
                        confirmRemove: 'Ao remover o projeto, todas as suas informações serão perdidas, incluindo seus editais. Essa operação não poderá ser revertida. Tem certeza que deseja continuar?',
                        confirmFinish: 'Os dados do projeto não poderão ser alterados após a finalização. Tem certeza que deseja prosseguir?'
                    },
                    tabs: {
                        generalData: {
                            name: 'Dados do Projeto',
                            fields: {
                                customer: {
                                    name: 'Cliente',
                                    label: 'Cliente*',
                                    placeholder: 'Ex. Faciles',
                                    noDataText: 'Digite 3 caracteres para buscar...'
                                },
                                name: {
                                    name: 'Nome do Projeto',
                                    label: 'Nome do Projeto*',
                                    placeholder: 'Ex. Venda de sucatas ferrosas'
                                },
                                categories: 'Categorias',
                                locations: 'Planta(s)',
                                description: 'Descrição',
                                calendar: {
                                    label: 'Prazos',
                                    kickoffLabel: '1. Abertura',
                                    kickoffDateName: 'data abertura',
                                    kickoffHourName: 'hora abertura',
                                    visitationLabel: '2. Visitação',
                                    visitationDateName: 'data visitação',
                                    visitationHourName: 'hora visitação',
                                    basicDocumentsLabel: '3. Habilitação',
                                    basicDocumentsDateName: 'data habilitação',
                                    basicDocumentsHourName: 'hora habilitação',
                                    additionalDocumentsLabel: '4. Cotação',
                                    additionalDocumentsDateName: 'data cotação',
                                    additionalDocumentsHourName: 'hora cotação',
                                    negociationLabel: '5. Negociação',
                                    negociationDateName: 'data negociação',
                                    negociationHourName: 'hora negociação'
                                }
                            }
                        },
                        termsAndConditions: {
                            name: 'Editais',
                            tableColumns: {
                                typeRequired: 'Informe o tipo do anexo',
                                type: 'Tipo',
                                name: 'Nome',
                                actions: 'Ações'
                            },
                            importFiles: 'Importar Arquivos',
                            dragAndDropFilesHere: 'Arraste e solte os arquivos aqui para serem carregados.',
                            noTermsFound: {
                                title: 'Ops, nenhum edital cadastrado...',
                                message: 'Adicione os editais do projeto através da área de seleção no modo de edição.'
                            },
                            confirmRemoveTerm: 'Tem certeza que deseja remover o edital?'
                        },
                        requiredDocuments: {
                            name: 'Documentação'
                        },
                        participants: {
                            name: 'Participantes',
                            status: [{
                                text: 'Indefinido',
                                value: 'NOT_DEFINED'
                            }, {
                                text: 'Sim',
                                value: 'APPROVED'
                            }, {
                                text: 'Não',
                                value: 'REPROVED'
                            }],
                            tableColumns: {
                                name: 'Nome',
                                termsAndConditions: '1. Abertura',
                                visitation: '2. Visitação',
                                documents: '3. Habilitação',
                                quotation: '4. Cotação',
                                negociationTerm: '5. Negociação',
                                approved: 'Finalista',
                                actions: {
                                    name: 'Ações',
                                    download: 'Baixar documentos',
                                    approve: 'Aprovar',
                                    reprove: 'Reprovar'
                                }
                            },
                            noParticipantsFound: 'Ops, nenhum participante encontrado...',
                            participantDoesNotHaveDocumentsError: 'O participante não possui documentos.',
                            documents: {
                                title: 'Documentação',
                                status: {
                                    ANALYSING: 'Em análise',
                                    APPROVED: 'Aprovado',
                                    REPROVED: 'Reprovado',
                                    EXEMPT: 'Dispensado'
                                },
                                tableColumns: {
                                    createdAt: 'Data envio',
                                    expiresAt: 'Validade',
                                    status: 'Status',
                                    notes: 'Observação'
                                },
                                expired: 'Expirou',
                                expiresIn: 'em $_X_DAYS_$ dias',
                                analyze: 'analisar',
                                analysis: {
                                    title: 'Análise de documento',
                                    notes: 'Observação',
                                    operationCannotBeUndone: 'Atenção, essa operação não poderá ser desfeita.'
                                }
                            },
                            reproveModal: {
                                reprovalNotes: 'Justificativa para reprovação'
                            },
                            actions: {
                                showVisitorsList: 'Lista de visitação'
                            },
                            visitors: {
                                title: 'Lista de visitantes',
                                list: {
                                    subtitle: 'Listagem dos visitantes confirmados',
                                    tableColumns: {
                                        name: 'Nome',
                                        document: 'RG',
                                        company: 'Empresa',
                                        phone: {
                                            label: 'Telefone',
                                            mask: JSON.stringify(['(##) #####-####','(##) ####-####'])
                                        }
                                    }
                                },
                                email: {
                                    subtitle: 'Enviar e-mail com relação de participantes',
                                    emailList: {
                                        label: 'Quem receberá a lista de visitantes?',
                                        name: 'E-mail',
                                        add: 'Adicionar',
                                        remove: 'Remover'
                                    },
                                    errors: {
                                        emptyRecipentsList: 'Por favor, informe endereços de e-mail válidos.'
                                    }
                                },
                                actions: {
                                    sendEmail: 'Enviar por e-mail',
                                    back: 'Voltar',
                                    cancel: 'Cancelar',
                                    send: 'Enviar'
                                }
                            }
                        },
                        dropouts: {
                            name: 'Desistentes',
                            tableColumns: {
                                company: 'Empresa',
                                reason: 'Motivo',
                                dateDropout: 'Data da Desistência'
                            },
                            noDropoutsFound: 'Ops, nenhum desistente encontrado...',
                        }
                    },
                    back: 'Voltar para listagem',
                    cancel: 'Cancelar',
                    close: 'Fechar',
                    save: 'Salvar'
                }
            },
            participant: {
                documents: {
                    title: 'Habilitação - Documentos Obrigatórios',
                    finishAt: 'Termina em',
                    importFiles: 'Importar Arquivos',
                    dragAndDropFilesHere: 'Arraste e solte os arquivos aqui para serem carregados.',
                    confirmRemoveDocument: 'Tem certeza que deseja remover o documento?',
                    expired: 'Expirou',
                    expiresIn: '$_X_DAYS_$ dias',
                    save: 'Salvar',
                    cancel: 'Cancelar',
                    indefinitePeriod: 'Prazo Indeterminado',
                    sendFiles: 'Enviar Arquivos',
                    dismiss: 'Dispensar',
                    sendBulkFiles: 'Enviar Arquivos em Massa',
                    noDocumentsFound: {
                        title: 'Ops, nenhum documento adicionado...',
                        message: 'Adicione os seus documentos através da área de seleção.'
                    },
                    tableColumns: {
                        typeRequired: 'Informe o tipo do documento',
                        expiresAtRequired: 'Informe a validade do documento',
                        expiresAt: 'Validade',
                        exemption: 'Dispensado',
                        type: 'Tipo',
                        status: 'Status',
                        actions: 'Ações',
                        file: 'Arquivo'
                    },
                    status: {
                        ANALYSING: 'Em análise',
                        APPROVED: 'Aprovado',
                        REPROVED: 'Reprovado',
                        EXEMPT: 'Dispensado',
                        NOT_UPLOADED: 'Não Enviado'
                    },
                    errors: {
                        findDocs: 'Ocorreu um erro ao buscar os documentos.',
                        docToSaveIsInvalid: 'Há campos do documento não preenchidos.',
                        docToSaveIsExpired: 'Data de validade documento está expirada.',
                        docToSaveIsRepeatedType: 'Há documentos com tipos repetidos.'
                    }
                },
                search: {
                    title: 'Projetos de sourcing em andamento',
                    loadingProjects: 'Carregando projetos...',
                    noProjectsFound: 'Nenhum projeto publicado.',
                    noProjectsCreated: {
                        title: 'Ops, nenhum projeto publicado...',
                        message: 'Não há projetos em publicados no momento'
                    },
                    noMyProjects: {
                        title: 'Ops, você não está participando de nenhum projeto...',
                        message: 'Não há projetos que você participa no momento'
                    },
                    seeMore: 'Ver Mais',
                    participating: 'Estou participando',
                    confirmDropoutProject: 'Você apagará todas as informações preenchidas neste projeto. Deseja realmente desistir?',
                    confirmDropoutProjectTitle: 'Deisistir do Projeto',
                    confirmDropoutTextAreaLabel: 'Motivo',
                    cancelDropout: 'Cancelar',
                    confirmDropout: 'Desistir',
                    phaseLabel: 'Etapa',
                    giveUpProjectLabel: 'Desistir',
                    viewProjectLabel: 'Visualizar',
                    finishedPhaseAt: 'Terminou em',
                    phases: {
                        termsAndConditions: 'Edital',
                        visitation: 'Visitação',
                        documentation: 'Habilitação',
                        quotationSlug: 'Cotação',
                        negotiation: 'Negociação',
                        allDone: 'Todas fases completas'
                    }
                },
                detail: {
                    errors: {
                        projectNotFound: 'Projeto não encontrado.',
                        projectUnavailable: 'O projeto não está mais dísponivel.',
                        droppedOutProject: 'Você desistiu desse projeto!',
                        selectAgreedInput: 'Selecione a opção de concordar com os termos.'
                    },
                    timeline: {
                        information: 'Informação',
                        terms: 'Edital',
                        paymentTerms: 'Pagamento',
                        visitation: 'Visitação',
                        documentation: 'Habilitação',
                        quotation: 'Cotação',
                        negotiation: 'Negociação',
                    },
                    visitation: {
                        title: 'Agendamento da Visitação',
                        finishAt: 'Termina em',
                        description: 'Nesta etapa você pode nos informar se pretende realizar ou não uma visitação à empresa responsável pelo processo, escolha sim ou não dentre as opções abaixo, no caso de sim em seguida adicione o nome dos participantes.',
                        addVisitant: 'Adicionar Visitante',
                        save: 'Salvar',
                        fields: {
                            name: {
                                name: 'Nome',
                                placeholder: 'Nome*'
                            },
                            document: {
                                name: 'RG',
                                placeholder: 'RG'
                            },
                            phone: {
                                name: 'Telefone',
                                placeholder: 'Telefone*',
                                mask: JSON.stringify(['(##) #####-####','(##) ####-####'])
                            },
                            agreedVisitation: {
                                name: 'Pretende realizar uma visitação?',
                                label: 'Pretende realizar uma visitação?'
                            }
                        }
                    },
                    quotation: {
                        title: 'Planilha de Cotação',
                        finishAt: 'Termina em',
                        description: 'Nesta etapa você pode baixar o modelo da planilha disponibilizada abaixo e realizar a importação com as informações preenchidas da sua cotação.',
                        importFiles: 'Importar Planilha',
                        dragAndDropFilesHere: 'Arraste e solte sua planilha preenchida aqui.',
                        downloadExampleHere: 'Baixar Planilha de Cotação',
                        fileUploaded: 'Planilha de cotação já foi importada',
                        downloadQuotationFile: 'Baixar planilha de cotação importada',
                        resend: 'Reenviar',
                        removeFile: 'Remover Arquivo',
                        thenLabel: 'em seguida'
                    },
                    negotiation: {
                        title: 'Negociação Eletrônica',
                        finishAt: 'Termina em',
                        notDefineMessage: 'A etapa de negociação ainda não esta disponível',
                        reprovedMessage1: 'Sua empresa não foi classificada para a etapa de negociação por motivo:',
                        reprovedMessage2: 'Continue acompanhando os processos, sua participação é muito importante para nós.',
                        approvedMessage: 'Atenção! Nesta etapa você precisa baixar o termo de negociação.',
                        importFiles: 'Importar Edital',
                        dragAndDropFilesHere: 'Arraste e solte o termo assinado, carimbado e digitalizado.',
                        downloadExampleHere: 'Baixar Termo',
                        fileUploaded: 'Documento Já Importado',
                        downloadNegotiationFile: 'Baixe aqui o documento importado',
                        negotiationCompletedMessage: 'Termo de negociação anexado',
                        enterNegotiationSystemBtn: 'Entrar no Sistema de Negociação',
                        removeFile: 'Remover Documento'
                    },
                    attachmentTabs: {
                        attachment1: 'EDITAL',
                        attachment2: 'ANEXO II',
                        attachment3: 'ANEXO III',
                        attachment4: 'ANEXO IV',
                    },
                    documents: {
                        download: 'Download',
                        remove: 'Remover',
                        send: 'Enviar',
                        reSend: 'Reenviar',
                        tableColumns: {
                            documents: 'Documentos',
                            status: 'Situação',
                            expireDate: 'Data Vencimento',
                            actions: 'Ações'
                        }
                    },
                    back: 'Voltar para página inicial',
                    locations: 'Planta(s)',
                    categories: 'Categorias',
                    noticeAndTerms: 'Edital e Termos',
                    agreeToTerms: 'Concordo com os termos do edital vigente',
                    agreeToProposalTerms: 'Concordo com os termos do anexo III - Proposta vigente',
                    agreeToPhotosTerms: 'Concordo com os termos do anexo IV - Fotos vigente',
                    agreeToPaymentTerms: 'Concordo com os termos do anexo II - Termos de Pagamento vigente',
                    termsAgreed: 'Os termos do edital ja foram aceitos.',
                    publishedAt: 'Publicado em',
                    paymentTermsAgreed: 'Os termos de pagamento já foram aceitos',
                    partipateThisProject: 'Quero Participar Deste Projeto',
                    nextStep: 'PRÓXIMA ETAPA',
                    downloadAttachment: 'Download do Anexo',
                    giveUpProject: 'Desistir do Projeto',
                    closeInfoProject: 'Fechar'
                }
            }
        },
        customer: {
            search: {
                loadingProjects: 'Carregando clientes...',
                searchPlaceholder: 'Informe o nome ou CNPJ',
                filterLabel: 'Nome/CNPJ',
                noProjectsFound: 'Nenhum cliente encontrado.',
                noProjectsCreated: {
                    title: 'Ops, nenhum cliente encontrado...',
                    message: 'Adicione clientes através do botão Novo.'
                },
                tableColumns: {
                    name: 'Nome',
                    document: 'CNPJ',
                    updatedAt: 'Última Modificação',
                    actions: 'Ações'
                },
                actions: {
                    add: 'Novo',
                    view: 'Visualizar',
                    edit: 'Editar'
                },
                rowsPerPage: 'Linhas por página'
            },
            edit: {
                form: {
                    fields: {
                        logo: {
                            placeholder: 'Clique aqui ou arraste e solte a logo para realizar o upload'
                        },
                        name: {
                            name: 'Nome Social',
                            label: 'Nome Social*',
                            placeholder: 'Ex. Industria de Sucatas LTDA'
                        },
                        document: {
                            name: 'CNPJ',
                            label: 'CNPJ',
                            placeholder: 'Ex. 83.589.536/0001-11',
                            mask: '##.###.###/####-##',
                        },
                        emails: {
                            name: 'E-mails',
                            label: 'E-mails',
                            placeholder: 'Ex. empresa@empresa.com',
                            addLabel: 'Adicionar E-mail',
                            removeLabel: 'Remover',
                            emptyEmailList: 'Nenhum e-mail cadastrado.'
                        },
                        phones: {
                            name: 'Telefones',
                            label: 'Telefones',
                            placeholder: 'Ex. (11) 2032-0101',
                            addLabel: 'Adicionar Telefone',
                            removeLabel: 'Remover',
                            mask: JSON.stringify(['(##) #####-####','(##) ####-####']),
                            emptyPhoneList: 'Nenhum telefone cadastrado.'
                        },
                        city: {
                            name: 'Cidade',
                            label: 'Cidade',
                            placeholder: 'Ex. Campinas - SP',
                            cityNotInformed: 'Cidade não informada.'
                        }
                    }
                },
                back: 'Voltar para listagem',
                cancel: 'Cancelar',
                close: 'Fechar',
                save: 'Salvar'
            }
        },
        company: {
            status: [{
                text: 'Ativo',
                value: true
            }, {
                text: 'Inativo',
                value: false
            }],
            search: {
                loadingProjects: 'Carregando compradores...',
                searchPlaceholder: 'Informe o nome ou CNPJ',
                filterLabel: 'Nome/CNPJ',
                noProjectsFound: 'Nenhum comprador encontrado.',
                noProjectsCreated: {
                    title: 'Ops, nenhum comprador encontrado...',
                    message: 'Adicione compradores através do botão Novo.'
                },
                tableColumns: {
                    name: 'Nome',
                    categories: 'Categorias',
                    updatedAt: 'Última Modificação',
                    status: 'Situação',
                    actions: 'Ações'
                },
                actions: {
                    add: 'Novo',
                    view: 'Visualizar',
                    edit: 'Editar',
                    delete: 'Excluir'
                },
                rowsPerPage: 'Linhas por página',
                dialogs: {
                    delete: {
                        title: 'Você tem certeza que deseja fazer isso?',
                        text: 'Se você realizar esta ação os dados da companhia serão excluídos permanentemente.',
                        options: {
                            reject: 'Cancelar',
                            accept: 'Excluir'
                        }
                    }
                }
            },
            edit: {
                form: {
                    alert: 'Prezado participante, para continuar utilizando as funcionalidades da aplicação preencha o cadastro de sua organização.',
                    fields: {
                        logo: {
                            placeholder: 'Clique aqui ou arraste e solte a logo para realizar o upload'
                        },
                        name: {
                            name: 'Nome Social',
                            label: 'Nome Social*',
                            placeholder: 'Ex. Industria de Sucatas LTDA'
                        },
                        address: {
                            line1: {
                                name: 'Endereço',
                                label: 'Endereço*',
                                placeholder: 'Logradouro e número'
                            },
                            line2: {
                                name: 'Complemento',
                                label: 'Complemento',
                                placeholder: 'Apartamento, bloco, empresa'
                            },
                            city: {
                                name: 'Cidade',
                                label: 'Cidade*',
                                placeholder: 'Ex. Campinas - SP',
                                cityNotInformed: 'Cidade não informada.'
                            },
                            state: {
                                name: 'Estado',
                                label: 'Estado*',
                                placeholder: 'UF'
                            },
                            zipCode: {
                                name: 'CEP',
                                label: 'CEP*',
                                placeholder: '00000-000',
                                mask: '#####-###'
                            },
                            country: {
                                name: 'País',
                                label: 'País*',
                                placeholder: 'País'
                            }
                        },
                        document: {
                            name: 'CNPJ',
                            label: 'CNPJ*',
                            placeholder: 'Ex. 83.589.536/0001-11',
                            mask: '##.###.###/####-##',
                        },
                        representatives: {
                            nameOrEmail: {
                                name: 'Nome ou e-mail',
                                label: 'Nome ou e-mail',
                                placeholder: 'Ex. Representante',
                                noDataText: 'Não existe usuário com este nome ou e-mail. Por favor tente novamente.'
                            },
                            email: {
                                name: 'E-mail',
                                label: 'E-mail',
                                placeholder: 'Ex. representante@empresa.com.br',
                                errors: {
                                    invalid: 'Por favor, insira um endereço de e-mail válido',
                                    doesNotExist: 'Não há usuário com este E-mail. Por favor, tente novamente',
                                    userAlreadyAddedInList: 'O usuário informado já foi adicionado na lista'
                                }
                            },
                            table: {
                                headers: {
                                    name: 'Nome',
                                    email: 'E-mail',
                                    phoneNumber1: 'Telefone 1',
                                    phoneNumber2: 'Telefone 2',
                                    action: 'Ação'
                                },
                                masks: {
                                    phone: JSON.stringify(['(##) #####-####','(##) ####-####'])
                                }
                            },
                            title: 'Usuários',
                            subtitle: 'Por favor, adicione um ou mais usuários para serem os representantes de sua organização',
                            tableDescription: 'Usuários Adicionados:',
                            addLabel: 'Adicionar Usuário',
                            removeLabel: 'Remover',
                            emptyList: 'Nenhum usuário cadastrado.'
                        },
                        categories: {
                            label: 'Categorias de interesse*',
                            placeholder: 'Selecione uma ou mais categorias de interesse'
                        }
                    }
                },
                back: 'Voltar para listagem',
                cancel: 'Cancelar',
                close: 'Fechar',
                save: 'Salvar'
            }
        },
        logEmails: {
            search: {
                actions: {
                    remove: 'Excluir e-mails'
                },
                searchPlaceholder: 'Informe assunto ou conteúdo',
                loadingLogEmails: 'Carregando e-mails...',
                noLogEmailsFound: {
                    title: 'Ops, nenhum emails enviado foi encontrado...',
                    message: 'Utilize o filtro de busca para encontrar emails enviados.'
                },
                filterLabel: 'Assunto/Conteúdo',
                rowsPerPage: 'Linhas por página',
                tableColumns: {
                    to: 'Para',
                    subject: 'Assunto',
                    createdAt: 'Data criação',
                    actions: {
                        label: 'Ações',
                        view: 'Visualizar'
                    }
                },
                dialogs: {
                    remove: {
                        title: 'Você tem certeza que deseja fazer isso?',
                        text: 'Se você realizar esta ação todos os e-mails selecionados serão excluidos permanentemente.',
                        options:{
                            reject: 'Cancelar',
                            accept: 'Excluir'
                        }
                    }
                }
            }
        },
        users: {
            search: {
                searchPlaceholder: 'Informe nome ou e-mail',
                loadingUsers: 'Carregando usuários...',
                noUsersFound: {
                    title: 'Ops, nenhum usuário encontrado...',
                    message: 'Utilize o filtro de busca para encontrar usuários.'
                },
                filters: {
                    nameEmail: {
                        label: 'Nome/E-mail'
                    },
                    source: {
                        label: 'Tipo de Usuário'
                    }
                },
                rowsPerPage: 'Linhas por página',
                tableColumns: {
                    name: 'Nome',
                    email: 'E-mail',
                    admin: 'Admin.',
                    company: 'Empresa',
                    emailSubscriber: 'Recebe e-mail',
                    createdAt: 'Data criação',
                    actions: 'Ações'
                },
                actions: {
                    add: 'Novo',
                    invite: 'Convidar',
                    view: 'Visualizar',
                    edit: 'Editar',
                    resetPassword: 'Redefinir senha',
                    delete: 'Excluir'
                },
                dialogs: {
                    delete: {
                        title: 'Você tem certeza que deseja fazer isso?',
                        text: 'Se você realizar esta ação os dados do usuário serão excluídos permanentemente.',
                        options: {
                            reject: 'Cancelar',
                            accept: 'Excluir'
                        }
                    },
                    resetPassword: {
                        title: 'Você tem certeza que deseja fazer isso?',
                        text: 'Se você realizar esta ação uma senha randômica será gerada e enviada ao usuário por meio de seu e-mail.',
                        options: {
                            reject: 'Cancelar',
                            accept: 'Redefinir'
                        }
                    }
                },
                modals: {
                    add: {
                        title: 'Usuário cadastrado com sucesso!',
                        text1: 'Enviamos um e-mail ao usuário contendo as suas credenciais de acesso. A senha gerada pelo sistema é:',
                        text2: 'Por favor, salientamos a importância de salvar esta senha em um ambiente seguro, pois não será possível consultá-la novamente.',
                        options: {
                            accept: 'Fechar'
                        }
                    },
                    resetPassword: {
                        title: 'Senha redefinida com sucesso!',
                        text1: 'Enviamos um e-mail ao usuário contendo sua nova senha. A senha gerada pelo sistema é:',
                        text2: 'Por favor, salientamos a importância de salvar esta senha em um ambiente seguro, pois não será possível consultá-la novamente.',
                        options: {
                            accept: 'Fechar'
                        }
                    }
                }
            },
            edit: {
                form: {
                    fields: {
                        name: {
                            name: 'Nome',
                            label: 'Nome*',
                            placeholder: 'Nome do usuário'
                        },
                        email: {
                            name: 'Email',
                            label: 'Email*',
                            placeholder: 'Informe o email no formato nome@empresa.com.br'
                        },
                        phone1: {
                            name: 'Telefone Comercial',
                            label: 'Telefone Comercial*',
                            mask: JSON.stringify(['(##) #####-####','(##) ####-####']),
                            placeholder: 'Informe o telefone comercial'
                        },
                        phone2: {
                            name: 'Telefone Pessoal',
                            label: 'Telefone Pessoal',
                            mask: JSON.stringify(['(##) #####-####','(##) ####-####']),
                            placeholder: 'Informe o telefone pessoal'
                        },
                        isAdmin: {
                            label: 'Perfil de Administrador'
                        },
                        source: {
                            label: 'Tipo de usuário',
                            placeholder: {
                                internal: 'Usuário ativo no sistema',
                                confirmed: 'Usuário ativo no sistema',
                                invite: 'Usuário convidado para o sistema',
                                migration: 'Usuário ainda não convidado para o sistema'
                            }
                        },
                        createdAt: {
                            name: 'Data de Cadastro',
                            label: 'Data de Cadastro'
                        }
                    }
                },
                back: 'Voltar',
                cancel: 'Cancelar',
                save: 'Salvar'
            }
        },
        representatives: {
            search: {
                tableColumns: {
                    name: 'Nome',
                    email: 'E-mail',
                    status: 'Status',
                    actions: 'Ações'
                },
                status: {
                    invite: 'Convidado',
                    confirmed: 'Ativo',
                    internal: 'Ativo'
                },
                actions: {
                    add: 'Novo'
                },
                modals: {
                    add: {
                        title: 'Convidar novo representante',
                        subtitle: 'Por favor, preencha o formulário abaixo para convidar um novo representante. Ao confirmar, um e-mail será enviado contendo o convite para juntar-se à sua organização.',
                        form: {
                            fields: {
                                name: {
                                    name: 'Nome',
                                    label: 'Nome',
                                    placeholder: 'Nome do representante'
                                },
                                email: {
                                    name: 'E-mail',
                                    label: 'E-mail',
                                    placeholder: 'E-mail do representante'
                                }
                            },
                            actions: {
                                cancel: 'Cancelar',
                                save: 'Convidar'
                            }
                        }
                    }
                },
                noRepresentativesFound: {
                    title: 'Ops, nenhum representante encontrado...',
                    message: 'Adicione os seus representantes através da opção "Novo".'
                },
                errors: {
                    findRepresentatives: 'Ocorreu um erro ao buscar os representantes de sua empresa.'
                }
            }
        },
    },
    en: {
        $vuetify: en,
        general: {
            yes: 'Yes',
            no: 'No',
            caution: 'Caution',
            and: 'and'
        },
        exceptions: {
            ERRO_401: 'Your session has expired. You will be redirected to the login ...',
            ERRO_DEFAULT: 'An error occurred while performing the operation.',
            ERRO_SEM_CONEXAO_INTERNET: 'Check your internet connection.',
            ERRO_PERDA_SESSAO: 'Sorry, you have lost the session and your page will be reloaded.',
            UNSPECIFIED_TOKEN: 'The token was not specified. You will be redirected to the login page ...',
            SUCESSO_DEFAULT: 'Operation performed successfully.',
            CANOT_CHANGE_USER_FROM_SOCIAL_NETWORK: 'Oops, your user was created from a social network, you can not change the password here.',
            CANOT_CREATE_THUMBNAL: 'Oops, unfortunately we could not create a minted image. Please enter another image. ',
            DUPLICATE_REGISTER: 'Oops, this email has already been entered before, please check the forgot password option',
            EMAIL_NOT_EXISTS: 'Oops, we\'ve noticed that this email does not exist in our account.',
            FILE_CORRUPTED: 'Oops, we were unable to recover this file',
            FILE_NOT_FOUND: 'Oops, we could not find this file in our registry',
            WRONG_PASSWORD: 'Oops, your password or email is invalid, please try again.',
            WAITING_PROCESSING: 'Oops, we have not yet been able to issue your certificate, please wait for processing to end.',
            USER_UPDATED: 'Your personal data has been successfully updated.',
            COMPANY_WITH_DUPLICATED_NAME: 'There is already a company with the same name.',
            COMPANY_WITH_DUPLICATED_DOCUMENT: 'There is already a company with the same document number.',
            REPRESENTATIVE_ALREADY_INVITED: 'The representative is already part of the company.',
            CUSTOMER_WITH_DUPLICATED_NAME: 'There is already a customer with the same name.',
            CUSTOMER_WITH_DUPLICATED_DOCUMENT: 'There is already a customer with the same document number.',
            FILE_BIGGER_THAN_ACCEPTED: 'The file must be smaller than 20Mb.'
        },
        login: {
            forgetPassword: 'Forget password?',
            login: 'Login',
            email: 'Email',
            password: 'Password',
            register: 'Register',
            inviteToRegister: 'Have not created your account yet?',
            inviteToAgreeTerms: 'By proceeding you accept our',
            termsAndPolicy: 'terms of use'
        },
        register: {
            title: 'Create your free account',
            subtitle: 'Fill in the information below',
            submit: 'Create your account',
            fullname: 'Fullname*',
            email: 'Email*',
            phones: {
                number1: 'Commercial Phone Number*',
                number2: 'Personal Phone Number',
                mask: JSON.stringify('(###) ###-####')
            },
            password: 'Password*',
            confirmation: 'Password confirmation*'
        },
        forgetPassword: {
            title: 'Forgot your password?',
            subtitle: 'Enter your email and we\'ll send you idocToSaveIsInvalidnstructions to reset your password',
            email: 'Email',
            submit: 'Redefine password'
        },
        updatePassword: {
            title: 'Password Reset Continued',
            subtitle: 'Enter a new password and confirm',
            newpassword: 'New password',
            confirmation: 'New password confirmation',
            submit: 'Redefine password'
        },
        unsubscribe: {
            subscribedTitle: 'Click the button to don\'t receive our emails anymore. This action can\'t be undone.',
            unsubscribedTitle: 'You won\'t receive our emails anymore, thank you!',
            unsubscribeBtn: 'I Don\'t Want To Receive Emails'
        },
        success: {
            title: 'Good job! Everything worked.',
            registerInstructions: 'To complete your account registration click on the confirmation link of the email that we just sent you.',
            forgetPasswordInstructions: 'To continue resetting your password, click the confirmation link of the email we just sent you.'
        },
        error: {
            title: 'Ops, something went wrong...',
            participantWithoutCompany: 'We had a problem when defining the company related to your user. Please, contact us through the phone +55 (19) 3305-0700 / +55 (19) 3305-0701 in order to solve this problem.',
            companyInactive: 'Your company was inactivated. Please, contact us through the phone +55 (19) 3305-0700 / +55 (19) 3305-0701 in order to solve this problem.',
        },
        menu: {
            projects: {
                title: 'Projects',
                subtitle: 'List of all available projects.'
            },
            addProject: {
                title: 'Project',
                subtitle: 'Add project'
            },
            projectGeneralInfo: {
                title: 'Project',
                subtitle: 'General Information'
            },
            projectTermsAndConditions: {
                title: 'Project',
                subtitle: 'Terms and Conditions'
            },
            projectDocuments: {
                title: 'Project',
                subtitle: 'Required Documentation'
            },
            projectParticipants: {
                title: 'Project',
                subtitle: 'List of participants'
            },
            projectDropouts: {
                title: 'Project',
                subtitle: 'List of dropouts'
            },
            participantProjects: {
                title: 'All Projects',
                subtitle: 'List of all published projects'
            },
            participantMyProjects: {
                title: 'My Projects',
                subtitle: 'List of projects I am participating'
            },
            participantDetailProject: {
                title: 'Project',
                subtitle: 'Detail'
            },
            participantDocuments: {
                title: 'Documents',
                subtitle: 'List of documents e certificates'
            },
            customers: {
                title: 'Customers',
                subtitle: 'List of all customers'
            },
            addCustomer: {
                title: 'Customer',
                subtitle: 'Add customer'
            },
            editCustomer: {
                title: 'Customer',
                subtitle: 'Customer details'
            },
            companies: {
                title: 'Companies',
                subtitle: 'List of all companies'
            },
            addCompany: {
                title: 'Company',
                subtitle: 'Add company'
            },
            addMyCompany: {
                title: 'My company',
                subtitle: 'Registration details of my company'
            },
            editCompany: {
                title: 'Company',
                subtitle: 'Company details'
            },
            editMyCompany: {
                title: 'My company',
                subtitle: 'Registration details of my company'
            },
            logemails: {
                title: 'Emails',
                subtitle: 'List of all emails'
            },
            users: {
                title: 'Users',
                subtitle: 'List of all users'
            },
            addUser: {
                title: 'Users',
                subtitle: 'New'
            },
            editUser: {
                title: 'Users',
                subtitle: 'User details'
            },
            viewUser: {
                title: 'Users',
                subtitle: 'User details'
            },
            representative: {
                title: 'Representatives',
                subtitle: 'Lista of representatives of my company'
            }
        },
        confirmation: {
            yes: 'Yes',
            no: 'No',
        },
        project: {
            status: {
                DRAFT: 'Draft',
                PUBLISHED: 'Published',
                FINISHED: 'Finished'
            },
            categories: {
                iron: 'Iron waste',
                ironless: 'Ironless waste',
                platic: 'Plastic',
                rubber: 'Rubber',
                wood: 'Wood',
                paperboard: 'Paperboard',
                eletronic: 'Eletronics',
                oil: 'Oil and Lubricant'
            },
            termTypes: {
                edital: 'Attachment I - Terms and Conditions',
                termoPagamento: 'Attachment II - Term of Payment',
                proposta: 'Attachment III - Bid',
                fotos: 'Attachment IV - Pictures',
                termoNegociacao: 'Anexo V - Terms and Conditions for Negotiation'
            },
            requiredDocuments: {
                licensaOperacao: 'Licença de operação',
                declaracaoRegimeTributario: 'Declaração de regime tributário',
                declaracaoLucroReal: 'Declaração de lucro real',
                inscricaoEstadual: 'Inscrição Estadual',
                certificadoVerificacao: 'Certificado de verificação',
                alvaraPrefeitura: 'Alvará prefeitura',
                autoVistoriaCorpoBombeiro: 'Auto de vistoria do corpo de bombeiros',
                certificadoMovimentacaoResiduoInteresseAmbiental: 'Certificado de movimentação de resíduos de interesse ambiental',
                parecerDesfavoravelCertificadoMovimentacaoResiduosInteresseAmbiental: 'Parecer desfavorável do certificado de movimentação de resíduos de interesse ambiental',
                certificadoRegularidadeCR: 'Certificado de regularidade CR',
                iso9001: 'Certificado ISO 9001',
                iso14001: 'Certificado ISO 14001',
                certificadoDispensaLicensa: 'Certificado de dispensa de licença',
                anexo2: 'Anexo II'
            },
            admin: {
                search: {
                    loadingProjects: 'Loading projects...',
                    searchPlaceholder: 'Search by name or customer',
                    filterLabel: 'Name/Customer',
                    noProjectsFound: 'No projects found.',
                    noProjectsCreated: {
                        title: 'Ops, we didn\'t found any projects...',
                        message: 'Add projects through the button New.'
                    },
                    tableColumns: {
                        createdAt: 'Created at',
                        customer: 'Customer',
                        name: 'Name',
                        locations: 'Location',
                        status: 'Status',
                        actions: 'Actions'
                    },
                    actions: {
                        add: 'New',
                        view: 'View',
                        edit: 'Edit',
                        participants: 'Participants'
                    },
                    status: {
                        DRAFT: 'Draft',
                        PUBLISHED: 'Published',
                        FINISHED: 'Finished'
                    },
                    rowsPerPage: 'Rows per page'
                },
                edit: {
                    errors: {
                        projectNotFound: 'Project not found.',
                        categoriesNotInformed: 'Inform the project categories.',
                        locationsNotInformed: 'Inform the project locations.',
                        termTypesNotInformed: 'Inform the term type.',
                        publishWithoutTermsAndConditions: 'The attachment Terms and Conditions is required to publish.'
                    },
                    summary: {
                        createdAt: 'Created at',
                        status: 'Status',
                        publish: 'Publish',
                        remove: 'Remove',
                        finish: 'Finish',
                        confirmPublish: 'This project is going to be publish. Do you want to proceed?',
                        confirmRemove: 'When removing a project, all of its data will be lost. This action cannot be undone. Do you want to proceed?',
                        confirmFinish: 'After finishing, the project will no longer accept changes. Do you want to proceed?'
                    },
                    tabs: {
                        generalData: {
                            name: 'General',
                            fields: {
                                customer: {
                                    name: 'Customer',
                                    label: 'Customer*',
                                    placeholder: 'Ex. Faciles',
                                    noDataText: 'Type 3 characters to filter...'
                                },
                                name: {
                                    name: 'Project Name',
                                    label: 'Project Name*',
                                    placeholder: 'Ex. Sell of iron waste'
                                },
                                categories: 'Categories',
                                locations: 'Locations',
                                description: 'Description',
                                calendar: {
                                    label: 'Due Dates',
                                    kickoffLabel: '1. Kick-off',
                                    kickoffDateName: 'kick-off date',
                                    kickoffHourName: 'kick-off time',
                                    visitationLabel: '2. Visitation',
                                    visitationDateName: 'visitation date',
                                    visitationHourName: 'visitation hour',
                                    basicDocumentsLabel: '3. Documentation',
                                    basicDocumentsDateName: 'documentation date',
                                    basicDocumentsHourName: 'documentation hour',
                                    additionalDocumentsLabel: '4. Quotation',
                                    additionalDocumentsDateName: 'quotation date',
                                    additionalDocumentsHourName: 'quotation hour',
                                    negociationLabel: '5. Negociation',
                                    negociationDateName: 'negociation date',
                                    negociationHourName: 'negociation hour'
                                }
                            }
                        },
                        termsAndConditions: {
                            name: 'Terms',
                            tableColumns: {
                                typeRequired: 'Select the attachment type',
                                type: 'Type',
                                name: 'Name',
                                actions: 'Actions'
                            },
                            importFiles: 'Upload Files',
                            dragAndDropFilesHere: 'Drag and drop files here to upload.',
                            noTermsFound: {
                                title: 'Ops, no terms found...',
                                message: 'Upload terms by the selection area on edit mode.'
                            },
                            confirmRemoveTerm: 'This term is going to be deleted. Do you want to proceed?'
                        },
                        requiredDocuments: {
                            name: 'Documents'
                        },
                        participants: {
                            name: 'Participants',
                            status: [{
                                text: 'Not defined',
                                value: 'NOT_DEFINED'
                            }, {
                                text: 'Yes',
                                value: 'APPROVED'
                            }, {
                                text: 'No',
                                value: 'REPROVED'
                            }],
                            tableColumns: {
                                name: 'Name',
                                termsAndConditions: '1. Kick-off',
                                visitation: '2. Visitation',
                                documents: '3. Documents',
                                quotation: '4. Quotation',
                                negociationTerm: '5.Negotiation',
                                approved: 'Finalist',
                                actions: {
                                    name: 'Actions',
                                    download: 'Download documents'
                                }
                            },
                            noParticipantsFound: 'Ops, no participants were found...',
                            participantDoesNotHaveDocumentsError: 'The participant does not have documents.',
                            documents: {
                                title: 'Documentation',
                                status: {
                                    ANALYSING: 'Analysing',
                                    APPROVED: 'Approved',
                                    REPROVED: 'Reproved',
                                    EXEMPT: 'Exempt'
                                },
                                tableColumns: {
                                    createdAt: 'Created at',
                                    expiresAt: 'Expiration',
                                    status: 'Status',
                                    notes: 'Notes'
                                },
                                expired: 'Expired',
                                expiresIn: 'in $_X_DAYS_$ days',
                                analyze: 'analyze',
                                analysis: {
                                    title: 'Document Analysis',
                                    notes: 'Notes',
                                    operationCannotBeUndone: 'Warning, this operation cannot be undone.'
                                }
                            },
                            reproveModal: {
                                reprovalNotes: 'Reproval notes'
                            },
                            actions: {
                                showVisitorsList: 'Visitors List'
                            },
                            visitors: {
                                title: 'Visitors List',
                                list: {
                                    subtitle: 'Confirmed Visitors',
                                    tableColumns: {
                                        name: 'Name',
                                        document: 'SSN',
                                        company: 'Company',
                                        phone: {
                                            label: 'Phone',
                                            mask: JSON.stringify('(###) ###-####')
                                        }
                                    }
                                },
                                email: {
                                    subtitle: 'Send visitors list through e-mail',
                                    emailList: {
                                        label: 'Who will receive this e-mail',
                                        name: 'E-mail',
                                        add: 'Add',
                                        remove: 'Remove'
                                    },
                                    errors: {
                                        emptyRecipentsList: 'Please, enter valid e-mail address.'
                                    }
                                },
                                actions: {
                                    sendEmail: 'Send e-mail',
                                    back: 'Back',
                                    cancel: 'Cancel',
                                    send: 'Send'
                                }
                            }
                        },
                        dropouts: {
                            name: 'Dropouts',
                            tableColumns: {
                                company: 'Company',
                                reason: 'Reason',
                                dateDropout: 'Date'
                            },
                            noDropoutsFound: 'Ops, no dropouts were found...',
                        }
                    },
                    back: 'Back',
                    cancel: 'Cancel',
                    close: 'Close',
                    save: 'Save'
                }
            },
            participant: {
                documents: {
                    title: 'Habilitation - Required Documents',
                    finishAt: 'Finish at',
                    importFiles: 'Upload Files',
                    dragAndDropFilesHere: 'Drag and drop files here to upload.',
                    confirmRemoveDocument: 'This document is going to be deleted. Do you want to proceed?',
                    expired: 'Expired',
                    expiresIn: '$_X_DAYS_$ days',
                    save: 'Save',
                    cancel: 'Cancel',
                    indefinitePeriod: 'Indefinite Period',
                    sendFiles: 'Send Files',
                    dismiss: 'Dismiss',
                    sendBulkFiles: 'Send Bulk Files',
                    noDocumentsFound: {
                        title: 'Ops, no documents found...',
                        message: 'Upload documents by the selection area.'
                    },
                    tableColumns: {
                        typeRequired: 'Select the document type',
                        expiresAtRequired: 'Inform the expiration date',
                        expiresAt: 'Validity',
                        exemption: 'Exemption',
                        type: 'Type',
                        status: 'Status',
                        actions: 'Actions',
                        file: 'File'
                    },
                    status: {
                        ANALYSING: 'Analysing',
                        APPROVED: 'Approved',
                        REPROVED: 'Reproved',
                        EXEMPT: 'Exempt',
                        NOT_UPLOADED: 'Not Uploaded'
                    },
                    errors: {
                        findDocs: 'An error occurred while find documents.',
                        docToSaveIsInvalid: 'There are required fields not informed.',
                        docToSaveIsExpired: 'Document is expired.',
                        docToSaveIsRepeatedType: 'There is documents with repeated type.'
                    }
                },
                search: {
                    title: 'Sourcing Projects in Progress',
                    loadingProjects: 'Loading projects...',
                    noProjectsFound: 'No projects published.',
                    noProjectsCreated: {
                        title: 'Ops, we difn\'t found any published projects...',
                        message: 'There is not any published projects.'
                    },
                    noMyProjects: {
                        title: 'Ops, we difn\'t found any projects you are participating...',
                        message: 'There is not any projects you are participating.'
                    },
                    seeMore: 'See More',
                    participating: 'I am participating',
                    confirmDropoutProject: 'You are going to erase all data of this project. Do you really want to give up?',
                    confirmDropoutProjectTitle: 'Drop out Project',
                    confirmDropoutTextAreaLabel: 'Reason',
                    cancelDropout: 'Cancel',
                    confirmDropout: 'Dropout',
                    phaseLabel: 'Phase',
                    giveUpProjectLabel: 'Drop out',
                    viewProjectLabel: 'View',
                    finishedPhaseAt: 'Phase finished at',
                    phases: {
                        termsAndConditions: 'Terms and Conditions',
                        visitation: 'Visitation',
                        documentation: 'Documentation',
                        quotationSlug: 'Quotation',
                        negotiation: 'Negotiation',
                        allDone: 'All done'
                    }
                },
                detail: {
                    errors: {
                        projectNotFound: 'Project not found.',
                        projectUnavailable: 'The project is no longer available.',
                        droppedOutProject: 'You dropped out this project!',
                        selectAgreedInput: 'Select agree with terms option.'
                    },
                    timeline: {
                        information: 'Information',
                        terms: 'Terms',
                        paymentTerms: 'Payment',
                        visitation: 'Visitation',
                        documentation: 'Documentation',
                        quotation: 'Quotation',
                        negotiation: 'Negotiation'
                    },
                    visitation: {
                        title: 'Visitation Schedule',
                        finishAt: 'Finish at',
                        description: 'In this step you can inform us if you want to make a visit to the company responsible for the process, choose yes or no from the options below, if yes then add the participants name.',
                        addVisitant: 'Add Visitant',
                        save: 'Save',
                        fields: {
                            name: {
                                name: 'Name',
                                placeholder: 'Name'
                            },
                            document: {
                                name: 'Document Number',
                                placeholder: 'Document Number'
                            },
                            phone: {
                                name: 'Phone',
                                placeholder: 'Phone',
                                mask: JSON.stringify('(###) ###-####')
                            },
                            agreedVisitation: {
                                name: 'Do you want to participate of visitation?',
                                label: 'Do you want to participate of visitation?'
                            }
                        }
                    },
                    quotation: {
                        title: 'Quotation Spreadsheet',
                        finishAt: 'Finish at',
                        description: '\n' +
                            '143/5000\n' +
                            'In this step you can download the template from the spreadsheet provided below and perform the import with the information filled in your quotation.',
                        importFiles: 'Import Spreadsheet',
                        dragAndDropFilesHere: 'Drag and drop your completed spreadsheet here.',
                        downloadExampleHere: 'Download the spreadsheet template here (Attachment III)',
                        fileUploaded: 'Quotation File Has Been Uploaded',
                        downloadQuotationFile: 'Download here the uploaded spreadshet',
                        resend: 'Resend',
                        removeFile: 'Remove File',
                        thenLabel: 'then'
                    },
                    negotiation: {
                        title: 'Eletronic Negotiation',
                        finishAt: 'Finish at',
                        notDefineMessage: 'Oops, the negotiation stage has not yet been set. \n Wait that we will contact you.',
                        reprovedMessage1: 'Sorry, but unfortunately you are not qualified for the next step by the following reason:',
                        reprovedMessage2: 'Stay tuned for new projects!',
                        approvedMessage: 'Congratulations, you passed to eletronic negotiation stage. \n Follow the next instructions:',
                        importFiles: 'Import Document',
                        dragAndDropFilesHere: 'Drag and drop the signed document here.',
                        downloadExampleHere: 'Download the updated terms here (Anexo V)',
                        fileUploaded: 'Document Has Been Uploaded',
                        downloadNegotiationFile: 'Download here the uploaded document',
                        negotiationCompletedMessage: 'Congratulations, you completed all required steps until now. \n Stay tuned the date and time that eletronic negotiation will start.',
                        enterNegotiationSystemBtn: 'Enter in Negotiation System',
                        removeFile: 'Remove Document'
                    },
                    attachmentTabs: {
                        attachment1: 'TERMS',
                        attachment2: 'ATTACHMENT II',
                        attachment3: 'ATTACHMENT III',
                        attachment4: 'ATTACHMENT IV',
                    },
                    documents: {
                        download: 'Download',
                        remove: 'Remove',
                        send: 'Send',
                        reSend: 'Resend',
                        tableColumns: {
                            documents: 'Documents',
                            status: 'Status',
                            expireDate: 'Expire Date',
                            actions: 'Actions'
                        }
                    },
                    back: 'Back',
                    locations: 'Location(s)',
                    categories: 'Categories',
                    noticeAndTerms: 'Notice and Terms',
                    agreeToTerms: 'I agree with the terms of the current notice',
                    agreeToProposalTerms: 'I agree with the current attachment III - Proposal',
                    agreeToPhotosTerms: 'I agree with the current attachment IV - Photos',
                    agreeToPaymentTerms: 'I agree with the current attachment II - Payment Terms',
                    termsAgreed: 'The Terms Has Been Accepted',
                    publishedAt: 'Published at',
                    paymentTermsAgreed: 'The Payment Terms Has Been Accepted',
                    partipateThisProject: 'I Want to Participate This Project',
                    nextStep: 'NEXT STEP',
                    downloadAttachment: 'Download Attachment',
                    giveUpProject: 'Drop Out Project',
                    closeInfoProject: 'Close'
                }
            }
        },
        customer: {
            search: {
                loadingProjects: 'Loading custoemers...',
                searchPlaceholder: 'Type the name or EIN',
                filterLabel: 'Name/EIN',
                noProjectsFound: 'No customers found.',
                noProjectsCreated: {
                    title: 'Ops, no customers were found...',
                    message: 'Add customers using the button New.'
                },
                tableColumns: {
                    name: 'Name',
                    document: 'EIN',
                    updatedAt: 'Last Modified',
                    actions: 'Actions'
                },
                actions: {
                    add: 'New',
                    view: 'View',
                    edit: 'Edit'
                },
                rowsPerPage: 'Lines per page'
            },
            edit: {
                form: {
                    fields: {
                        logo: {
                            placeholder: 'Click here or drag and drop the logo to upload the file.'
                        },
                        name: {
                            name: 'Social Name',
                            label: 'Social Name*',
                            placeholder: 'Ex. Industry LTDA'
                        },
                        document: {
                            name: 'EIN',
                            label: 'EIN',
                            placeholder: 'Ex. 12-0120194',
                            mask: '##-#######',
                        },
                        emails: {
                            name: 'E-mails',
                            label: 'E-mails',
                            placeholder: 'Ex. empresa@empresa.com',
                            addLabel: 'Add E-mail',
                            removeLabel: 'Delete',
                            emptyEmailList: 'E-mail not informed.'
                        },
                        phones: {
                            name: 'Contact Numbers',
                            label: 'Contact Numbers',
                            placeholder: 'Ex. (122) 111-3158',
                            addLabel: 'Add Contact Number',
                            removeLabel: 'Delete',
                            mask: JSON.stringify('(###) ###-####'),
                            emptyList: 'Contact number not informed.'
                        },
                        city: {
                            name: 'City',
                            label: 'City',
                            placeholder: 'Ex. Atlanta - GA',
                            cityNotInformed: 'City not informed.'
                        }
                    }
                },
                back: 'Back',
                cancel: 'Cancel',
                close: 'Close',
                save: 'Save'
            }
        },
        company: {
            status: [{
                text: 'Active',
                value: true
            }, {
                text: 'Inactive',
                value: false
            }],
            search: {
                loadingProjects: 'Loading companies...',
                searchPlaceholder: 'Type the name or EIN',
                filterLabel: 'Name/EIN',
                noProjectsFound: 'No companies found.',
                noProjectsCreated: {
                    title: 'Ops, no companies were found...',
                    message: 'Add companies using the button New.'
                },
                tableColumns: {
                    name: 'Name',
                    categories: 'Categories',
                    updatedAt: 'Last Modified',
                    status: 'Status',
                    actions: 'Actions'
                },
                actions: {
                    add: 'New',
                    view: 'View',
                    edit: 'Edit',
                    delete: 'Delete'
                },
                rowsPerPage: 'Lines per page',
                dialogs: {
                    delete: {
                        title: 'Are you sure about that?',
                        text: 'If you perform this action, company data will be permanently deleted.',
                        options: {
                            reject: 'Cancel',
                            accept: 'Delete'
                        }
                    }
                }
            },
            edit: {
                form: {
                    alert: 'Dear participant, to continue using the features of the application fill the registration of your organization.',
                    fields: {
                        logo: {
                            placeholder: 'Click here or drag and drop the logo to upload the file.'
                        },
                        name: {
                            name: 'Social Name',
                            label: 'Social Name*',
                            placeholder: 'Ex. Industry LTDA'
                        },
                        document: {
                            name: 'EIN',
                            label: 'EIN*',
                            placeholder: 'Ex. 12-0120194',
                            mask: '##-#######',
                        },
                        address: {
                            line1: {
                                name: 'Address Line1',
                                label: 'Address Line1*',
                                placeholder: 'Street address, P.O. box, company name, c/o'
                            },
                            line2: {
                                name: 'Address Line2',
                                label: 'Address Line2',
                                placeholder: 'Apartment, suite, unit, building, floor'
                            },
                            city: {
                                name: 'City',
                                label: 'City*',
                                placeholder: 'Ex. Atlanta - GA',
                                cityNotInformed: 'City not informed.'
                            },
                            state: {
                                name: 'State',
                                label: 'State*',
                                placeholder: 'State, Province or Region'
                            },
                            zipCode: {
                                name: 'ZIP',
                                label: 'ZIP*',
                                placeholder: 'ZIP or Postal Code',
                                mask: ''
                            },
                            country: {
                                name: 'Country',
                                label: 'Country*',
                                placeholder: 'Country'
                            },
                        },
                        representatives: {
                            nameOrEmail: {
                                name: 'Name or e-mail',
                                label: 'Name or e-mail',
                                placeholder: 'Ex. Representative',
                                noDataText: 'There is no user with this name or e-mail. Please try again'
                            },
                            email: {
                                name: 'E-mail',
                                label: 'E-mail',
                                placeholder: 'Ex. representative@company.com.br',
                                errors: {
                                    invalid: 'Please enter a valid e-mail address',
                                    doesNotExist: 'There is no user with this e-mail. Please try again',
                                    userAlreadyAddedInList: 'The user informed already added in the list'
                                }
                            },
                            table: {
                                headers: {
                                    name: 'Name',
                                    email: 'E-mail',
                                    phoneNumber1: 'Phone Number 1',
                                    phoneNumber2: 'Phone Number 2',
                                    action: 'Action'
                                },
                                masks: {
                                    phone: JSON.stringify(['(###) ###-####'])
                                }
                            },
                            title: 'Users',
                            subtitle: 'Please add one or more users to represent your organization',
                            tableDescription: 'Users added:',
                            addLabel: 'Add User',
                            removeLabel: 'Delete',
                            emptyList: 'Users not informed.'
                        },
                        categories: {
                            label: 'Categories*',
                            placeholder: 'Select multiples categories'
                        }
                    }
                },
                back: 'Back',
                cancel: 'Cancel',
                close: 'Close',
                save: 'Save'
            }
        },
        logEmails: {
            search: {
                actions: {
                    remove: 'Delete e-mails'
                },
                searchPlaceholder: 'Type the subject or content',
                loadingLogEmails: 'Loading e-mails...',
                noLogEmailsFound: {
                    title: 'Ops, no emails were found...',
                    message: 'Type something in the search filter to find emails.'
                },
                filterLabel: 'Subject/Content',
                rowsPerPage: 'Lines per page',
                tableColumns: {
                    to: 'To',
                    subject: 'Subject',
                    createdAt: 'Created',
                    actions: {
                        label: 'Actions',
                        view: 'View'
                    }
                },
                dialogs: {
                    remove: {
                        title: 'Are you sure you want to do this?',
                        text: 'If you do this, all selected emails will be permanently deleted.',
                        options:{
                            reject: 'Cancel',
                            accept: 'Delete'
                        }
                    }
                }
            }
        },
        users: {
            search: {
                searchPlaceholder: 'Type the name or e-mail',
                loadingUsers: 'Loading users...',
                noUsersFound: {
                    title: 'Ops, no users were found...',
                    message: 'Type something in the search filter to find users.'
                },
                filters: {
                    nameEmail: {
                        label: 'Name/E-mail'
                    },
                    source: {
                        label: 'User Type'
                    }
                },
                rowsPerPage: 'Lines per page',
                tableColumns: {
                    name: 'Name',
                    email: 'E-mail',
                    admin: 'Admin.',
                    company: 'Company',
                    emailSubscriber: 'Receive E-mail',
                    createdAt: 'Created',
                    actions: 'Actions'
                },
                actions: {
                    add: 'New',
                    invite: 'Invite',
                    view: 'View',
                    edit: 'Edit',
                    resetPassword: 'Reset password',
                    delete: 'Delete'
                },
                dialogs: {
                    delete: {
                        title: 'Are you sure about that?',
                        text: 'If you perform this action, user data will be permanently deleted.',
                        options: {
                            reject: 'Cancel',
                            accept: 'Delete'
                        }
                    },
                    resetPassword: {
                        title: 'Are you sure about that?',
                        text: 'If you perform this action a random password will be generated and sent to the user via email.',
                        options: {
                            reject: 'Cancel',
                            accept: 'Reset'
                        }
                    }
                },
                modals: {
                    add: {
                        title: 'User successfully registered!',
                        text1: 'We sent an email to user containing their login credentials. The generated password is:',
                        text2: 'Please note the importance of saving this password in a secure environment as it will not be possible to review it again.',
                        options: {
                            accept: 'Close'
                        }
                    },
                    resetPassword: {
                        title: 'Password successfully reseted!',
                        text1: 'We sent an email to user containing their new password. The generated password is:',
                        text2: 'Please note the importance of saving this password in a secure environment as it will not be possible to review it again.',
                        options: {
                            accept: 'Close'
                        }
                    },
                }
            },
            edit: {
                form: {
                    fields: {
                        name: {
                            name: 'Fullname',
                            label: 'Fullname*',
                            placeholder: 'User fullname'
                        },
                        email: {
                            name: 'Email',
                            label: 'Email*',
                            placeholder: 'Email'
                        },
                        phone1: {
                            name: 'Commercial Phone Number',
                            label: 'Commercial Phone Number*',
                            mask: JSON.stringify(['(###) ###-####']),
                            placeholder: 'Commercial Phone number'
                        },
                        phone2: {
                            name: 'Personal Phone Number',
                            label: 'Personal Phone Number',
                            mask: JSON.stringify(['(###) ###-####']),
                            placeholder: 'Personal Phone number'
                        },
                        isAdmin: {
                            label: 'System Administrator'
                        },
                        source: {
                            label: 'User Type',
                            placeholder: {
                                internal: 'System active user',
                                confirmed: 'System active user',
                                invite: 'User invited to system',
                                migration: 'User not yet invited to system'
                            }
                        },
                        createdAt: {
                            name: 'Created at',
                            label: 'Created at'
                        }
                    }
                },
                back: 'Back',
                cancel: 'Cancel',
                save: 'Save'
            }
        },
        representatives: {
            search: {
                tableColumns: {
                    name: 'Name',
                    email: 'E-mail',
                    status: 'Status',
                    actions: 'Actions'
                },
                status: {
                    invite: 'Invited',
                    confirmed: 'Active',
                    internal: 'Active'
                },
                actions: {
                    add: 'New'
                },
                modals: {
                    add: {
                        title: 'Invite new representative',
                        subtitle: 'Please complete the form below to invite a new representative. Upon confirmation, an email will be sent containing the invitation to join your organization.',
                        form: {
                            fields: {
                                name: {
                                    name: 'Name',
                                    label: 'Name',
                                    placeholder: 'Name of representative'
                                },
                                email: {
                                    name: 'E-mail',
                                    label: 'E-mail',
                                    placeholder: 'E-mail of representative'
                                }
                            },
                            actions: {
                                cancel: 'Cancel',
                                save: 'Invite'
                            }
                        }
                    }
                },
                noRepresentativesFound: {
                    title: 'Ops, no representatives found...',
                    message: 'Add new representatives using the "New" option.'
                },
                errors: {
                    findRepresentatives: 'An error ocurred while find representatives.'
                }
            }
        }
    }
}
