<template>
    <div>
        <az-toolbar>
            <az-call-to-action style="font-size: 14px" active slot="actions" @click="addCustomer">
                <v-icon small dense>add_circle</v-icon>
                {{ $t('customer.search.actions.add') }}
            </az-call-to-action>
            <az-search slot="simpleSearch"
                       style="font-size: 14px"
                       :filter="activeFilters"
                       @simple-search="simpleSearchEvent"
                       @remove-filter="removeFilterEvent"
                       :simple-search-placeholder="$t('customer.search.searchPlaceholder')">
            </az-search>
        </az-toolbar>
        <customer-search-table :itens="customers"
                               :pages="pages"
                               :total-itens="totalItens"
                               :pagination="customerSearch.pagination"
                               @paginate="paginateEvent"
                               @view="viewCustomerDetailEvent"
                               @edit="editCustomerEvent"
                               v-if="hasCustomers"/>
        <az-ops :title="$t('customer.search.noProjectsCreated.title')"
                :message="$t('customer.search.noProjectsCreated.message')" v-else/>
    </div>
</template>

<script>
    import _ from 'lodash'
    import {mapActions, mapMutations, mapState} from 'vuex'
    import {filters} from '@azinformatica/loki'
    import i18n from '@/commons/i18n'
    import {actionTypes, mutationTypes} from '@/commons/constants'
    import CustomerSearchTable from './CustomerSearchTable'

    export default {
        components: {CustomerSearchTable},
        name: 'customer-search',
        data() {
            return {
                internalFilters: _.cloneDeep(this.$store.state.customerSearch.filters),
                pages: 0,
                customers: undefined,
                totalItens: 0
            }
        },
        computed: {
            ...mapState(['customerSearch']),
            activeFilters() {
                const allFilters = _.cloneDeep(this.customerSearch.filters)
                const activeFilters = {}
                for (let [key, val] of Object.entries(allFilters)) {
                    if (allFilters[key].value) {
                        val.valueTruncated = filters.azClipText(val.value, 10)
                        activeFilters[key] = val
                    }
                }
                return activeFilters
            },
            hasCustomers() {
                return this.customers !== undefined && this.customers.length > 0
            }
        },
        async mounted() {
            await this.executeSearch()
            this.openAsideMenu()
        },
        methods: {
            ...mapActions([actionTypes.FIND_CUSTOMERS]),
            ...mapMutations([
                mutationTypes.CLEAN_CUSTOMER_SEARCH_FILTERS,
                mutationTypes.SET_CUSTOMER_SEARCH_FILTERS,
                mutationTypes.SET_CUSTOMER_SEARCH_PAGINATION
            ]),
            addCustomer() {
                this.$router.push({name: 'addCustomer'})
            },
            editCustomerEvent(id) {
                this.$router.push({name: 'editCustomer', params: {id}, query: {editing: true}})
            },
            async executeSearch() {
                this.setCustomerSearchFilters(this.internalFilters)
                this.setLoadingMessage(i18n.t('customer.search.loadingProjects'))
                const result = await this.findCustomers()
                this.customers = result.content
                this.pages = result.pages
                this.totalItens = result.totalElements
            },
            paginateEvent(pagination) {
                this.setCustomerSearchPagination(pagination)
                this.executeSearch()
            },
            removeFilterEvent() {
                this.cleanCustomerSearchFilters()
                this.internalFilters = _.cloneDeep(this.customerSearch.filters)
                this.executeSearch()
            },
            simpleSearchEvent(value) {
                this.internalFilters.nameDoc = {
                    value: value,
                    label: i18n.t('customer.search.filterLabel')
                }
                this.executeSearch()
            },
            viewCustomerDetailEvent(id) {
                this.$router.push({name: 'editCustomer', params: {id}})
            }
        }
    }
</script>