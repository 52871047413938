<template>
    <div class="mt-3 pb-3">
        <div class="az-form-content" v-if="hasParticipants">
            <v-container fluid grid-list-lg>
                <project-participants-table
                    :participants="participants"
                    :editing="editing"
                    @documents="viewDocs"
                    @changeStatus="changeStatus"/>
                <project-participants-docs
                    v-if="showDocs"
                    :project-id="project._id"
                    :participant="participant"
                    @refresh="refreshDocs"
                    @close="hideDocs"/>
                <visitors
                    v-if="showVisitors"
                    :visitors="visitors"
                    @close="closeVisitorsModal"/>
                <az-call-to-action class="mt-3" @click="openVisitorsList">
                    {{$t('project.admin.edit.tabs.participants.actions.showVisitorsList')}}
                </az-call-to-action>
            </v-container>
        </div>
        <az-ops
            :title="$t('project.admin.edit.tabs.participants.noParticipantsFound')"
            message=""
            v-else/>
        <reproval-notes
            :participant="participantReproved"
            v-if="askingForReprovalNotes"
            @cancel="cancelParticipantReproval"
            @confirm="updateStatus"
        />
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import {actionTypes, mutationTypes} from '@/commons/constants'
    import ProjectParticipantsTable from './ProjectParticipantsTable'
    import ProjectParticipantsDocs from './documents/Docs'
    import Visitors from './visitors/Visitors'
    import ReprovalNotes from './ReprovalNotes'

    export default {
        components: {
            ReprovalNotes,
            Visitors,
            ProjectParticipantsDocs,
            ProjectParticipantsTable
        },
        name: 'edit-project-participants',
        data() {
            return {
                askingForReprovalNotes: false,
                editing: false,
                participant: {},
                participantReproved: {},
                participants: [],
                showDocs: false,
                showVisitors: false,
                visitors: []
            }
        },
        computed: {
            ...mapState(['project']),
            hasParticipants() {
                return this.project.participants && this.project.participants.length > 0
            }
        },
        watch: {
            'project'() {
                if (this.project.status === 'FINISHED') {
                    this.setViewMode(false)
                }
            }
        },
        async mounted() {
            this.setViewMode(`${this.$route.query.editing}` === 'true')
            this.cleanProjectData()
            await this.findProjectById()
            await this.findProjectParticipants()
        },
        methods: {
            async cancelParticipantReproval() {
                this.participantReproved = {}
                this.closeReprovalNotesModal()
                await this.findProjectParticipants()
            },
            async changeStatus({id, status}) {
                if (this.isReprovingParticipant(status)) {
                    this.participantReproved = {id, status, reprovalNotes: ''}
                    this.askingForReprovalNotes = true
                } else {
                    await this.updateStatus({id, status})
                }
            },
            cleanProjectData() {
                this.$store.commit(mutationTypes.SET_PROJECT, {
                    calendar: {},
                    categories: [],
                    locations: [],
                    termsAndConditions: [],
                    participants: []
                })
            },
            closeReprovalNotesModal() {
                this.askingForReprovalNotes = false
                this.participantReproved = {}
            },
            closeVisitorsModal() {
                this.showVisitors = false
            },
            async findProjectById() {
                try {
                    await this.$store.dispatch(actionTypes.FIND_PROJECT_BY_ID, this.$route.params.id)
                } catch (e) {
                    this.showErrorFrontEnd('project.admin.edit.errors.projectNotFound')
                    this.$router.push({name: 'home'})
                }
            },
            async findProjectParticipants() {
                this.participants = await this.$store.dispatch(actionTypes.FIND_PROJECT_PARTICIPANTS, this.$route.params.id)
            },
            async hideDocs() {
                this.showDocs = false
                this.participant = {}
                await this.findProjectParticipants()
            },
            isReprovingParticipant(status) {
                return status === 'REPROVED'
            },
            async openVisitorsList() {
                this.visitors = await this.$store.dispatch(actionTypes.FIND_ALL_VISITORS, this.project._id)
                this.showVisitors = true
            },
            async refreshDocs() {
                const participant = this.participant
                this.hideDocs()
                this.viewDocs(participant)
            },
            setViewMode(editing) {
                this.editing = editing
            },
            async updateStatus({id, status, reprovalNotes}) {
                await this.updateParticipantStatus(id, status, reprovalNotes)
                await this.findProjectById()
                await this.findProjectParticipants()
                this.showSuccess()
                this.closeReprovalNotesModal()
            },
            async updateParticipantStatus(participantId, status, reprovalNotes = '') {
                await this.$store.dispatch(actionTypes.UPDATE_PARTICIPANT_STATUS, {
                    projectId: this.project._id,
                    participantId,
                    status,
                    reprovalNotes
                })
            },
            async viewDocs(participant) {
                this.participant = participant
                this.participant.documents = await this.$store.dispatch(actionTypes.FIND_LATEST_VERSION_OF_DOCUMENTS, {
                    companyId: this.participant.company._id,
                    types: this.project.requiredDocuments.map(doc => {
                        if (doc.mandatory) {
                            return doc.type
                        }
                    })
                })
                this.showDocs = true
            }
        }
    }
</script>