<template>
    <div>
        <az-back-button :route="{name: 'projects'}" :text="$t('project.admin.edit.back')"/>
        <az-container>
            <summary-bar inserindo/>
            <div>
                <div class="az-form-content">
                    <v-container fluid grid-list-lg>
                        <general-data-form ref="generalRef" v-model="project" editing/>
                        <div class="az-actions-form">
                            <div class="align-left"></div>
                            <div class="align-right">
                                <button class="action-secundary" @click.prevent="cancel">
                                    {{$t('project.admin.edit.cancel')}}
                                </button>
                                <button class="action-primary" @click.prevent="create">
                                    {{$t('project.admin.edit.save')}}
                                </button>
                            </div>
                        </div>
                    </v-container>
                </div>
            </div>
        </az-container>
    </div>
</template>

<script>
    import _ from 'lodash'
    import {actionTypes} from '@/commons/constants'
    import SummaryBar from '../SummaryBar'
    import GeneralDataForm from './GeneralDataForm'

    export default {
        name: 'add-project',
        components: {GeneralDataForm, SummaryBar},
        data() {
            return {
                project: {
                    calendar: {},
                    categories: [],
                    locations: []
                }
            }
        },
        mounted() {
            this.closeAsideMenu()
        },
        methods: {
            cancel() {
                this.$router.push({name: 'projects'})
            },
            async create() {
                const isFormValid = await this.$validator._base.validateAll('general')
                if (!isFormValid || !this.hasCategories() || !this.hasLocations()) {
                    return
                }
                try {
                    const payload = this.preparePayload()
                    const createdProject = await this.$store.dispatch(actionTypes.CREATE_PROJECT, payload)
                    this.showSuccess()
                    this.$router.push({name: 'editProject', params: {id: createdProject._id}, query: {editing: true}})
                } catch (e) {
                    this.showError(e)
                }
            },
            getLocationsIdList(locations) {
                const locationsIdList = []
                locations.forEach(location => locationsIdList.push(location._id))
                return locationsIdList
            },
            hasCategories() {
                if (this.project.categories.length) {
                    return true
                }
                this.showErrorFrontEnd('project.admin.edit.errors.categoriesNotInformed')
                return false
            },
            hasLocations() {
                if (this.project.locations.length) {
                    return true
                }
                this.showErrorFrontEnd('project.admin.edit.errors.locationsNotInformed')
                return false

            },
            preparePayload() {
                const payload = _.clone(this.project)
                payload.locations = this.getLocationsIdList(payload.locations)
                payload.customer = this.project.customer.value
                return payload
            }
        }
    }
</script>