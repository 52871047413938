import store from '@/commons/store'

class NoCompany {

    shouldExecute(to) {
        const isAdmin = store.state.loggedUser.isAdmin
        const company = store.state.company
        return (!isAdmin && !company._id) && (to.name !== 'addMyCompany')
    }

    execute(to, from, next) {
        next({name: 'addMyCompany'})
    }
}

export default new NoCompany()
