import store from '@/commons/store'

class HasAuthorities {

    shouldExecute(to) {
        return !this.hasAuthorities(to)
    }

    execute(to, from, next) {
        const isAdmin = store.state.loggedUser.isAdmin
        if (isAdmin) {
            next({name: 'projects'})
        } else {
            next({name: 'participant'})
        }
    }

    hasAuthorities(to) {
        const requiresAuthorities = to.meta && to.meta.authorities && to.meta.authorities.length
        if (!requiresAuthorities) {
            return true
        }
        const required = to.meta.authorities
        const userAuthorities = store.state.loggedUser.authorities
        let hasAuths = false
        for (let req of required) {
            const auth = userAuthorities.find(auth => auth.name === req)
            if (auth && auth.hasAccess) {
                hasAuths = true
                break
            }
        }
        return hasAuths
    }
}

export default new HasAuthorities()
