<template>
    <v-data-table
        :headers="headers"
        :items="dropouts"
        :loading="false"
        hide-default-footer
        disable-pagination
        class="az-table-list">
        <template v-slot:item="props">
            <tr>
                <td>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <span v-on="on">
                                {{ props.item.companyId.name | azClipText(60)}}
                            </span>
                        </template>
                        <span>{{props.item.companyId.name}}</span>
                    </v-tooltip>
                </td>
                <td>{{props.item.reason}}</td>
                <td>{{props.item.date | azDate('DD/MM/YYYY HH:mm')}}</td>
            </tr>
        </template>
    </v-data-table>
</template>

<script>
    export default {
        name: 'project-dropouts-table',
        props: {
            dropouts: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                headers: [
                    {
                        text: this.$t('project.admin.edit.tabs.dropouts.tableColumns.company'),
                        align: 'left',
                        sortable: false,
                        class: 'primary--text',
                        width: '35%'
                    },
                    {
                        text: this.$t('project.admin.edit.tabs.dropouts.tableColumns.reason'),
                        align: 'left',
                        sortable: false,
                        class: 'primary--text',
                        width: '55%'
                    },
                    {
                        text: this.$t('project.admin.edit.tabs.dropouts.tableColumns.dateDropout'),
                        align: 'left',
                        sortable: false,
                        class: 'primary--text',
                        width: '10%'
                    }
                ]
            }
        }
    }
</script>