<template>
    <v-dialog
        :value=true
        persistent
        width="500">
        <v-card>
            <v-card-title class="primary white--text" primary-title>
                <v-layout row wrap>
                    <v-flex xs12>
                        <span primary-title class="headline white--text">
                            {{$t('project.admin.edit.tabs.participants.reproveModal.reprovalNotes')}}
                        </span>
                    </v-flex>
                </v-layout>
            </v-card-title>
            <v-card-text>
                <v-layout row wrap>
                    <v-flex xs12>
                        <v-textarea
                            :label="$t('project.admin.edit.tabs.participants.reproveModal.reprovalNotes')"
                            :name="$t('project.admin.edit.tabs.participants.reproveModal.reprovalNotes')"
                            :error-messages="errors.collect(`$t('project.admin.edit.tabs.participants.reproveModal.reprovalNotes')`)"
                            v-validate="'required'"
                            v-model="reprovalNotes"/>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="text--secondary" text @click="$emit('cancel')">
                    {{$t('project.admin.edit.cancel')}}
                </v-btn>
                <v-btn color="primary" text @click="validateBeforeSave">
                    {{$t('project.admin.edit.save')}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'reproval-notes',
        props: {
            participant: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                reprovalNotes: ''
            }
        },
        methods: {
            async validateBeforeSave() {
                const valid = await this.$validator._base.validateAll()
                if (!valid) {
                    return
                }
                this.participant.reprovalNotes = this.reprovalNotes
                this.$emit('confirm', this.participant)
            }
        }
    }
</script>