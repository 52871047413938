<template>
    <div>
        <document-search-table :documents="documents"
                                v-if="documents.length"
                                style="margin: 12px;"/>
        <az-ops :title="$t('project.participant.documents.noDocumentsFound.title')"
                :message="$t('project.participant.documents.noDocumentsFound.message')"
                v-if="documents.length === 0"/>
    </div>
</template>

<script>
    import DocumentSearchTable from "./DocumentSearchTable";
    import {actionTypes} from '@/commons/constants'
    import {mapState} from 'vuex'

    export default {
        name: 'document-search',
        components: {DocumentSearchTable},
        data() {
            return {
                documents: []
            }
        },
        computed: {
            ...mapState(['company', 'loki'])
        },
        methods: {
            async findDocumentsByCompanyId() {
                try {
                    this.documents = await this.$store.dispatch(actionTypes.FIND_DOCUMENTS_BY_COMPANY_ID, this.company._id)
                    this.orderDocumentsByType()
                } catch (e) {
                    this.showErrorFrontEnd('project.participant.documents.errors.findDocs')
                }
            },
            orderDocumentsByType() {
                this.documents.forEach(doc => doc.translatedType = this.$t(`project.requiredDocuments.${doc.type}`))
                this.documents.sort((a, b) => a.translatedType.localeCompare(b.translatedType))
            }
        },
        mounted() {
            this.findDocumentsByCompanyId()
            this.openAsideMenu()
        }
    }
</script>
