<template>
    <div>
        <az-container>
            <az-form>
                <v-data-table
                        :headers="columns" :items="itens"
                        :no-data-text="$t('company.search.noProjectsFound')"
                        class="az-table-list tabela-usuarios"
                        hide-default-footer
                        disable-pagination>
                    <template v-slot:item="props">
                        <tr class="clickable" @click="$emit('edit',props.item._id)">
                            <td>{{ props.item.name }}</td>
                            <td>
                                <v-chip
                                        v-for="(category, index) in props.item.categories"
                                        v-bind:key="index"
                                        :color="selectColor(category)"
                                        text-color="white"
                                        small>
                                    {{[category] | categoriesFilter}}
                                </v-chip>
                            </td>
                            <td>{{ props.item.updatedAt | azDate('DD/MM/YYYY HH:mm') }}</td>
                            <td @click.prevent.stop="">
                                <v-select
                                        class="pt-0"
                                        item-text="text"
                                        item-value="value"
                                        :items="companyStatus"
                                        @change="updateStatus(props.item)"
                                        v-model="props.item.active"/>
                            </td>
                            <td class="text-xs-right" @click.stop="">
                                <v-menu bottom left>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" dark icon text depressed color="primary">
                                            <v-icon>more_vert</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item @click.prevent.stop="$emit('view',props.item._id)">
                                            <v-list-item-title>
                                                <v-icon size="18px" class="mr-1">search</v-icon>
                                                {{ $t('company.search.actions.view') }}
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="$emit('edit',props.item._id)">
                                            <v-list-item-title>
                                                <v-icon size="18px" class="mr-1">edit</v-icon>
                                                {{ $t('company.search.actions.edit') }}
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click.stop="showDeleteDialog(props.item._id)">
                                            <v-list-item-title>
                                                <v-icon size="18px" class="mr-1">delete</v-icon>
                                                {{ $t('company.search.actions.delete') }}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
                <div class="az-pagination">
                    <v-pagination v-model="internalPagination.page" :length="pages"/>
                    <div class="az-select-pagination">
                        <span>{{ $t('company.search.rowsPerPage') }}</span>
                        <v-select :items="rowsPerPage" v-model="internalPagination.rowsPerPage"/>
                    </div>
                </div>
                <v-dialog v-model="openDeleteDialog" max-width="290">
                    <v-card>
                        <v-card-title class="headline">{{$t('company.search.dialogs.delete.title')}}</v-card-title>
                        <v-card-text>{{$t('company.search.dialogs.delete.text')}}</v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="text--secondary" text @click="hideDialog()">
                                {{$t('company.search.dialogs.delete.options.reject')}}
                            </v-btn>
                            <v-btn color="primary" text @click="deleteOnRemoteServer()">
                                {{$t('company.search.dialogs.delete.options.accept')}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </az-form>
        </az-container>
    </div>
</template>

<script>
    import i18n from '@/commons/i18n'

    export default {
        name: 'company-search-table',
        props: ['itens', 'pagination', 'pages', 'totalItens'],
        data() {
            return {
                columns: [
                    {
                        text: i18n.t('company.search.tableColumns.name'),
                        align: 'left',
                        sortable: false,
                        width: '35%',
                        class: 'primary--text'
                    },
                    {
                        text: i18n.t('company.search.tableColumns.categories'),
                        align: 'left',
                        sortable: false,
                        width: '25%',
                        class: 'primary--text'
                    },
                    {
                        text: i18n.t('company.search.tableColumns.updatedAt'),
                        align: 'left',
                        sortable: false,
                        width: '15%',
                        class: 'primary--text'
                    },
                    {
                        text: i18n.t('company.search.tableColumns.status'),
                        align: 'left',
                        sortable: false,
                        width: '15%',
                        class: 'primary--text'
                    },
                    {
                        text: i18n.t('company.search.tableColumns.actions'),
                        align: 'right',
                        sortable: false,
                        width: '1%',
                        class: 'primary--text'
                    }
                ],
                colorCategories: {
                    iron: '#e91e63',
                    ironless: '#673ab7',
                    platic: '#2196f3',
                    rubber: '#4caf50',
                    wood: '#afb42b',
                    paperboard: '#ff9800',
                    eletronic: '#ff5722'
                },
                openDeleteDialog: false,
                companyId: null,
                internalPagination: this.pagination,
                rowsPerPage: [5, 10, 25, 50, 100]
            }
        },
        computed: {
            companyStatus() {
                return Object.values(this.$t('company.status'))
            }
        },
        watch: {
            'internalPagination.page': {
                handler() {
                    this.$emit('paginate', this.internalPagination)
                }
            },
            'internalPagination.rowsPerPage': {
                handler() {
                    this.internalPagination.page = 1
                    this.$emit('paginate', this.internalPagination)
                }
            }
        },
        methods: {
            deleteOnRemoteServer() {
                this.$emit('delete', this.companyId)
                this.hideDialog()
            },
            hideDialog() {
                this.openDeleteDialog = false
                this.setCompanyIdOnState(null)
            },
            updateStatus(company) {
                this.$emit('status', company)
            },
            selectColor(category) {
                return this.colorCategories[category]
            },
            setCompanyIdOnState(id) {
                this.companyId = id
            },
            showDeleteDialog(id) {
                this.openDeleteDialog = true
                this.setCompanyIdOnState(id)
            }
        }
    }
</script>