<template>
    <v-container fluid grid-list-lg>
        <v-layout row wrap align-center>
            <v-flex xs12>
                <user-form-name :editing="editing" v-model="value"/>
            </v-flex>
            <v-flex xs12>
                <user-form-email :editing="editing" v-model="value"/>
            </v-flex>
            <v-flex xs12 sm6>
                <user-form-phone1 :editing="editing" v-model="value"/>
            </v-flex>
            <v-flex xs12 sm6>
                <user-form-phone2 :editing="editing" v-model="value"/>
            </v-flex>
            <v-flex xs12 sm6>
                <user-form-source :editing="editing" v-model="value"/>
            </v-flex>
            <v-flex xs12 sm6>
                <user-form-admin :editing="editing" v-model="value"/>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import UserFormName from './UserFormName'
    import UserFormEmail from './UserFormEmail'
    import UserFormPhone1 from './UserFormPhone1'
    import UserFormPhone2 from './UserFormPhone2'
    import UserFormAdmin from './UserFormAdmin'
    import UserFormSource from './UserFormSource'

    export default {
        name: 'user-form',
        components: {
            UserFormName,
            UserFormEmail,
            UserFormPhone1,
            UserFormPhone2,
            UserFormAdmin,
            UserFormSource
        },
        props: {
            editing: {
                type: Boolean,
                default: true
            },
            value: {
                required: true
            }
        }
    }
</script>
