<template>
    <v-layout row wrap align-center>
        <v-flex xs12 sm6>
            <v-autocomplete
                    :name="$t('project.admin.edit.tabs.generalData.fields.customer.name')"
                    :label="$t('project.admin.edit.tabs.generalData.fields.customer.label')"
                    :placeholder="$t('project.admin.edit.tabs.generalData.fields.customer.placeholder')"
                    :data-vv-scope="validatorScope"
                    :error-messages="errors.collect(`general.${$t('project.admin.edit.tabs.generalData.fields.customer.name')}`)"
                    :items="customers"
                    :search-input.sync="customerSearch"
                    :loading="isLoadingCustomers"
                    :no-data-text="$t('project.admin.edit.tabs.generalData.fields.customer.noDataText')"
                    return-object
                    v-model="value.customer"
                    v-if="editing && newRecord"
                    v-validate="'required'"/>
            <az-text-view
                    :label="$t('project.admin.edit.tabs.generalData.fields.customer.label')"
                    :text="customerName"
                    v-else/>
        </v-flex>
        <v-flex xs12 sm6>
            <v-text-field
                    :name="$t('project.admin.edit.tabs.generalData.fields.name.name')"
                    :label="$t('project.admin.edit.tabs.generalData.fields.name.label')"
                    :placeholder="$t('project.admin.edit.tabs.generalData.fields.name.placeholder')"
                    :data-vv-scope="validatorScope"
                    :error-messages="errors.collect(`general.${$t('project.admin.edit.tabs.generalData.fields.name.name')}`)"
                    v-model="value.name"
                    v-if="editing"
                    v-validate="'required'"/>
            <az-text-view
                    :label="$t('project.admin.edit.tabs.generalData.fields.name.label')"
                    :text="value.name"
                    v-else/>
        </v-flex>
        <v-flex xs12>
            <options-picker
                    :selected="selectedCategories"
                    :options="translatedCategories"
                    :editing="editing"
                    :label="$t('project.admin.edit.tabs.generalData.fields.categories')"
                    required
                    display-field="text"
                    @add="addCategory"
                    @remove="removeCategory"/>
        </v-flex>
        <v-flex xs12>
            <options-picker
                    :selected="value.locations"
                    :options="locationOptions"
                    :editing="editing"
                    :label="$t('project.admin.edit.tabs.generalData.fields.locations')"
                    required
                    display-field="formattedLocation"
                    @add="addLocation"
                    @remove="removeLocation"/>
        </v-flex>
        <v-flex xs12 sm12>
            <v-textarea
                    :name="$t('project.admin.edit.tabs.generalData.fields.description')"
                    :label="$t('project.admin.edit.tabs.generalData.fields.description')"
                    v-if="editing"
                    v-model="value.description"/>
            <az-text-view
                    :label="$t('project.admin.edit.tabs.generalData.fields.description')"
                    :text="value.description"
                    v-else/>
        </v-flex>
        <v-flex xs12>
            <h3 class="secondary--text mb-0">
                {{$t('project.admin.edit.tabs.generalData.fields.calendar.label')}}
            </h3>
        </v-flex>
        <v-flex xs12 sm4>
            <az-date
                    date-time
                    :label="$t('project.admin.edit.tabs.generalData.fields.calendar.kickoffLabel')"
                    :name-date="$t('project.admin.edit.tabs.generalData.fields.calendar.kickoffDateName')"
                    :name-hour="$t('project.admin.edit.tabs.generalData.fields.calendar.kickoffHourName')"
                    v-if="editing"
                    v-model="value.calendar.kickoff"/>
            <az-text-view
                    :label="$t('project.admin.edit.tabs.generalData.fields.calendar.kickoffLabel')"
                    :text="value.calendar.kickoff | azDate('DD/MM/YYYY HH:mm')"
                    v-else/>
        </v-flex>
        <v-flex xs12 sm4>
            <az-date
                    date-time
                    :label="$t('project.admin.edit.tabs.generalData.fields.calendar.visitationLabel')"
                    :name-date="$t('project.admin.edit.tabs.generalData.fields.calendar.visitationDateName')"
                    :name-hour="$t('project.admin.edit.tabs.generalData.fields.calendar.visitationHourName')"
                    v-if="editing"
                    v-model="value.calendar.visitation"/>
            <az-text-view
                    :label="$t('project.admin.edit.tabs.generalData.fields.calendar.visitationLabel')"
                    :text="value.calendar.visitation | azDate('DD/MM/YYYY HH:mm')"
                    v-else/>
        </v-flex>
        <v-flex xs12 sm4>
            <az-date
                    date-time
                    :label="$t('project.admin.edit.tabs.generalData.fields.calendar.basicDocumentsLabel')"
                    :name-date="$t('project.admin.edit.tabs.generalData.fields.calendar.basicDocumentsDateName')"
                    :name-hour="$t('project.admin.edit.tabs.generalData.fields.calendar.basicDocumentsHourName')"
                    v-if="editing"
                    v-model="value.calendar.basicDocuments"/>
            <az-text-view
                    :label="$t('project.admin.edit.tabs.generalData.fields.calendar.basicDocumentsLabel')"
                    :text="value.calendar.basicDocuments | azDate('DD/MM/YYYY HH:mm')"
                    v-else/>
        </v-flex>
        <v-flex xs12 sm4>
            <az-date
                    date-time
                    :label="$t('project.admin.edit.tabs.generalData.fields.calendar.additionalDocumentsLabel')"
                    :name-date="$t('project.admin.edit.tabs.generalData.fields.calendar.additionalDocumentsDateName')"
                    :name-hour="$t('project.admin.edit.tabs.generalData.fields.calendar.additionalDocumentsHourName')"
                    v-if="editing"
                    v-model="value.calendar.additionalDocuments"/>
            <az-text-view
                    :label="$t('project.admin.edit.tabs.generalData.fields.calendar.additionalDocumentsLabel')"
                    :text="value.calendar.additionalDocuments | azDate('DD/MM/YYYY HH:mm')"
                    v-else/>
        </v-flex>
        <v-flex xs12 sm4>
            <az-date
                    date-time
                    :label="$t('project.admin.edit.tabs.generalData.fields.calendar.negociationLabel')"
                    :name-date="$t('project.admin.edit.tabs.generalData.fields.calendar.negociationDateName')"
                    :name-hour="$t('project.admin.edit.tabs.generalData.fields.calendar.negociationHourName')"
                    v-if="editing"
                    v-model="value.calendar.negociation"/>
            <az-text-view
                    :label="$t('project.admin.edit.tabs.generalData.fields.calendar.negociationLabel')"
                    :text="value.calendar.negociation | azDate('DD/MM/YYYY HH:mm')"
                    v-else/>
        </v-flex>
    </v-layout>
</template>

<script>
    import _ from 'lodash'
    import moment from 'moment-timezone'
    import {mapState} from 'vuex'
    import {actionTypes, categories, mutationTypes} from '@/commons/constants'
    import OptionsPicker from '@/views/components/OptionsPicker'

    export default {
        components: {OptionsPicker},
        name: 'general',
        props: {
            value: {
                required: true
            },
            editing: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                customers: [],
                customerSearch: null,
                isLoadingCustomers: false,
                selectedCategories: [],
                translatedCategories: [],
                validatorScope: 'general'
            }
        },
        computed: {
            ...mapState(['cities']),
            customerName() {
                return this.value.customer ? this.value.customer.name : ''
            },
            newRecord() {
                return this.value._id ? false : true
            },
            locationOptions() {
                const locations = []
                for (let city of this.cities) {
                    locations.push({text: `${city.name} - ${city.state}`, value: city})
                }
                return locations
            }
        },
        watch: {
            'value'() {
                this.formatDateAndTime()
                this.setCustomers()
            },
            'value.categories'() {
                this.translateSelectedCategories()
            },
            async 'customerSearch'(value) {
                if (value && value.length < 3) {
                    return
                }
                this.customers = await this.findCustomers(value)
            }
        },
        async mounted() {
            if (!this.isCitiesLoaded()) {
                await this.loadCities()
            }
            this.translateCategories()
            this.translateSelectedCategories()
        },
        beforeDestroy() {
            const filters = _.clone(this.$store.state.customerSearch.filters)
            filters.nameDoc.value = ''
            this.$store.commit(mutationTypes.SET_CUSTOMER_SEARCH_FILTERS, filters)
        },
        methods: {
            addCategory(category) {
                if (this.isDuplicatedCategory(category)) {
                    return
                }
                this.value.categories.push(category)
            },
            addLocation(location) {
                location.formattedLocation = `${location.name} - ${location.state}`
                if (this.isDuplicatedLocation(location)) {
                    return
                }
                this.value.locations.push(location)
            },
            formatDateAndTime() {
                this.value.calendar.kickoff = this.getMomentObject(this.value.calendar.kickoff)
                this.value.calendar.basicDocuments = this.getMomentObject(this.value.calendar.basicDocuments)
                this.value.calendar.additionalDocuments = this.getMomentObject(this.value.calendar.additionalDocuments)
                this.value.calendar.visitation = this.getMomentObject(this.value.calendar.visitation)
                this.value.calendar.negociation = this.getMomentObject(this.value.calendar.negociation)
            },
            getMomentObject(dateStr) {
                return dateStr ? moment(dateStr).format('YYYY-MM-DDTHH:mm:ss.SSZ') : ''
            },
            isCitiesLoaded() {
                return this.cities.length > 0
            },
            isDuplicatedCategory(category) {
                const existingCategory = this.value.categories.find(c => c === category)
                return existingCategory
            },
            isDuplicatedLocation(location) {
                const existingLocation = this.value.locations.find(l => l._id === location._id)
                return existingLocation
            },
            async loadCities() {
                await this.$store.dispatch(actionTypes.LOAD_CITIES)
            },
            async findCustomers(searchCriteria) {
                this.disableGlobalLoading()
                this.isLoadingCustomers = true
                const foundCustomers = await this.findCustomersByNameOrDoc(searchCriteria)
                const customers = []
                foundCustomers.content.forEach(customer => customers.push({
                    text: customer.name,
                    value: customer
                }))
                this.isLoadingCustomers = false
                this.enableGlobalLoading()
                return customers
            },
            async findCustomersByNameOrDoc(searchCriteria) {
                const filters = _.clone(this.$store.state.customerSearch.filters)
                filters.nameDoc.value = searchCriteria
                this.$store.commit(mutationTypes.SET_CUSTOMER_SEARCH_FILTERS, filters)
                return await this.$store.dispatch(actionTypes.FIND_CUSTOMERS)
            },
            removeCategory(category) {
                const index = this.value.categories.indexOf(category.value)
                this.value.categories.splice(index, 1)
            },
            removeLocation(location) {
                const index = this.value.locations.findIndex(l => l._id === location._id)
                this.value.locations.splice(index, 1)
            },
            setCustomers() {
                if (this.value.customer) {
                    this.customers = [this.value.customer]
                }
            },
            translateCategories() {
                categories.map(category => {
                    const key = `project.categories.${category}`
                    this.translatedCategories.push({text: this.$t(key), value: category})
                })
            },
            translateSelectedCategories() {
                this.selectedCategories = []
                this.value.categories.map(category => {
                    const key = `project.categories.${category}`
                    this.selectedCategories.push({text: this.$t(key), value: category})
                })
            }
        }
    }
</script>
