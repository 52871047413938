var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fa-company-form-representatives-table"},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticClass:"body-2 subtitulo"},[_vm._v(" "+_vm._s(_vm.$t('company.edit.form.fields.representatives.tableDescription'))+" ")])]),_c('v-flex',{staticClass:"table",attrs:{"xs12":""}},[_c('v-data-table',{staticClass:"az-table-list",attrs:{"headers":_vm.headers,"items":_vm.value.representatives,"loading":false,"hide-default-footer":"","disable-pagination":"","item-key":"name"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("azClipText")((props.item.fullName || props.item.name),30))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(props.item.fullName || props.item.name))])])],1),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("azClipText")((props.item.email || props.item.name),30))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(props.item.email || props.item.name))])])],1),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("phone-filter")(_vm.checkUndefinedField(props.item.phoneNumber1),_vm.getPhoneMask()))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm._f("phone-filter")(_vm.checkUndefinedField(props.item.phoneNumber1),_vm.getPhoneMask()))+" ")])])],1),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("phone-filter")(_vm.checkUndefinedField(props.item.phoneNumber2),_vm.getPhoneMask()))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm._f("phone-filter")(_vm.checkUndefinedField(props.item.phoneNumber2),_vm.getPhoneMask()))+" ")])])],1),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"button-remove",attrs:{"color":"#ccc","disabled":_vm.canIEdit(props.item)},on:{"click":function($event){$event.preventDefault();return _vm.remove(props.index)}}},on),[_vm._v(" cancel ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('company.edit.form.fields.representatives.removeLabel'))+" ")])])],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }