<template>
    <v-container class="fa-detail-project-terms">
        <v-tabs class="az-tabs" style="width: 99%">
            <v-tab v-for="tab in tabs" :key="tab" class="ml-2">{{ tab }}</v-tab>
            <v-tab-item
                    :key="$t('project.participant.detail.attachmentTabs.attachment1')"
                    v-if="hasAttachment('edital')"
            >
                <v-layout style="margin: 10px; display: block" v-if="isPDF(slugs[0])">
                    <div>
                        <embed :src="slugs[0]" width="100%" height="572px" type="application/pdf"/>
                    </div>
                </v-layout>
                <v-layout style="margin: 281px; display: block" v-else>
                    <v-flex xs12 class="text-md-center">
                        <v-btn
                                color="green"
                                dark
                                @click="downloadAttachment('edital')"
                        >{{$t('project.participant.detail.downloadAttachment')}}
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-tab-item>
            <v-tab-item
                    :key="$t('project.participant.detail.attachmentTabs.attachment2')"
                    v-if="hasAttachment('termoPagamento')"
            >
                <v-layout style="margin: 10px; display: block" v-if="isPDF(slugs[1])">
                    <div>
                        <embed :src="slugs[1]" width="100%" height="572px" type="application/pdf"/>
                    </div>
                </v-layout>
                <v-layout style="margin: 281px; display: block" v-else>
                    <v-flex xs12 class="text-md-center">
                        <v-btn
                                color="green"
                                dark
                                @click="downloadAttachment('termoPagamento')"
                        >{{$t('project.participant.detail.downloadAttachment')}}
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-tab-item>
            <v-tab-item
                    :key="$t('project.participant.detail.attachmentTabs.attachment3')"
                    v-if="hasAttachment('proposta')"
            >
                <v-layout style="margin: 281px; display: block">
                    <v-flex xs12 class="text-md-center">
                        <v-btn
                                color="green"
                                dark
                                @click="downloadAttachment('proposta')"
                        >{{$t('project.participant.detail.downloadAttachment')}}
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-tab-item>
            <v-tab-item
                    :key="$t('project.participant.detail.attachmentTabs.attachment4')"
                    v-if="hasAttachment('fotos')"
            >
                <v-layout style="margin: 10px; display: block" v-if="isPDF(slugs[3])">
                    <div>
                        <embed :src="slugs[3]" width="100%" height="572px" type="application/pdf"/>
                    </div>
                </v-layout>
                <v-layout style="margin: 281px; display: block" v-else>
                    <v-flex xs12 class="text-md-center">
                        <v-btn
                                color="green"
                                dark
                                @click="downloadAttachment('fotos')"
                        >{{$t('project.participant.detail.downloadAttachment')}}
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-tab-item>
        </v-tabs>
        <div
                class="accept-terms-slot"
                v-if="areTermsAgreed"
        >{{$t('project.participant.detail.termsAgreed')}}
        </div>
        <div class="area-inferior text-xs-center" v-if="!areTermsAgreed">
            <v-layout>
                <v-flex xs6 class="text-xs-right" v-if="!areTermsAgreed">
                    <v-radio-group v-model="agreedInput" style="margin-left: 50%; margin-top: 0 !important">
                        <v-radio :label="$t('project.participant.detail.agreeToTerms')" :value="true"></v-radio>
                    </v-radio-group>
                </v-flex>
                <div style="border-right: 1px solid #ccc; height: 30px; margin: 10px 0"></div>
                <v-flex
                        xs6
                        class="text-xs-left"
                        v-if="!areTermsAgreed"
                        style="margin-left: 10px !important;"
                >
                    <v-btn color="green" rounded depressed dark @click="participateProjectAndAgreeToTerms()">
                        {{$t('project.participant.detail.nextStep')}}
                        <v-icon color="white">chevron_right</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
        </div>
    </v-container>
</template>

<script>
    import _ from "lodash";
    import {actionTypes} from "@/commons/constants";
    import {mapState} from "vuex";

    export default {
        name: "detail-project-terms",
        data() {
            return {
                agreedInput: false,
                slugs: []
            };
        },
        computed: {
            areTermsAgreed() {
                if (!this.project.participants) return false;
                const participantIdx = this.project.participants.findIndex(
                    p => p.companyId === this.company._id
                );
                if (
                    participantIdx !== -1 &&
                    this.project.participants[participantIdx].termsAndConditions
                ) {
                    return true;
                }
                return false;
            },
            amIDropout() {
                if (!this.project.dropouts) return false;
                const dropoutIdx = this.project.dropouts.findIndex(
                    p => p.companyId === this.company._id
                );
                return dropoutIdx !== -1;
            },
            tabs() {
                const tabsDefault = [];
                if (this.hasAttachment('edital')) {
                    tabsDefault.push(this.$t("project.participant.detail.attachmentTabs.attachment1"));
                }
                if (this.hasAttachment('termoPagamento')) {
                    tabsDefault.push(this.$t("project.participant.detail.attachmentTabs.attachment2"));
                }
                if (this.hasAttachment('proposta')) {
                    tabsDefault.push(this.$t("project.participant.detail.attachmentTabs.attachment3"));
                }
                if (this.hasAttachment('fotos')) {
                    tabsDefault.push(this.$t("project.participant.detail.attachmentTabs.attachment4"));
                }
                return tabsDefault;
            },
            ...mapState(["project", "company"])
        },
        watch: {
            project() {
                this.buildReadFileAllLinks();
            }
        },
        methods: {
            async findProjectById() {
                try {
                    await this.$store.dispatch(
                        actionTypes.FIND_PROJECT_BY_ID,
                        this.$route.params.id
                    );
                } catch (e) {
                    this.showErrorFrontEnd(
                        "project.participant.detail.errors.projectNotFound"
                    );
                }
            },
            async participateProjectAndAgreeToTerms() {
                if (!this.agreedInput) {
                    this.showErrorFrontEnd(
                        "project.participant.detail.errors.selectAgreedInput"
                    );
                    return;
                }
                if (this.amIDropout) {
                    this.showErrorFrontEnd(
                        "project.participant.detail.errors.droppedOutProject"
                    );
                    return;
                }
                const project = _.clone(this.project);
                const participant = {
                    companyId: this.company._id,
                    termsAndConditions: {date: new Date(), agreed: true}
                };
                await this.$store.dispatch(actionTypes.UPDATE_PARTICIPANT, {
                    projectId: project._id,
                    companyId: participant.companyId,
                    participant: participant
                });
                await this.findProjectById();
                this.showSuccess();
                this.setAgreedTerms();
                this.$emit("checkAndUpdatePhases");
            },
            getTermSlug(type) {
                let slug = "";
                this.project.termsAndConditions.forEach(term => {
                    if (term.type === type) {
                        slug = term.slug;
                    }
                });
                return slug;
            },
            buildReadFileLink(slugTerm) {
                let downloadLink = this.buildDownloadLink(slugTerm);
                return downloadLink.replace("/true", "");
            },
            buildReadFileAllLinks() {
                this.slugs[0] = this.buildReadFileLink(this.getTermSlug("edital"));
                this.slugs[1] = this.buildReadFileLink(
                    this.getTermSlug("termoPagamento")
                );
                this.slugs[2] = this.buildReadFileLink(this.getTermSlug("proposta"));
                this.slugs[3] = this.buildReadFileLink(this.getTermSlug("fotos"));
            },
            setAgreedTerms() {
                this.agreedInput = this.areTermsAgreed;
            },
            downloadAttachment(termType) {
                let a = window.document.createElement("a");
                a.href = this.buildDownloadLink(this.getTermSlug(termType));
                a.target = "_blank";
                window.document.body.appendChild(a);
                a.click();
            },
            isPDF(termSlug) {
                return (
                    termSlug.match(/(-pdf-)/) !== null &&
                    termSlug.match(/(-pdf-)/).length > 0
                );
            },
            hasAttachment(type) {
                return this.project.termsAndConditions.find(termsIterator => {
                    return termsIterator.type === type;
                });
            }
        },
        beforeMount() {
            this.setAgreedTerms();
            this.buildReadFileAllLinks();
        }
    };
</script>

<style lang="stylus">
    .fa-detail-project-terms {
        .az-tabs {
            margin: 10px !important;
        }

        .accept-terms-slot {
            padding: 10px;
            display: flex;
            justify-content: center;
            font-size: 16px;
            font-weight: bold;
            color: #81C784;
            border-top: 1px solid #ddd;
        }
    }
</style>
