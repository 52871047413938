<template>
    <div>
        <az-toolbar>
            <az-call-to-action style="height: 35px" active slot="actions" @click="addUser">
                <v-icon size="18px">add_circle</v-icon>
                {{ $t('users.search.actions.add') }}
            </az-call-to-action>
            <az-call-to-action
                    style="height: 30px; margin-left: 12px;"
                    active slot="actions"
                    @click="inviteUsers"
                    v-show="canIShowInviteOption">
                <v-icon>email</v-icon>
                {{ $t('users.search.actions.invite') }}
            </az-call-to-action>
            <az-search-item slot="simpleSearch">
                <v-select
                        single-line
                        persistent-hint
                        item-text="text"
                        item-value="value"
                        v-model="selectedSearchFilter"
                        :label="$t('users.search.filters.source.label')"
                        :items="selectSearchItems"
                        @change="selectSearchEvent">
                </v-select>
            </az-search-item>
            <az-search
                    slot="simpleSearch"
                    style="font-size: 14px"
                    :filter="activeFilters"
                    @simple-search="simpleSearchEvent"
                    @remove-filter="removeFilterEvent"
                    :simple-search-placeholder="$t('users.search.searchPlaceholder')">
            </az-search>
        </az-toolbar>
        <user-search-table
                ref="userSearchTableRef"
                :itens="users"
                :pages="pages"
                :total-itens="totalItens"
                :pagination="userSearch.pagination"
                :enableMultipleSelect="canIShowInviteOption"
                @paginate="paginateEvent"
                @view="viewUser"
                @edit="editUser"
                @delete="deleteUser"
                @resetPassword="resetPassword"
                v-if="hasUsers"/>
        <az-ops
                :title="$t('users.search.noUsersFound.title')"
                :message="$t('users.search.noUsersFound.message')"
                v-else/>
        <modal-view
                :openModal="openResetPasswordModal"
                :title="$t('users.search.modals.resetPassword.title')"
                :textPrimary="$t('users.search.modals.resetPassword.text1')"
                :textSecondary="$t('users.search.modals.resetPassword.text2')"
                :argument="userPasswordReseted.password"
                :acceptText="$t('users.search.modals.resetPassword.options.accept')"
                @accept="hideResetPasswordModal"/>
    </div>
</template>

<script>
    import _ from 'lodash'
    import {mapActions, mapMutations, mapState} from 'vuex'
    import {filters} from '@azinformatica/loki'
    import i18n from '@/commons/i18n'
    import {actionTypes, mutationTypes} from '@/commons/constants'
    import UserSearchTable from './UserSearchTable'
    import ModalView from '@/views/pages/commons/ModalView'

    export default {
        components: {UserSearchTable, ModalView},
        name: 'user-search',
        data() {
            return {
                internalFilters: _.cloneDeep(this.$store.state.userSearch.filters),
                pages: 0,
                users: undefined,
                selectedSearchFilter: {text: 'Todos', value: ''},
                selectSearchItems: [
                    {text: 'Todos', value: ''},
                    {text: 'Convidados', value: 'invite'},
                    {text: 'Não convidados', value: 'migration'}
                ],
                totalItens: 0,
                openResetPasswordModal: false,
                userPasswordReseted: {}
            }
        },
        computed: {
            ...mapState(['userSearch']),
            activeFilters() {
                let allFilters = _.cloneDeep(this.userSearch.filters)
                allFilters = _.pick(allFilters, _.keys({nameEmail: null}))

                const activeFilters = {}
                for (let [key, val] of Object.entries(allFilters)) {
                    if (allFilters[key].value) {
                        val.valueTruncated = filters.azClipText(val.value, 10)
                        activeFilters[key] = val
                    }
                }
                return activeFilters
            },
            hasUsers() {
                return this.users !== undefined && this.users.length > 0
            },
            canIShowInviteOption() {
                const source = this.internalFilters.source
                if (source) {
                    const isInviteFilterSelected = source.value === 'invite'
                    const isMigrationFilterSelected = source.value === 'migration'
                    return isInviteFilterSelected || isMigrationFilterSelected
                }
                return false
            }
        },
        async mounted() {
            await this.executeSearch()
            this.openAsideMenu()
        },
        beforeDestroy() {
            this.cleanUserSearchFilters()
        },
        methods: {
            ...mapActions([actionTypes.FIND_USERS]),
            ...mapMutations([
                mutationTypes.CLEAN_USER_SEARCH_FILTERS,
                mutationTypes.SET_USER_SEARCH_FILTERS,
                mutationTypes.SET_USER_SEARCH_PAGINATION
            ]),
            addUser() {
                this.$router.push({name: 'addUser'})
            },
            async executeSearch() {
                this.setUserSearchFilters(this.internalFilters)
                this.setLoadingMessage(i18n.t('users.search.loadingUsers'))
                const result = await this.findUsers()
                this.users = result.content
                this.pages = result.pages
                this.totalItens = result.totalElements
            },
            paginateEvent(pagination) {
                this.setUserSearchPagination(pagination)
                this.executeSearch()
            },
            removeFilterEvent() {
                this.cleanUserSearchFilters()
                this.internalFilters = _.cloneDeep(this.userSearch.filters)
                this.selectSearchEvent(this.selectedSearchFilter)
            },
            simpleSearchEvent(value) {
                this.internalFilters.nameEmail = {
                    value: value,
                    label: i18n.t('users.search.filters.nameEmail.label')
                }
                this.resetSelectedUsers()
                this.executeSearch()
            },
            editUser(id) {
                this.$router.push({name: 'editUser', params: {id}})
            },
            viewUser(id) {
                this.$router.push({name: 'viewUser', params: {id}})
            },
            async deleteUser(id) {
                await this.$store.dispatch(actionTypes.REMOVE_USER, id)
                await this.executeSearch()
                this.resetSelectedUsers()
                this.showSuccess()
            },
            async resetPassword(id) {
                this.userPasswordReseted = await this.$store.dispatch(actionTypes.RESET_USER_PASSWORD, id)
                this.showSuccess()
                this.showResetPasswordModal()
            },
            showResetPasswordModal() {
                this.openResetPasswordModal = true
            },
            async hideResetPasswordModal() {
                this.userPasswordReseted = {}
                this.openResetPasswordModal = false
                await this.executeSearch()
            },
            async selectSearchEvent(value) {
                this.internalFilters.source = {
                    value: value,
                    label: i18n.t('users.search.filters.source.label')
                }
                await this.executeSearch()
                this.resetSelectedUsers()
            },
            resetSelectedUsers() {
                if (this.$refs.userSearchTableRef) {
                    this.$refs.userSearchTableRef.resetSelectedUsers()
                }
            },
            getSelectedUsers() {
                let selectedUsers = []
                if (this.$refs.userSearchTableRef) {
                    selectedUsers = this.$refs.userSearchTableRef.getSelectedUsers()
                }

                return selectedUsers
            },
            getSelectedUserIds() {
                const selectedUsers = this.getSelectedUsers()
                let selectedUserIds = []
                if (this.isThereAnySelectedUser(selectedUsers)) {
                    selectedUserIds = selectedUsers.map(item => item.user._id)
                }

                return selectedUserIds
            },
            isThereAnySelectedUser(selectedUsers) {
                return selectedUsers.length > 0
            },
            async inviteUsers() {
                const ids = this.getSelectedUserIds()
                if (!this.isThereAnySelectedUser(ids)) {
                    return
                }

                await this.$store.dispatch(actionTypes.REINVITE_USERS, ids)
                await this.executeSearch()
                this.resetSelectedUsers()
                this.showSuccess()
            }
        }
    }
</script>

<style lang="stylus">
    .az-toolbar
        height auto
        padding 8px 16px

        .actions
            padding 0

        .search
            position initial
            top 0

    .az-search
        .input-search
            width 220px !important

        .az-search-items
            .v-input__control
                width 140px

    @media screen and (max-width: 870px)
        .az-toolbar
            .search
                display flex
                flex-direction column
                padding-right 0

                .az-search-items
                    .v-text-field
                        padding-top 0
                        margin-top 0
</style>
