export default {
    cities: [],
    company: {},
    companySearch: {
        pagination: {page: 1, rowsPerPage: 10, sortBy: 'name', descending: true},
        filters: {
            nameDoc: {value: null, default: null, label: 'Name or Doc.'}
        }
    },
    customerSearch: {
        pagination: {page: 1, rowsPerPage: 10, sortBy: 'name', descending: true},
        filters: {
            nameDoc: {value: null, default: null, label: 'Name or Doc.'}
        }
    },
    defaultLoadingMessage: 'Carregando...',
    loading: false,
    token: '',
    loggedUser: '',
    logEmailSearch: {
        pagination: {page: 1, rowsPerPage: 10, sortBy: 'createdAt', descending: true},
        filters: {
            searchedText: {value: null, default: null, label: 'Subject or Content'}
        }
    },
    project: {
        calendar: {},
        categories: [],
        locations: [],
        termsAndConditions: []
    },
    projectSearch: {
        pagination: {page: 1, rowsPerPage: 10, sortBy: 'createdAt', descending: true},
        filters: {
            nameCustomer: {value: null, default: null, label: 'Name'},
            status: {value: null, default: null, label: 'Status'},
            companyId: {value: null, default: null, label: 'Participant'}
        }
    },
    userSearch: {
        pagination: {page: 1, rowsPerPage: 10, sortBy: 'name', descending: true},
        filters: {
            nameEmail: {value: null, default: null, label: 'Name or E-mail'},
            source: {value: null, default: null, label: 'Source'}
        }
    }
}
