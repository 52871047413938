<template>
    <div class="text-xs-center text-sm-center text-md-center text-lg-center">
        <v-divider light></v-divider>
        <p class="grey--text text--darken-1 body-2 mt-3 font-weight-regular">
            {{ $t('login.inviteToRegister')}}
            <router-link class="grey--text text--darken-3 font-weight-bold" to="register">{{ $t('login.register')}}</router-link>
        </p>
        <p class="grey--text text--darken-1 caption mt-3 font-weight-regular ">
            {{ $t('login.inviteToAgreeTerms')}}
            <a target="_blank" class="grey--text text--darken-1"
               href="https://faciles.com.br/politica-de-privacidade/">{{ $t('login.termsAndPolicy')}}</a>
        </p>
        <br>
    </div>
</template>
<script>
    export default {
        name: 'login-footer'
    }
</script>
