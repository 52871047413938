<template>
    <div>
        <az-container>
            <az-form>
                <v-data-table
                        :headers="columns"
                        :items="itens"
                        :no-data-text="$t('customer.search.noProjectsFound')"
                        class="az-table-list tabela-usuarios"
                        hide-default-footer
                        disable-pagination>
                    <template v-slot:item="props">
                        <tr class="clickable" @click="$emit('edit',props.item._id)">
                            <td>{{ props.item.name }}</td>
                            <td>{{ props.item.document | mask-filter($t('customer.edit.form.fields.document.mask'))}}
                            </td>
                            <td>{{ props.item.updatedAt | azDate('DD/MM/YYYY HH:mm') }}</td>
                            <td class="text-xs-right" @click.stop="">
                                <v-menu bottom left>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" dark icon text depressed color="primary">
                                            <v-icon>more_vert</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item @click.prevent.stop="$emit('view',props.item._id)">
                                            <v-list-item-title>
                                                <v-icon size="18px" class="mr-1">search</v-icon>
                                                {{ $t('customer.search.actions.view') }}
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="$emit('edit',props.item._id)">
                                            <v-list-item-title>
                                                <v-icon size="18px" class="mr-1">edit</v-icon>
                                                {{ $t('customer.search.actions.edit') }}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
                <div class="az-pagination">
                    <v-pagination v-model="internalPagination.page" :length="pages"/>
                    <div class="az-select-pagination">
                        <span>{{ $t('customer.search.rowsPerPage') }}</span>
                        <v-select :items="rowsPerPage" v-model="internalPagination.rowsPerPage"/>
                    </div>
                </div>
            </az-form>
        </az-container>
    </div>
</template>

<script>
    import i18n from '@/commons/i18n'

    export default {
        name: 'customer-search-table',
        props: ['itens', 'pagination', 'pages', 'totalItens'],
        data() {
            return {
                columns: [
                    {
                        text: i18n.t('customer.search.tableColumns.name'),
                        align: 'left',
                        sortable: false,
                        width: '50%',
                        class: 'primary--text'
                    },
                    {
                        text: i18n.t('customer.search.tableColumns.document'),
                        align: 'left',
                        sortable: false,
                        width: '20%',
                        class: 'primary--text'
                    },
                    {
                        text: i18n.t('customer.search.tableColumns.updatedAt'),
                        align: 'left',
                        sortable: false,
                        width: '20%',
                        class: 'primary--text'
                    },
                    {
                        text: i18n.t('customer.search.tableColumns.actions'),
                        align: 'right',
                        sortable: false,
                        width: '1%',
                        class: 'primary--text'
                    }
                ],
                internalPagination: this.pagination,
                rowsPerPage: [5, 10, 25, 50, 100]
            }
        },
        watch: {
            'internalPagination.page': {
                handler() {
                    this.$emit('paginate', this.internalPagination)
                }
            },
            'internalPagination.rowsPerPage': {
                handler() {
                    this.internalPagination.page = 1
                    this.$emit('paginate', this.internalPagination)
                }
            }
        }
    }
</script>

<style lang="stylus">

    .v-data-table__wrapper
        color #777
</style>