<template>
    <v-app id="container">
        <az-alert></az-alert>
        <v-content>
            <v-container fluid fill-height-custom>
                <v-layout align-center justify-center>
                    <v-flex xs12 sm8 md4>
                        <slot></slot>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-content>
    </v-app>
</template>

<script>
    export default {

    }
</script>

<style lang="stylus">
    .fill-height-custom
        min-height 100%
</style>
