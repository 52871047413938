<template>
    <div color="white">
        <div class="az-form-content">
            <v-container fluid grid-list-lg>
                <general-data-form ref="generalRef" v-model="localProject" :editing="editing"/>
                <div class="az-actions-form" v-if="editing">
                    <div class="align-left"></div>
                    <div class="align-right">
                        <button class="action-secundary mr-2" @click.prevent="cancel">
                            {{$t('project.admin.edit.cancel')}}
                        </button>
                        <button class="action-primary" @click.prevent="update">
                            {{$t('project.admin.edit.save')}}
                        </button>
                    </div>
                </div>
            </v-container>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'
    import {mapState} from 'vuex'
    import {actionTypes, mutationTypes} from '@/commons/constants'
    import GeneralDataForm from './GeneralDataForm'

    export default {
        name: 'edit-project-general',
        components: {GeneralDataForm},
        data() {
            return {
                editing: false,
                localProject: {
                    calendar: {},
                    categories: [],
                    locations: [],
                    termsAndConditions: []
                }
            }
        },
        computed: {
            ...mapState(['project'])
        },
        watch: {
            'project'() {
                this.localProject = _.clone(this.project)
                if (this.project.status === 'FINISHED') {
                    this.setViewMode(false)
                }
            }
        },
        async mounted() {
            this.setViewMode(`${this.$route.query.editing}` === 'true')
            this.cleanProjectData()
            await this.findProjectById()
        },
        methods: {
            cancel() {
                this.$router.push({name: 'projects'})
            },
            cleanProjectData() {
                this.$store.commit(mutationTypes.SET_PROJECT, {
                    calendar: {},
                    categories: [],
                    locations: [],
                    termsAndConditions: []
                })
            },
            async findProjectById() {
                try {
                    await this.$store.dispatch(actionTypes.FIND_PROJECT_BY_ID, this.$route.params.id)
                } catch (e) {
                    this.showErrorFrontEnd('project.admin.edit.errors.projectNotFound')
                    this.$router.push({name: 'home'})
                }
            },
            getLocationsIdList(locations) {
                const locationsIdList = []
                locations.forEach(location => locationsIdList.push(location._id))
                return locationsIdList
            },
            hasCategories() {
                if (this.project.categories.length) {
                    return true
                }
                this.showErrorFrontEnd('project.admin.edit.errors.categoriesNotInformed')
                return false
            },
            hasLocations() {
                if (this.project.locations.length) {
                    return true
                }
                this.showErrorFrontEnd('project.admin.edit.errors.locationsNotInformed')
                return false

            },
            preparePayload() {
                return {
                    name: this.localProject.name,
                    categories: this.localProject.categories,
                    locations: this.getLocationsIdList(this.localProject.locations),
                    description: this.localProject.description,
                    calendar: this.localProject.calendar
                }
            },
            setViewMode(editing) {
                this.editing = editing
            },
            async update() {
                const isFormValid = await this.$validator._base.validateAll('general')
                if (!isFormValid || !this.hasCategories() || !this.hasLocations()) {
                    return
                }
                const payload = this.preparePayload()
                await this.$store.dispatch(actionTypes.UPDATE_PROJECT, {
                    id: this.$route.params.id,
                    project: payload
                })
                await this.findProjectById()
                this.showSuccess()
            }
        }
    }
</script>