<template>
    <div>
        <v-checkbox
                :label="$t('users.edit.form.fields.isAdmin.label')"
                v-model="value.isAdmin"
                color="primary"
                v-if="editing"
        ></v-checkbox>
        <v-checkbox
                :label="$t('users.edit.form.fields.isAdmin.label')"
                v-model="value.isAdmin"
                color="primary"
                disabled
                v-else
        ></v-checkbox>
    </div>
</template>
<script>
    export default {
        name: 'user-form-admin',
        props: {
            editing: {
                type: Boolean,
                default: true
            },
            value: {}
        }
    }
</script>
