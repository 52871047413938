import Vue from 'vue'
import Router from 'vue-router'
import i18n from '@/commons/i18n'
import store from '@/commons/store'
import {mutationTypes} from '@/commons/constants'
import external from './routes/external'
import participant from './routes/participant'
import admin from './routes/admin'

import InternalPage from '@/views/pages/InternalPage'
import conditions from './conditions'

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/:lang',
            component: InternalPage,
            beforeEnter(to, from, next) {
                const lang = to.params.lang

                if (!['pt', 'en'].includes(lang)) {
                    return next({path: 'pt', params: to.params, query: to.query})
                }

                if (i18n.locale !== lang) {
                    i18n.locale = lang
                }
                return next()
            },
            children: [...admin, ...participant, ...external]
        },
        {
            path: '*',
            redirect() {
                return 'pt/projects'
            }
        }
    ]
})

router.beforeEach((to, from, next) => conditions(to, from, next))

router.afterEach((to) => afterNavigation(to))

function afterNavigation(to) {
    setTimeout(() => {
        const pageInfo = {
            meta: {
                page: {
                    title: i18n.t(`menu.${to.name}.title`),
                    subtitle: i18n.t(`menu.${to.name}.subtitle`)
                }
            }
        }
        store.commit(mutationTypes.LOKI.SET_CURRENT_PAGE, pageInfo)
    })
}

export default router
