export default class TextUtils {
    static removeSpecialCharacters(str) {
        return str.replace(/[^\w]/gi, '')
    }

    static unmask(model) {
        const props = Array.prototype.slice.call(arguments, 1)
        props.forEach(prop => {
            model[prop] = TextUtils.removeSpecialCharacters(model[prop])
        })
    }
}
