import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/commons/i18n'

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'md',
    },
    lang: {
        t: (key, ...params) => i18n.t(key, params)
    },
    theme: {
        themes: {
            light: {
                primary: '#153c5c',
                secondary: '#ed8732'
            }
        }
    },
    options: {
        customProperties: true
    }
})

