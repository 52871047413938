<template>
    <v-card-text>
        <v-container pa-0>
            <v-form v-on:submit.prevent="submitForm" autocomplete="off">
                <v-text-field prepend-icon="email" name="email" type="text" maxlength="255"
                              :data-vv-as="$t('login.email')"
                              :placeholder="$t('login.email')"
                              :error-messages="errors.collect('email')"
                              v-model="email"
                              v-validate="'required|email'"/>
                <v-text-field prepend-icon="lock" name="password" type="password" maxlength="20"
                              :data-vv-as="$t('login.password')"
                              :placeholder="$t('login.password')"
                              :error-messages="errors.collect('password')"
                              v-model="password"
                              v-validate="'required'"/>
                <v-layout row wrap justify-start>
                    <v-flex xs12 sm12 md12 lg12 class="text-xs-right text-sm-right text-md-right text-lg-right mr-4">
                        <router-link class="caption font-weight-regular grey--text text--darken-1" to="forget-password">{{ $t('login.forgetPassword') }}</router-link>
                    </v-flex>
                </v-layout>
                <v-btn color="success" class="mt-3" type="submit" block large
                       :loading="$store.state.loki.isLoading"
                       :disabled="(submitted && errors.any()) || $store.state.loki.isLoading"> {{ $t('login.login') }}
                </v-btn>
            </v-form>
        </v-container>
    </v-card-text>
</template>

<script>
    export default {
        name: 'login-form',
        data() {
            return {
                email: '',
                password: '',
                submitted: false
            }
        },
        methods: {
            async submitForm() {
                this.submitted = true
                const isValid = await this.$validator.validateAll()
                if (isValid) {
                    const credentials = {email: this.email, password: this.password}
                    this.$emit('submit', credentials)
                }
            }
        }
    }
</script>
