<template>
    <div>
        <az-back-button :route="{name: 'companies'}" :text="$t('company.edit.back')"/>
        <az-container>
            <div class="az-form-content">
                <company-form :editing="editing" :showAlert="false" v-model="company"/>
                <company-form-representatives-search :editing="editing" v-model="company"/>
                <company-form-representatives-table :loggedUser="loggedUser" :editing="editing" v-model="company"/>
                <company-actions @save="save" @cancel="cancel"/>
            </div>
        </az-container>
    </div>
</template>

<script>
    import {actionTypes, mutationTypes} from '@/commons/constants'
    import CompanyForm from '@/views/pages/commons/company/CompanyForm'
    import CompanyFormRepresentativesSearch from './components/CompanyFormRepresentativesSearch'
    import CompanyFormRepresentativesTable from '@/views/pages/commons/company/CompanyFormRepresentativesTable'
    import CompanyActions from '@/views/pages/commons/company/CompanyActions'

    export default {
        name: 'add-company-page',
        components: {
            CompanyForm,
            CompanyFormRepresentativesSearch,
            CompanyFormRepresentativesTable,
            CompanyActions
        },
        data() {
            return {
                company: {
                    name: '',
                    document: '',
                    logoSlug: '',
                    categories: [],
                    address: {
                        line1: '',
                        line2: '',
                        city: '',
                        state: '',
                        zipCode: '',
                        country: ''
                    },
                    representatives: []
                },
                editing: true,
                loggedUser: {}
            }
        },
        async mounted() {
            this.closeAsideMenu()
            this.getLoggedUser()
        },
        methods: {
            cancel() {
                this.goToCompaniesPage()
            },
            getLoggedUser() {
                this.loggedUser = this.$store.state.loggedUser
                this.loggedUser._id = this.loggedUser.id
            },
            async save() {
                if (await this.hasValidationErrors()) return
                try {
                    const companyCreated = await this.insertOnRemoteServer()
                    this.insertOnStore(companyCreated)
                    this.goToEditPage(companyCreated._id)
                    this.showSuccess()
                } catch (e) {
                    this.showError(e)
                }
            },
            async insertOnRemoteServer() {
                return this.$store.dispatch(actionTypes.CREATE_COMPANY, this.company)
            },
            async insertOnStore(companyCreated) {
                this.$store.commit(mutationTypes.SET_COMPANY, companyCreated)
            },
            goToEditPage(id) {
                this.$router.push({name: 'editCompany', params: {id}, query: {editing: true}})
            },
            goToCompaniesPage() {
                this.$router.push({name: 'companies'})
            },
            async hasValidationErrors() {
                return !(await this.$validator._base.validateAll())
            }
        }
    }
</script>
