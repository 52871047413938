<template>
    <div>
        <az-toolbar>
            <az-call-to-action class="subtitle-2" style="height: 30px; font-size: 14px" active slot="actions"
                               @click="openRemoveDialog">
                <v-icon size="18px">delete</v-icon>
                {{ $t('logEmails.search.actions.remove') }}
            </az-call-to-action>
            <az-search
                    slot="simpleSearch"
                    style="font-size: 14px"
                    :filter="activeFilters"
                    @simple-search="simpleSearchEvent"
                    @remove-filter="removeFilterEvent"
                    :simple-search-placeholder="$t('logEmails.search.searchPlaceholder')">
            </az-search>
        </az-toolbar>
        <log-email-search-table
                ref="emailSearchTableRef"
                :itens="logEmails"
                :pages="pages"
                :total-itens="totalItens"
                :pagination="logEmailSearch.pagination"
                v-if="hasLogEmails"
                @paginate="paginateEvent"
                @view="viewEmail"/>
        <az-ops
                :title="$t('logEmails.search.noLogEmailsFound.title')"
                :message="$t('logEmails.search.noLogEmailsFound.message')"
                v-else/>
        <log-email-detail
                :email="email"
                v-if="showEmailDetail"
                @close="closeEmailDetail"/>

        <dialog-view
                :openDialog="showRemoveDialog"
                :title="$t('logEmails.search.dialogs.remove.title')"
                :text="$t('logEmails.search.dialogs.remove.text')"
                :rejectText="$t('logEmails.search.dialogs.remove.options.reject')"
                :acceptText="$t('logEmails.search.dialogs.remove.options.accept')"
                @reject="hideRemoveDialog"
                @accept="removeEmails"/>
    </div>
</template>

<script>
    import _ from 'lodash'
    import {mapActions, mapMutations, mapState} from 'vuex'
    import {filters} from '@azinformatica/loki'
    import i18n from '@/commons/i18n'
    import {actionTypes, mutationTypes} from '@/commons/constants'
    import LogEmailSearchTable from './LogEmailSearchTable'
    import LogEmailDetail from './LogEmailDetail'
    import DialogView from '@/views/pages/commons/DialogView'

    export default {
        name: 'logEmail-search',
        components: {LogEmailDetail, LogEmailSearchTable, DialogView},
        data() {
            return {
                email: {},
                internalFilters: _.cloneDeep(this.$store.state.logEmailSearch.filters),
                pages: 0,
                logEmails: undefined,
                showEmailDetail: false,
                showRemoveDialog: false,
                totalItens: 0,
            }
        },
        computed: {
            ...mapState(['logEmailSearch']),
            activeFilters() {
                const allFilters = _.cloneDeep(this.logEmailSearch.filters)
                const activeFilters = {}
                for (let [key, val] of Object.entries(allFilters)) {
                    if (allFilters[key].value) {
                        val.valueTruncated = filters.azClipText(val.value, 10)
                        activeFilters[key] = val
                    }
                }
                return activeFilters
            },
            hasLogEmails() {
                return this.logEmails !== undefined && this.logEmails.length > 0
            }
        },
        async mounted() {
            await this.executeSearch()
            this.openAsideMenu()
        },
        methods: {
            ...mapActions([actionTypes.FIND_LOG_EMAIL]),
            ...mapMutations([
                mutationTypes.CLEAN_LOG_EMAIL_SEARCH_FILTERS,
                mutationTypes.SET_LOG_EMAIL_SEARCH_FILTERS,
                mutationTypes.SET_LOG_EMAIL_SEARCH_PAGINATION
            ]),
            closeEmailDetail() {
                this.showEmailDetail = false
            },
            async executeSearch() {
                this.setLogEmailSearchFilters(this.internalFilters)
                this.setLoadingMessage(i18n.t('logEmails.search.loadingLogEmails'))
                const result = await this.findLogEmails()
                this.logEmails = result.content
                this.pages = result.pages
                this.totalItens = result.totalElements
            },
            paginateEvent(pagination) {
                this.setLogEmailSearchPagination(pagination)
                this.executeSearch()
            },
            removeFilterEvent() {
                this.cleanLogEmailSearchFilters()
                this.internalFilters = _.cloneDeep(this.logEmailSearch.filters)
                this.executeSearch()
            },
            simpleSearchEvent(value) {
                this.internalFilters.searchedText = {
                    value: value,
                    label: i18n.t('logEmails.search.filterLabel')
                }
                this.executeSearch()
            },
            viewEmail(email) {
                this.email = email
                this.showEmailDetail = true
            },
            openRemoveDialog() {
                this.showRemoveDialog = true
            },
            hideRemoveDialog() {
                this.showRemoveDialog = false
            },
            resetSelectedEmails() {
                if (this.$refs.emailSearchTableRef) {
                    this.$refs.emailSearchTableRef.resetSelectedEmails()
                }
            },
            getSelectedEmails() {
                let selectedEmails = []
                if (this.$refs.emailSearchTableRef) {
                    selectedEmails = this.$refs.emailSearchTableRef.getSelectedEmails()
                }

                return selectedEmails
            },
            getSelectedEmailIds() {
                const selectedEmails = this.getSelectedEmails()
                let selectedEmailIds = []
                if (this.isThereAnySelectedEmail(selectedEmails)) {
                    selectedEmailIds = selectedEmails.map(email => email._id)
                }

                return selectedEmailIds
            },
            isThereAnySelectedEmail(selectedEmails) {
                return selectedEmails.length > 0
            },
            async removeEmails() {
                const ids = this.getSelectedEmailIds()
                if (!this.isThereAnySelectedEmail(ids)) {
                    return
                }

                await this.$store.dispatch(actionTypes.REMOVE_LOG_EMAILS, ids)
                await this.executeSearch()
                this.hideRemoveDialog()
                this.resetSelectedEmails()
                this.showSuccess()
            }
        }
    }
</script>
