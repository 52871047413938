export default {
    ADD_PARTICIPANT_TO_DROPOUTS: 'addParticipantToDropouts',
    ADD_TERMS_AND_CONDITIONS: 'addTermsAndConditions',
    ADD_TOKEN: 'addToken',
    AFTER_LOGIN: 'afterLogin',
    CONFIRM_EMAIL: 'confirmEmail',
    CONFIRM_USER_INVITED: 'confirmUserInvited',
    CREATE_COMPANY: 'createCompany',
    CREATE_CUSTOMER: 'createCustomer',
    CREATE_DOCUMENT: 'createDocument',
    CREATE_PROJECT: 'createProject',
    FIND_ALL_VISITORS: 'findAllVisitors',
    FIND_COMPANIES: 'findCompanies',
    FIND_COMPANY_BY_ID: 'findCompanyById',
    FIND_CUSTOMERS: 'findCustomers',
    FIND_CUSTOMER_BY_ID: 'findCustomerById',
    FIND_DOCUMENTS_BY_COMPANY_ID: 'findDocumentsByCompanyId',
    FIND_LATEST_VERSION_OF_DOCUMENTS: 'findLatestVersionOfDocuments',
    FIND_PROJECT_BY_ID: 'findProjectById',
    FIND_PROJECT_DROPOUTS: 'findProjectDropouts',
    FIND_PROJECT_PARTICIPANTS: 'findProjectParticipants',
    FIND_PROJECTS: 'findProjects',
    FIND_USER_BY_ID: 'findUserById',
    FIND_USERS: 'findUsers',
    FIND_REPRESENTATIVES_BY_COMPANY_ID: 'findRepresentativesByCompanyId',
    FIND_LOG_EMAIL: 'findLogEmails',
    FINISH_PROJECT: 'finishProject',
    FORGET_PASSWORD: 'forgetPassword',
    GET_COMPANY: 'getCompany',
    GET_USER: 'getUser',
    INSERT_USER: 'insertUser',
    INVITE_REPRESENTATIVE: 'inviteRepresentative',
    LOAD_CITIES: 'loadCities',
    LOGIN: 'login',
    LOGIN_FACEBOOK: 'loginFacebook',
    LOGOUT: 'logout',
    PUBLISH: 'publish',
    REGISTER: 'register',
    REINVITE_USERS: 'reinviteUsers',
    REMOVE_COMPANY: 'removeCompany',
    REMOVE_DOCUMENT: 'removeDocument',
    REMOVE_LOG_EMAILS: 'removeLogEmails',
    REMOVE_PARTICIPANT: 'removeParticipant',
    REMOVE_PROJECT: 'removeProject',
    REMOVE_TERM: 'removeTerm',
    REMOVE_TOKEN: 'removeToken',
    REMOVE_USER: 'removeUser',
    RESET_USER_PASSWORD: 'resetUserPassword',
    SEND_VISITORS_LIST_EMAIL: 'sendVisitorsListEmail',
    UNSUBSCRIBE_USER: 'unsubscribeUser',
    UPDATE_COMPANY: 'updateCompany',
    UPDATE_CUSTOMER: 'updateCustomer',
    UPDATE_DOCUMENT: 'updateDocument',
    UPDATE_DOCUMENT_STATUS: 'updateDocumentStatus',
    UPDATE_PARTICIPANT: 'updateParticipant',
    UPDATE_PARTICIPANT_STATUS: 'updateParticipantStatus',
    UPDATE_PASSWORD: 'updatePassword',
    UPDATE_PROJECT: 'updateProject',
    UPDATE_REQUIRED_DOCUMENTS: 'updateRequiredDocuments',
    UPDATE_USER: 'updateUser',
    VERIFY_TOKEN: 'verifyToken'
}
