<template>
    <v-card>
        <div class="primary" style="padding:10px 20px">
            <div class="white--text headline">
                {{$t('project.admin.edit.tabs.participants.visitors.title')}}
            </div>
            <div style="color: rgba(255,255,255,.5)">
                {{$t('project.admin.edit.tabs.participants.visitors.list.subtitle')}}
            </div>
        </div>
        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="visitors"
                :loading="false"
                hide-default-footer
                disable-pagination>
                <template v-slot:item="props">
                    <tr>
                        <td>{{ props.item.name }}</td>
                        <td>{{ props.item.document }}</td>
                        <td>{{ props.item.company }}</td>
                        <td>{{ props.item.phone | phone-filter(getPhoneMask()) }}</td>
                    </tr>
                </template>
            </v-data-table>
        </v-card-text>
        <v-card-actions>
            <v-btn color="primary" text @click="$emit('back')">
                {{$t('project.admin.edit.close')}}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="$emit('next')">
                {{$t('project.admin.edit.tabs.participants.visitors.actions.sendEmail')}}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: 'visitors-list',
        props: {
            visitors: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                headers: [
                    {
                        text: this.$t('project.admin.edit.tabs.participants.visitors.list.tableColumns.name'),
                        align: 'left',
                        sortable: false,
                        class: 'primary--text'
                    },
                    {
                        text: this.$t('project.admin.edit.tabs.participants.visitors.list.tableColumns.document'),
                        align: 'left',
                        sortable: false,
                        class: 'primary--text'
                    },
                    {
                        text: this.$t('project.admin.edit.tabs.participants.visitors.list.tableColumns.company'),
                        align: 'left',
                        sortable: false,
                        class: 'primary--text'
                    },
                    {
                        text: this.$t('project.admin.edit.tabs.participants.visitors.list.tableColumns.phone.label'),
                        align: 'left',
                        sortable: false,
                        class: 'primary--text'
                    }
                ]
            }
        },
        methods: {
            getPhoneMask() {
                return JSON.parse(this.$t('project.admin.edit.tabs.participants.visitors.list.tableColumns.phone.mask'))
            }
        }
    }
</script>