<template>
    <div class="mt-3 pb-3">
        <form @submit.prevent="update">
            <div class="az-form-content" v-if="editing">
                <v-flex xs12 sm12>
                    <az-file-upload
                        repository=""
                        height="130px"
                        ref="azFileUploadEdital"
                        @error="treatFileUploadError">
                        <a @click.prevent="$refs.azFileUploadEdital.openFileSelector()">
                            <v-icon class="pb-2">archive</v-icon>
                            {{$t('project.admin.edit.tabs.termsAndConditions.importFiles')}}
                        </a>
                        <p>{{$t('project.admin.edit.tabs.termsAndConditions.dragAndDropFilesHere')}}</p>
                    </az-file-upload>
                </v-flex>
            </div>
            <terms-and-conditions-table :terms="terms" :editing="editing" @remove="remove"/>
            <div class="az-actions-form" v-if="hasTermsAndConditions && editing">
                <div class="align-left">
                </div>
                <div class="align-right">
                    <button class="action-secundary mr-2" @click.prevent="cancel">
                        {{$t('project.admin.edit.cancel')}}
                    </button>
                    <button class="action-primary" type="submit">
                        {{$t('project.admin.edit.save')}}
                    </button>
                </div>
            </div>
        </form>
        <az-ops
            :title="$t('project.admin.edit.tabs.termsAndConditions.noTermsFound.title')"
            :message="$t('project.admin.edit.tabs.termsAndConditions.noTermsFound.message')"
            v-if="!editing && !hasTermsAndConditions"/>
        <az-confirm
            v-if="dialogRemove"
            :label-confirm="$t('confirmation.yes')"
            :label-decline="$t('confirmation.no')"
            @onConfirm="removeTermOnBackend"
            @onDecline="hideDialog">
            {{$t('project.admin.edit.tabs.termsAndConditions.confirmRemoveTerm')}}
        </az-confirm>
    </div>
</template>

<script>
    import _ from 'lodash'
    import {mapState} from 'vuex'
    import {actionTypes, mutationTypes} from '@/commons/constants'
    import TermsAndConditionsTable from './TermsAndConditionsTable'

    export default {
        components: {TermsAndConditionsTable},
        name: 'edit-project-terms-conditions',
        data() {
            return {
                dialogRemove: false,
                editing: false,
                terms: [],
                termToRemove: null
            }
        },
        computed: {
            ...mapState(['project', 'loki']),
            hasTermsAndConditions() {
                return this.terms.length
            }
        },
        watch: {
            'loki.uploadedFiles'() {
                const uploadedFiles = this.loki.uploadedFiles
                if (!uploadedFiles.length) {
                    return
                }
                const projectTerms = _.clone(this.terms)
                const newTerms = []
                for (let i = 0; i < uploadedFiles.length; i++) {
                    const file = uploadedFiles[i]
                    const term = {
                        name: file.originalname,
                        slug: file.filename
                    }
                    newTerms.push(term)
                }
                this.terms = projectTerms.concat(newTerms)
                this.resetUploadedFiles()
            },
            'project'() {
                if (this.project.status === 'FINISHED') {
                    this.setViewMode(false)
                }
            }
        },
        beforeRouteLeave(to, from, next) {
            this.resetUploadedFiles()
            next()
        },
        async mounted() {
            this.setViewMode(`${this.$route.query.editing}` === 'true')
            this.cleanProjectData()
            await this.findProjectById()
            this.terms = _.clone(this.project.termsAndConditions)
        },
        methods: {
            areValidTerms() {
                let valid = true
                for (let term of this.terms) {
                    if (!term.type) {
                        valid = false
                        break
                    }
                }
                return valid
            },
            cancel() {
                this.$router.push({name: 'projects'})
            },
            cleanProjectData() {
                this.$store.commit(mutationTypes.SET_PROJECT, {
                    calendar: {},
                    categories: [],
                    locations: [],
                    termsAndConditions: []
                })
            },
            confirmBeforeRemove() {
                this.dialogRemove = true
            },
            async findProjectById() {
                try {
                    await this.$store.dispatch(actionTypes.FIND_PROJECT_BY_ID, this.$route.params.id)
                    this.terms = this.project.termsAndConditions
                } catch (e) {
                    this.showErrorFrontEnd('project.admin.edit.errors.projectNotFound')
                    this.$router.push({name: 'home'})
                }
            },
            hideDialog() {
                this.dialogRemove = false
            },
            isDefinitiveTerm(term) {
                return term._id
            },
            remove(term) {
                if (this.isDefinitiveTerm(term)) {
                    this.termToRemove = term
                    this.confirmBeforeRemove()
                } else {
                    this.removeTermOnFrontend(term)
                }
            },
            async removeTermOnBackend() {
                this.hideDialog()
                await this.$store.dispatch(actionTypes.REMOVE_TERM, {
                    projectId: this.$route.params.id,
                    termId: this.termToRemove._id
                })
                await this.findProjectById()
            },
            removeTermOnFrontend(term) {
                const index = this.terms.findIndex(file => file.slug === term.slug)
                this.terms.splice(index, 1)
            },
            setViewMode(editing) {
                this.editing = editing
            },
            async update() {
                if (!this.areValidTerms()) {
                    this.showErrorFrontEnd('project.admin.edit.errors.termTypesNotInformed')
                    return
                }
                const terms = this.translateTermTypesToPortuguese(this.terms)
                await this.$store.dispatch(actionTypes.ADD_TERMS_AND_CONDITIONS, {
                    id: this.$route.params.id,
                    termsAndConditions: terms
                })
                await this.findProjectById()
                this.showSuccess()
                this.resetUploadedFiles()
            },
            translateTermTypesToPortuguese(terms) {
                terms.map(term => {
                    const key = `project.termsAndConditions['${term.type}']`
                    term.tye = this.$t(key, 'pt')
                })
                return terms
            }
        }
    }
</script>