<template>
    <options-picker
            :selected="selectedCategories"
            :options="translatedCategories"
            :editing="editing"
            :label="$t('company.edit.form.fields.categories.label')"
            display-field="text"
            @add="addCategory"
            @remove="removeCategory"/>
</template>
<script>
    import {categories} from '@/commons/constants'
    import OptionsPicker from '@/views/components/OptionsPicker'

    export default {
        name: 'company-form-categories',
        components: {
            OptionsPicker
        },
        props: {
            editing: {
                type: Boolean,
                default: true
            },
            value: {}
        },
        data() {
            return {
                selectedCategories: [],
                translatedCategories: []
            }
        },
        mounted() {
            this.translateCategories()
            this.translateSelectedCategories()
        },
        watch: {
            'value.categories'() {
                this.translateCategories()
                this.translateSelectedCategories()
            }
        },
        methods: {
            addCategory(category) {
                if (this.isDuplicatedCategory(category)) {
                    return
                }
                this.value.categories.push(category)
            },
            isDuplicatedCategory(category) {
                const existingCategory = this.value.categories.find(c => c === category)
                return existingCategory
            },
            removeCategory(category) {
                const index = this.value.categories.indexOf(category.value)
                this.value.categories.splice(index, 1)
            },
            translateCategories() {
                categories.map(category => {
                    const key = `project.categories.${category}`
                    this.translatedCategories.push({text: this.$t(key), value: category})
                })
            },
            translateSelectedCategories() {
                this.selectedCategories = []
                this.value.categories.map(category => {
                    const key = `project.categories.${category}`
                    this.selectedCategories.push({text: this.$t(key), value: category})
                })
            }
        }
    }
</script>
