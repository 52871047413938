<template>
    <v-flex xs12 class="pa-2">
        <v-card color="white" elevation="0" class="card-projeto ml-3 mr-3">
            <div class="logo">
                <v-img
                        height="60px"
                        width="60px"
                        :contain=true
                        :src="getSrcLogoCustomer()"
                >
                </v-img>
            </div>
            <div class="dados" @click="$emit('viewProject')">
                <div style="width: 50%; margin-top: 10px">
                    <div class="empresa">{{project.customer.name}}</div>
                    <div class="nome-projeto">{{project.name}}</div>
                </div>
                <div class="etapa" v-if="amIParticipating && actualProjectPhase">
                    <div class="label-etapa">{{$t('project.participant.search.phaseLabel')}}:</div>
                    <div class="nome-etapa">{{actualProjectPhase}}</div>
                </div>
                <div class="etapa-vencida" v-if="amIParticipating && projectPhaseExpired">
                    <div style="display: flex">
                        <v-icon color="red" size="18" class="mr-1">error</v-icon>
                        <div class="label-etapa">Etapa:</div>
                        <div class="nome-etapa"> {{projectPhaseExpired}}</div>
                    </div>
                    <div class="nome-etapa">
                        ({{$t('project.participant.search.finishedPhaseAt')}} {{projectPhaseExpiredDate | az-date}})
                    </div>
                </div>
            </div>
            <div class="acoes">
                <v-menu offset-y left>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="#aaa">more_vert</v-icon>
                    </template>
                    <v-list>
                        <v-list-item style="border-bottom: 1px solid #ddd" class="menu-acoes"
                                     @click="$emit('viewProject')">
                            <v-icon size="18" class="mr-1">search</v-icon>
                            <v-list-item-title>{{$t('project.participant.search.viewProjectLabel')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="menu-acoes" @click="$emit('giveUpProject')" v-if="amIParticipating">
                            <v-icon size="18" class="mr-1">delete</v-icon>
                            <v-list-item-title>{{$t('project.participant.search.giveUpProjectLabel')}}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </v-card>
    </v-flex>
</template>

<script>
    import moment from 'moment-timezone'
    import {mapState} from 'vuex'
    import {actionTypes} from '@/commons/constants'

    export default {
        name: "project-card",
        props: ['project'],
        data() {
            return {
                actualProjectPhase: null,
                projectPhaseExpired: null,
                projectPhaseExpiredDate: null
            }
        },
        computed: {
            amIParticipating() {
                if (!this.project.participants)
                    return false
                const participantIdx = this.project.participants.findIndex(p => p.companyId === this.company._id)
                return participantIdx !== -1;

            },
            ...mapState(['company'])
        },
        methods: {
            async checkCompletedOrExpiredPhase() {
                if (!this.amIParticipating)
                    return
                const participantIdx = this.project.participants.findIndex(p => p.companyId === this.company._id)
                if (this.checkPhase(participantIdx, 'termsAndConditions', 'kickoff') !== 'done')
                    return
                if (this.checkPhase(participantIdx, 'visitation', 'visitation') !== 'done')
                    return
                if (await this.checkDocumentationPhase() !== 'done')
                    return
                if (this.checkPhase(participantIdx, 'quotationSlug', 'additionalDocuments') !== 'done')
                    return
                if (this.checkPhase(participantIdx, 'negotiation', 'negociation') !== 'done')
                    return
                this.actualProjectPhase = this.$t('project.participant.search.phases.allDone')
            },
            checkPhase(participantIdx, phase, datePhase) {
                const now = moment()
                if (!this.project.participants[participantIdx][phase]) {
                    if (now.isAfter(this.project.calendar[datePhase])) {
                        this.projectPhaseExpired = this.$t('project.participant.search.phases.' + phase)
                        this.projectPhaseExpiredDate = this.project.calendar.kickoff
                        return 'expired'
                    }
                    this.actualProjectPhase = this.$t('project.participant.search.phases.' + phase)
                    return 'actual'
                }
                return 'done'
            },
            async checkDocumentationPhase() {
                const now = moment()
                const reqDocsUploaded = await this.requiredDocumentsHaveBeenUploaded()
                if (!reqDocsUploaded) {
                    if (now.isAfter(this.project.calendar.basicDocuments)) {
                        this.projectPhaseExpired = this.$t('project.participant.search.phases.documentation')
                        this.projectPhaseExpiredDate = this.project.calendar.basicDocuments
                        return 'expired'
                    }
                    this.actualProjectPhase = this.$t('project.participant.search.phases.documentation')
                    return 'actual'
                }
                return 'done'
            },
            async findDocumentsByCompanyId() {
                try {
                    return this.$store.dispatch(actionTypes.FIND_DOCUMENTS_BY_COMPANY_ID, this.company._id)
                } catch (e) {
                    this.showErrorFrontEnd('project.participant.documents.errors.findDocs')
                }
            },
            async requiredDocumentsHaveBeenUploaded() {
                const companyDocs = await this.findDocumentsByCompanyId()
                const mandatoryDocs = this.project.requiredDocuments.filter(doc => doc.mandatory)
                for (let i = 0; i < mandatoryDocs.length; i++) {
                    const docIdx = companyDocs.findIndex(d => d.type === mandatoryDocs[i].type)
                    if (docIdx === -1 || (!companyDocs[docIdx].exemption && companyDocs[docIdx].status !== 'APPROVED')) {
                        return false
                    }
                }
                return true
            },
            getSrcLogoCustomer() {
                if (this.project.customer)
                    return this.buildDownloadLink(this.project.customer.logoSlug)
                return ''
            }
        },
        mounted() {
            this.checkCompletedOrExpiredPhase()
        }
    }
</script>

<style lang="stylus">
    .menu-acoes:hover
        background-color #ddd
        cursor pointer

    .card-projeto
        display flex
        padding 20px
        height 99px

        .acoes
            margin-top 20px

        .logo
            width 60px
            height 60px
            margin-right 20px

        .dados
            width 100%
            display flex

            .empresa
                font-size 13px
                color #ccc

            .nome-projeto
                font-size 14px
                color #999
                font-weight bold

            .etapa
                margin-top 10px
                display flex
                width 50%

                .label-etapa
                    font-size 13px
                    color #ccc
                    font-weight bold
                    margin-right 5px

                .nome-etapa
                    font-size 13px
                    color #ccc

            .etapa-vencida
                margin-top 10px
                display block
                width 50%

                .label-etapa
                    font-size 13px
                    color #B71C1C
                    font-weight bold
                    margin-right 5px

                .nome-etapa
                    font-size 13px
                    color #B71C1C

    .card-projeto:hover
        cursor pointer
</style>
