import {mutationTypes} from '@/commons/constants'
import {geterror} from '@/commons/utils'
import i18n from '@/commons/i18n'

function translateErrorMessage(error) {
    let key = geterror(error)
    key = (key) ? 'exceptions.' + key : 'exceptions.ERRO_DEFAULT'
    return i18n.t(key)
}

function translateSuccessMessage(key) {
    if (!key) {
        key = 'exceptions.SUCESSO_DEFAULT'
    }
    return i18n.t(key)
}

export default {
    methods: {
        showError(error) {
            this.$store.commit(mutationTypes.LOKI.SHOW_ALERT, {
                message: translateErrorMessage(error),
                type: 'error'
            })
        },
        showErrorFrontEnd(key) {
            this.$store.commit(mutationTypes.LOKI.SHOW_ALERT, {
                message: i18n.t(key),
                type: 'error'
            })
        },
        showSuccess(key) {
            this.$store.commit(mutationTypes.LOKI.SHOW_ALERT, {
                message: translateSuccessMessage(key),
                type: 'success'
            })
        }
    }
}
