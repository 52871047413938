<template>
    <div>
        <div>
            <az-toolbar>
                <az-call-to-action style="font-size: 14px" active slot="actions" @click="addProject">
                    <v-icon small dense>add_circle</v-icon>
                    {{ $t('project.admin.search.actions.add') }}
                </az-call-to-action>
                <az-search slot="simpleSearch"
                           :filter="activeFilters"
                           style="font-size: 14px"
                           @simple-search="simpleSearchEvent"
                           @remove-filter="removeFilterEvent"
                           :simple-search-placeholder="$t('project.admin.search.searchPlaceholder')">
                </az-search>
            </az-toolbar>
        </div>
        <project-search-table :itens="projects"
                              :pages="pages"
                              :total-itens="totalItens"
                              :pagination="projectSearch.pagination"
                              @paginate="paginateEvent"
                              @view="viewProjectDetailEvent"
                              @edit="editProjectEvent"
                              @participants="manageParticipants"
                              v-if="hasProjects"/>
        <az-ops :title="$t('project.admin.search.noProjectsCreated.title')"
                :message="$t('project.admin.search.noProjectsCreated.message')" v-else/>
    </div>
</template>

<script>
    import _ from 'lodash'
    import {mapActions, mapMutations, mapState} from 'vuex'
    import {filters} from '@azinformatica/loki'
    import i18n from '@/commons/i18n'
    import {actionTypes, mutationTypes} from '@/commons/constants'
    import ProjectSearchTable from './ProjectSearchTable'

    export default {
        name: 'project-search',
        components: {ProjectSearchTable},
        data() {
            return {
                internalFilters: _.cloneDeep(this.$store.state.projectSearch.filters),
                pages: 0,
                projects: undefined,
                totalItens: 0
            }
        },
        computed: {
            ...mapState(['projectSearch']),
            activeFilters() {
                const allFilters = _.cloneDeep(this.projectSearch.filters)
                const activeFilters = {}
                for (let [key, val] of Object.entries(allFilters)) {
                    if (allFilters[key].value) {
                        val.valueTruncated = filters.azClipText(val.value, 10)
                        activeFilters[key] = val
                    }
                }
                return activeFilters
            },
            hasProjects() {
                return this.projects !== undefined && this.projects.length > 0
            }
        },
        async mounted() {
            await this.executeSearch()
            this.openAsideMenu()
        },
        methods: {
            ...mapActions([actionTypes.FIND_PROJECTS]),
            ...mapMutations([
                mutationTypes.CLEAN_PROJECT_SEARCH_FILTERS,
                mutationTypes.SET_PROJECT_SEARCH_FILTERS,
                mutationTypes.SET_PROJECT_SEARCH_PAGINATION
            ]),
            addProject() {
                this.$router.push({name: 'addProject'})
            },
            editProjectEvent(id) {
                this.$router.push({name: 'editProject', params: {id}, query: {editing: true}})
            },
            async executeSearch() {
                this.setProjectSearchFilters(this.internalFilters)
                this.setLoadingMessage(i18n.t('project.admin.search.loadingProjects'))
                const result = await this.findProjects()
                this.projects = result.content
                this.pages = result.pages
                this.totalItens = result.totalElements
            },
            manageParticipants(id) {
                this.$router.push({name: 'projectParticipants', params: {id}, query: {editing: true}})
            },
            paginateEvent(pagination) {
                this.setProjectSearchPagination(pagination)
                this.executeSearch()
            },
            removeFilterEvent() {
                this.cleanProjectSearchFilters()
                this.internalFilters = _.cloneDeep(this.projectSearch.filters)
                this.executeSearch()
            },
            simpleSearchEvent(value) {
                this.internalFilters.nameCustomer = {
                    value: value,
                    label: i18n.t('project.admin.search.filterLabel')
                }
                this.executeSearch()
            },
            viewProjectDetailEvent(id) {
                this.$router.push({name: 'editProject', params: {id}})
            }
        }
    }
</script>