<template>
    <profile-picture
            ref="profilePicture"
            height="150px"
            :slug="value.logoSlug"
            :editing="editing"
            @change="setLogoSlug">
        <div slot="upload-area">
            <v-icon large @click="$refs.profilePicture.openFileSelector()">
                business
            </v-icon>
            <p>{{$t('company.edit.form.fields.logo.placeholder')}}</p>
        </div>
    </profile-picture>
</template>

<script>
    import ProfilePicture from '@/views/components/ProfilePicture'

    export default {
        name: 'company-form-logo',
        components: {ProfilePicture},
        props: {
            editing: {
                type: Boolean,
                default: true
            },
            value: {}
        },
        methods: {
            setLogoSlug(slug) {
                this.value.logoSlug = slug
            }
        }
    }
</script>
