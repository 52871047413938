<template>
    <div>
        <az-back-button :route="{name: 'companies'}" :text="$t('company.edit.back')"/>
        <az-container>
            <div class="az-form-content">
                <company-form :editing="editing" :loggedUser="loggedUser" :showAlert="false" v-model="company"/>
                <company-form-representatives-search v-if="editing" :editing="editing" v-model="company"/>
                <company-form-representatives-table :loggedUser="loggedUser" :editing="editing" v-model="company"/>
                <company-actions v-if="editing" @save="save" @cancel="cancel"/>
            </div>
        </az-container>
    </div>
</template>

<script>
    import {actionTypes} from '@/commons/constants'
    import CompanyForm from '@/views/pages/commons/company/CompanyForm'
    import CompanyFormRepresentativesSearch from './components/CompanyFormRepresentativesSearch'
    import CompanyFormRepresentativesTable from '@/views/pages/commons/company/CompanyFormRepresentativesTable'
    import CompanyActions from '@/views/pages/commons/company/CompanyActions'

    export default {
        name: 'edit-company-page',
        components: {
            CompanyForm,
            CompanyFormRepresentativesSearch,
            CompanyFormRepresentativesTable,
            CompanyActions
        },
        data() {
            return {
                id: null,
                company: {
                    logoSlug: '',
                    representatives: [],
                    categories: [],
                    address: {
                        line1: '',
                        line2: '',
                        city: '',
                        state: '',
                        zipCode: '',
                        country: ''
                    }
                },
                editing: false,
                loggedUser: {}
            }
        },
        async mounted() {
            this.closeAsideMenu()
            this.getEditingStatusFromUrl()
            this.getLoggedUser()
            await this.getFromRemoteServer()
        },
        methods: {
            cancel() {
                this.goToCompaniesPage()
            },
            async getFromRemoteServer() {
                this.id = this.$route.params.id
                const foundCompany = await this.$store.dispatch(actionTypes.FIND_COMPANY_BY_ID, this.id)
                if (this.isLogoSlugUndefined(foundCompany)) {
                    this.setDefaultLogoSlug(foundCompany)
                }
                this.company = foundCompany
            },
            getLoggedUser() {
                this.loggedUser = this.$store.state.loggedUser
                this.loggedUser._id = this.loggedUser.id
            },
            goToCompaniesPage() {
                this.$router.push({name: 'companies'})
            },
            getEditingStatusFromUrl() {
                if (this.$route.query.editing) {
                    this.editing = Boolean(this.$route.query.editing)
                }                
            },
            isLogoSlugUndefined(foundCompany) {
                return foundCompany.logoSlug == undefined
            },
            async save() {
                if (await this.hasValidationErrors()) return
                try {
                    await this.updateOnRemoteServer()
                    await this.getFromRemoteServer()
                    this.showSuccess()
                } catch (e) {
                    this.showError(e)
                }
            },
            setDefaultLogoSlug(foundCompany) {
                foundCompany.logoSlug = ''
            },
            async hasValidationErrors() {
                return !(await this.$validator._base.validateAll())
            },
            async updateOnRemoteServer() {
                await this.$store.dispatch(actionTypes.UPDATE_COMPANY, {companyId: this.company._id, company: this.company})
            }
        }
    }
</script>
