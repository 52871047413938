<template>
    <az-container>
        <div class="az-form-content">
            <company-form :editing="editing" :loggedUser="loggedUser" :showAlert="false" v-model="company"/>
            <company-actions @save="save" @cancel="cancel"/>
        </div>
    </az-container>
</template>

<script>
    import {actionTypes} from '@/commons/constants'
    import CompanyForm from '@/views/pages/commons/company/CompanyForm'
    import CompanyActions from '@/views/pages/commons/company/CompanyActions'

    export default {
        name: 'edit-company-page',
        components: {
            CompanyForm,
            CompanyActions
        },
        data() {
            return {
                id: null,
                company: {
                    logoSlug: '',
                    representatives: [],
                    categories: [],
                    address: {
                        line1: '',
                        line2: '',
                        city: '',
                        state: '',
                        zipCode: '',
                        country: ''
                    }
                },
                editing: true,
                loggedUser: {}
            }
        },
        async mounted() {
            this.closeAsideMenu()
            this.getLoggedUser()
            await this.getFromRemoteServer()
        },
        methods: {
            cancel() {
                this.$router.push({name: 'participantMyProjects'})
            },
            async getFromRemoteServer() {
                this.id = this.$store.state.company._id
                const foundCompany = await this.$store.dispatch(actionTypes.FIND_COMPANY_BY_ID, this.id)
                if (this.isLogoSlugUndefined(foundCompany)) {
                    this.setDefaultLogoSlug(foundCompany)
                }
                this.company = foundCompany
            },
            getLoggedUser() {
                this.loggedUser = this.$store.state.loggedUser
                this.loggedUser._id = this.loggedUser.id
            },
            async hasValidationErrors() {
                return !(await this.$validator._base.validateAll())
            },
            isLogoSlugUndefined(foundCompany) {
                return foundCompany.logoSlug == undefined
            },
            async save() {
                if (await this.hasValidationErrors()) return
                try {
                    await this.updateOnRemoteServer()
                    await this.getFromRemoteServer()
                    this.showSuccess()
                } catch (e) {
                    this.showError(e)
                }
            },
            setDefaultLogoSlug(foundCompany) {
                foundCompany.logoSlug = ''
            },
            async updateOnRemoteServer() {
                await this.$store.dispatch(actionTypes.UPDATE_COMPANY, {companyId: this.company._id, company: this.company})
            }
        }
    }
</script>
