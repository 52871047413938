class NotLoggedIn {

    shouldExecute() {
        return !(sessionStorage.getItem('jwt') !== null)
    }

    execute(to, from, next) {
        next({name: 'login', params: to.params, query: to.query})
    }
}

export default new NotLoggedIn()
