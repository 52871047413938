import Vue from 'vue'
import booleanFilter from './boolean'
import maskFilter from './mask'
import projectStatusFilter from './project-status'
import phoneFilter from './phone'
import categories from './categories'
import locations from './locations'

Vue.filter('boolean-filter', booleanFilter)
Vue.filter('mask-filter', maskFilter)
Vue.filter('project-status', projectStatusFilter)
Vue.filter('phone-filter', phoneFilter)
Vue.filter('categoriesFilter', categories)
Vue.filter('locationsFilter', locations)

export default {}
