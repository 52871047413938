<template>
    <container>
        <v-card>
            <headerbar/>
            <continue-register-form v-model="form" @submit="register"/>
        </v-card>
    </container>
</template>

<script>
    import {actionTypes} from '@/commons/constants'
    import Container from '../../commons/Container'
    import Headerbar from '../../commons/Headerbar'
    import ContinueRegisterForm from './ContinueRegisterForm'
    import {query} from '@/commons/utils'
    import menuBuilder from '@/commons/utils/MenuBuilder'

    export default {
        components: {Container, Headerbar, ContinueRegisterForm},
        data() {
            return {
                form: {
                    name: '',
                    email: '',
                    phoneNumber1: '',
                    phoneNumber2: '',
                    source: 'confirmed',
                    password: '',
                    confirmation: '',
                    submitted: false
                },
                token: '',
                user: {}
            }
        },
        async created() {
            this.checkTokenExist()
            await this.findUserOnRemoteServer()
        },
        methods: {
            checkTokenExist() {
                this.token = query('userInvited')
                if (!this.token) {
                    this.showErrorFrontEnd('exceptions.UNSPECIFIED_TOKEN')
                    this.$router.push({name: 'login'})
                }
            },
            async findUserOnRemoteServer() {
                this.user = await this.$store.dispatch(actionTypes.VERIFY_TOKEN, this.token)
                this.completeUserForm()
            },
            completeUserForm() {
                this.form.name = this.user.name
                this.form.email = this.user.email
            },
            async register() {
                try {
                    await this.confirmUserInvitedOnRemoteServer()
                    this.setMenuActions()
                    this.redirectToHome()
                } catch (err) {
                    this.showError(err)
                }
            },
            async confirmUserInvitedOnRemoteServer() {
                const user = this.getUserModel()
                await this.$store.dispatch(actionTypes.CONFIRM_USER_INVITED, {id: this.user.id, user: user})
            },
            getUserModel() {
                const {name, email, phoneNumber1, phoneNumber2, source, password} = this.form
                const user = {name, email, phoneNumber1, phoneNumber2, source, password}
                return user
            },
            redirectToHome() {
                this.$router.push({name: 'participant'})
            },
            setMenuActions() {
                const lang = this.$route.params.lang
                menuBuilder.build(this.$store, this.$router, lang)
            }
        }
    }
</script>
