<template>
    <v-dialog :value=true persistent width="650">
        <v-container fluid grid-list-lg class="fa-general-info-project">
            <v-layout row wrap style="margin: 0 8px; padding: 8px;">
                <v-flex xs12>
                    <v-layout row wrap style="width:100%">
                        <div style="display:flex; width:100%">
                            <div class="ml-4" style="width:100%">
                                <v-flex xs12 class="text-md-left">
                                    <span class="headline font-weight-bold">{{project.name}}</span><br>
                                    <span>{{$t('project.participant.detail.publishedAt')}} {{project.publishedAt | azDate}}</span>
                                </v-flex>
                                <v-flex xs12 class="text-md-left">
                                    <div class="font-weight-bold">Situação</div>
                                    <span>Em Andamento</span>
                                </v-flex>
                                <v-flex xs12 class="text-md-left">
                                    <div class="font-weight-bold">{{$t('project.participant.detail.categories')}}</div>
                                    <span>{{project.categories | categoriesFilter}}</span>
                                </v-flex>
                                <v-flex xs12 class="text-md-left">
                                    <div class="font-weight-bold">{{$t('project.participant.detail.locations')}}</div>
                                    <span>{{project.locations | locationsFilter}}</span>
                                </v-flex>
                                <v-flex xs12 class="text-md-left">
                                    <div class="font-weight-bold">Descrição</div>
                                    <span>{{project.description}}</span>
                                </v-flex>
                            </div>
                            <div>
                                <v-img
                                        height="150px"
                                        width="150px"
                                        :contain=true
                                        :src="getSrcLogoCustomer()"
                                        style="margin: 5px"

                                >
                                </v-img>
                            </div>
                        </div>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-container>
        <div style="background-color: white; padding: 10px; border-top: 1px solid #eee" class="text-md-center">
            <v-btn color="primary" @click="$emit('hideDialog')">
                {{$t('project.participant.detail.closeInfoProject')}}
            </v-btn>
        </div>
    </v-dialog>
</template>

<script>
    export default {
        name: 'general-info-project-modal',
        props: ['project'],
        methods: {
            getSrcLogoCustomer() {
                if (this.project.customer)
                    return this.buildDownloadLink(this.project.customer.logoSlug)
                return ''
            }
        }
    }
</script>

<style lang="stylus">
    .fa-general-info-project
        .v-list__tile
            padding 0 !important

    .custom-primary-chip
        background: #153c5c
        border-radius: 8px
        text-align: center
        height: 21px
        width: 20%
        color: white
        font-size: 13px
        margin-top: 12px

</style>
