export default {
    isCitiesLoaded: state => state.cities.length > 0,
    getLocationOptions(state) {
        const locations = []
        for (let city of state.cities) {
            locations.push({text: `${city.name} - ${city.state}`, value: city})
        }
        return locations
    }
}
