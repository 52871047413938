import Vue from 'vue'
import RootPage from '@/views/pages/RootPage.vue'
import menuBuilder from '@/commons/utils/MenuBuilder'
import vuetify from '@/application/vendors/vuetify'
import router from '@/views/router'
import store from '@/commons/store'
import i18n from '@/commons/i18n'

class RootPageCreator {

    createInstance() {
        new Vue({
            i18n,
            vuetify,
            router,
            store,
            render: h => h(RootPage),
            created() {
                const lang = this.$route.params.lang
                menuBuilder.build(this.$store, this.$router, lang)
            }
        }).$mount('#app')
    }

    createBootstrapError() {
        new Vue({
            el: '#app',
            render(createElement) {
                return createElement('h2', 'Ocorreu um erro na inicialização desta aplicação.');
            }
        })
    }
}

export default new RootPageCreator()
