import ProjectSearch from '@/views/pages/internal/admin/project/search/ProjectSearch'
import AddProject from '@/views/pages/internal/admin/project/detail/general/AddProject'
import EditProject from '@/views/pages/internal/admin/project/detail/EditProject'
import EditProjectGeneral from '@/views/pages/internal/admin/project/detail/general/EditProjectGeneral'
import EditProjectTermsConditions from '@/views/pages/internal/admin/project/detail/terms/EditProjectTermsConditions'
import EditProjectDocuments from '@/views/pages/internal/admin/project/detail/documents/EditProjectDocuments'
import EditProjectParticipants from '@/views/pages/internal/admin/project/detail/participants/EditProjectParticipants'
import EditProjectDropouts from '@/views/pages/internal/admin/project/detail/dropouts/EditProjectDropouts'
import CustomerSearch from '@/views/pages/internal/admin/customer/search/CustomerSearch'
import AddCustomer from '@/views/pages/internal/admin/customer/detail/AddCustomer'
import EditCustomer from '@/views/pages/internal/admin/customer/detail/EditCustomer'
import CompanySearch from '@/views/pages/internal/admin/company/search/CompanySearch'
import AddCompany from '@/views/pages/internal/admin/company/AddCompanyPage'
import EditCompany from '@/views/pages/internal/admin/company/EditCompanyPage'
import UserSearch from '@/views/pages/internal/admin/user/search/UserSearch'
import UserAdd from '@/views/pages/internal/admin/user/detail/UserAdd'
import UserEdit from '@/views/pages/internal/admin/user/detail/UserEdit'
import UserView from '@/views/pages/internal/admin/user/detail/UserView'
import LogEmailSearch from '@/views/pages/internal/admin/logemail/search/LogEmailSearch'

export default [
    {
        path: '/',
        redirect: '/:lang/projects'
    },
    {
        path: '/:lang/projects',
        name: 'projects',
        component: ProjectSearch,
        meta: {
            menu: {
                title: 'Projetos',
                icon: 'folder_open'
            },
            page: {
                title: 'Projetos',
                subtitle: 'Lista geral dos projetos disponíveis'
            },
            requiresAuth: true,
            authorities: ['admin']
        }
    },
    {
        path: 'projects/add',
        name: 'addProject',
        component: AddProject,
        meta: {
            page: {
                title: 'Projeto',
                subtitle: 'Novo'
            },
            requiresAuth: true,
            authorities: ['admin']
        }
    },
    {
        path: 'projects/:id',
        name: 'editProject',
        component: EditProject,
        redirect: 'projects/:id/general',
        children: [
            {
                path: 'general',
                name: 'projectGeneralInfo',
                component: EditProjectGeneral,
                meta: {
                    page: {
                        title: 'Projeto',
                        subtitle: 'Dados Gerais do Projeto'
                    },
                    requiresAuth: true,
                    authorities: ['admin']
                }
            },
            {
                path: 'terms',
                name: 'projectTermsAndConditions',
                component: EditProjectTermsConditions,
                meta: {
                    page: {
                        title: 'Projeto',
                        subtitle: 'Editais e Termos do processo'
                    },
                    requiresAuth: true,
                    authorities: ['admin']
                }
            },
            {
                path: 'documents',
                name: 'projectDocuments',
                component: EditProjectDocuments,
                meta: {
                    page: {
                        title: 'Projeto',
                        subtitle: 'Documentação Requerida dos Participantes'
                    },
                    requiresAuth: true,
                    authorities: ['admin']
                }
            },
            {
                path: 'participants',
                name: 'projectParticipants',
                component: EditProjectParticipants,
                meta: {
                    page: {
                        title: 'Projeto',
                        subtitle: 'Lista dos participantes que enviaram proposta'
                    },
                    requiresAuth: true,
                    authorities: ['admin']
                }
            },
            {
                path: 'dropouts',
                name: 'projectDropouts',
                component: EditProjectDropouts,
                meta: {
                    page: {
                        title: 'Projeto',
                        subtitle: 'Lista de desistentes'
                    },
                    requiresAuth: true,
                    authorities: ['admin']
                }
            }
        ]
    },
    {
        path: '/:lang/customers',
        name: 'customers',
        component: CustomerSearch,
        meta: {
            menu: {
                title: 'Clientes',
                icon: 'group'
            },
            page: {
                title: 'Clientes',
                subtitle: 'Lista geral dos clientes'
            },
            requiresAuth: true,
            authorities: ['admin']
        }
    },
    {
        path: 'customers/add',
        name: 'addCustomer',
        component: AddCustomer,
        meta: {
            page: {
                title: 'Cliente',
                subtitle: 'Novo'
            },
            requiresAuth: true,
            authorities: ['admin']
        }
    },
    {
        path: 'customers/:id',
        name: 'editCustomer',
        component: EditCustomer,
        meta: {
            page: {
                title: 'Cliente',
                subtitle: 'Dados do cliente'
            },
            requiresAuth: true,
            authorities: ['admin']
        }
    },
    {
        path: '/:lang/companies',
        name: 'companies',
        component: CompanySearch,
        meta: {
            menu: {
                title: 'Compradores',
                icon: 'business'
            },
            page: {
                title: 'Compradores',
                subtitle: 'Lista geral dos compradores'
            },
            requiresAuth: true,
            authorities: ['admin']
        }
    },
    {
        path: 'companies/add',
        name: 'addCompany',
        component: AddCompany,
        meta: {
            page: {
                title: 'Comprador',
                subtitle: 'Novo'
            },
            requiresAuth: true,
            authorities: ['admin']
        }
    },
    {
        path: 'companies/:id',
        name: 'editCompany',
        component: EditCompany,
        meta: {
            page: {
                title: 'Comprador',
                subtitle: 'Dados do comprador'
            },
            requiresAuth: true,
            authorities: ['admin']
        }
    },
    {
        path: '/:lang/users',
        name: 'users',
        component: UserSearch,
        meta: {
            menu: {
                title: 'Usuários',
                icon: 'perm_identity'
            },
            page: {
                title: 'Usuários',
                subtitle: 'Lista geral dos usuários do sistema'
            },
            requiresAuth: true,
            authorities: ['admin']
        }
    },
    {
        path: '/:lang/users/add',
        name: 'addUser',
        component: UserAdd,
        meta: {
            page: {
                title: 'Usuário',
                subtitle: 'Incluindo novo usuário'
            },
            requiresAuth: true,
            authorities: ['admin']
        }
    },
    {
        path: '/:lang/users/edit/:id',
        name: 'editUser',
        component: UserEdit,
        meta: {
            page: {
                title: 'Usuário',
                subtitle: 'Alterando dados do usuário'
            },
            requiresAuth: true,
            authorities: ['admin']
        }
    },
    {
        path: '/:lang/users/view/:id',
        name: 'viewUser',
        component: UserView,
        meta: {
            page: {
                title: 'Usuário',
                subtitle: 'Visualizando dados do usuário'
            },
            requiresAuth: true,
            authorities: ['admin']
        }
    },
    {
        path: '/:lang/logemails',
        name: 'logemails',
        component: LogEmailSearch,
        meta: {
            menu: {
                title: 'Emails enviados',
                icon: 'email'
            },
            page: {
                title: 'Emails enviados',
                subtitle: 'Lista dos emails enviados pelo sistema'
            },
            requiresAuth: true,
            authorities: ['admin']
        }
    }

]
