<template>
    <div>
        <v-select
                :label="$t('users.edit.form.fields.source.label')"
                :hint="$t(`users.edit.form.fields.source.placeholder.${value.source}`)"
                :items="items"
                v-model="value.source"
                item-text="text"
                item-value="value"
                persistent-hint
                v-if="editing"
        ></v-select>
        <az-text-view
                :label="$t('users.edit.form.fields.source.label')"
                :text="$t(`users.edit.form.fields.source.placeholder.${value.source}`)"
                v-else/>
    </div>
</template>
<script>
    export default {
        name: 'user-form-source',
        data() {
            return {
                items: [
                    {text: 'Ativo', value: 'internal' },
                    {text: 'Convidado', value: 'invite' }
                ]
            }
        },
        props: {
            editing: {
                type: Boolean,
                default: true
            },
            value: {}
        }
    }
</script>
