<template>
    <div>
        <az-back-button :route="{name: 'projects'}" :text="$t('project.admin.edit.back')"></az-back-button>
        <az-container>
            <summary-bar/>
            <div class="az-tabs">
                <v-tabs left>
                    <v-tab active-class="secondary--text"
                           v-for="tab in tabs"
                           :key="tab.title"
                           :to="buildRoute(tab.route)">
                        {{tab.title}}
                    </v-tab>
                    <v-tab active-class="secondary--text"
                           :to="buildRoute('projectParticipants')"
                           v-show="$store.state.project.status !== 'DRAFT'">
                        {{$t('project.admin.edit.tabs.participants.name')}}
                    </v-tab>
                    <v-tab active-class="secondary--text"
                           :to="buildRoute('projectDropouts')"
                           v-show="$store.state.project.status !== 'DRAFT'">
                        {{$t('project.admin.edit.tabs.dropouts.name')}}
                    </v-tab>
                </v-tabs>
                <router-view></router-view>
            </div>
        </az-container>
    </div>
</template>

<script>
    import SummaryBar from './SummaryBar'

    export default {
        name: 'edit-project',
        components: {SummaryBar},
        data() {
            return {
                tabs: [
                    {
                        title: this.$t('project.admin.edit.tabs.generalData.name'),
                        route: 'projectGeneralInfo'
                    },
                    {
                        title: this.$t('project.admin.edit.tabs.termsAndConditions.name'),
                        route: 'projectTermsAndConditions'
                    },
                    {
                        title: this.$t('project.admin.edit.tabs.requiredDocuments.name'),
                        route: 'projectDocuments'
                    }
                ]
            }
        },
        mounted() {
            this.closeAsideMenu()
        },
        methods: {
            buildRoute(name) {
                return {
                    name,
                    params: {id: this.$route.params.id},
                    query: this.$route.query
                }
            }
        }
    }
</script>