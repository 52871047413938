<template>
    <v-card>
        <div class="primary" style="padding:10px 20px">
            <div class="white--text headline">
                {{$t('project.admin.edit.tabs.participants.visitors.title')}}
            </div>
            <div style="color: rgba(255,255,255,.5)">
                {{$t('project.admin.edit.tabs.participants.visitors.email.subtitle')}}
            </div>
        </div>
        <v-card-text>
            <multi-string
                editing
                placeholder="E-mail"
                :name="$t('project.admin.edit.tabs.participants.visitors.email.emailList.name')"
                :label="$t('project.admin.edit.tabs.participants.visitors.email.emailList.label')"
                :addLabel="$t('project.admin.edit.tabs.participants.visitors.email.emailList.add')"
                :removeLabel="$t('project.admin.edit.tabs.participants.visitors.email.emailList.remove')"
                :items="emails"
                @add="addEmail"
                @remove="removeEmail"/>
        </v-card-text>
        <v-card-actions>
            <v-btn color="primary" text @click="$emit('back')">
                {{$t('project.admin.edit.tabs.participants.visitors.actions.back')}}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="send">
                {{$t('project.admin.edit.tabs.participants.visitors.actions.send')}}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {actionTypes} from '@/commons/constants'
    import MultiString from '@/views/components/MultiString'

    export default {
        components: {MultiString},
        name: 'send-email',
        data() {
            return {
                emails: []
            }
        },
        created() {
            const customerEmails = this.$store.state.project.customer.emails
            customerEmails.forEach(email => this.emails.push({value: email}))
        },
        methods: {
            addEmail() {
                this.emails.push({value: ''})
            },
            hasValidRecipients() {
                let hasValidEmails = false
                for (let email of this.emails) {
                    if (email.value) {
                        hasValidEmails = true
                        break
                    }
                }
                return hasValidEmails
            },
            prepareEmails() {
                const emails = []
                for (let email of this.emails) {
                    if (email.value) {
                        emails.push(email.value)
                    }
                }
                return emails
            },
            removeEmail(index) {
                this.emails.splice(index, 1)
            },
            async send() {
                if (!this.hasValidRecipients()) {
                    this.showErrorFrontEnd('project.admin.edit.tabs.participants.visitors.email.errors.emptyRecipentsList')
                    return
                }
                this.setLoadingMessage('Sending e-mail...')
                await this.$store.dispatch(actionTypes.SEND_VISITORS_LIST_EMAIL, {
                    projectId: this.$store.state.project._id,
                    emails: this.prepareEmails()
                })
                this.showSuccess()
                this.$emit('close')
            }
        }
    }
</script>
