<template>
    <div>
        <az-container v-if="hasRepresentatives">
            <az-form>
                <v-data-table
                        :headers="headers"
                        :items="representatives"
                        :loading="false"
                        hide-default-footer
                        disable-pagination
                        class="az-table-list">
                    <template v-slot:item="props">
                        <tr>
                            <td><span>{{props.item.name}}</span></td>
                            <td><span>{{props.item.email}}</span></td>
                            <td>
                                <v-chip
                                        v-if="props.item.source === 'invite'"
                                        text-color="white"
                                        color="orange darken-1"
                                        small>
                                    {{$t(`representatives.search.status.${props.item.source}`)}}
                                </v-chip>
                                <v-chip
                                        v-else
                                        color="green darken-1"
                                        text-color="white"
                                        small>
                                    {{$t(`representatives.search.status.${props.item.source}`)}}
                                </v-chip>
                            </td>
                            <td class="text-xs-right" @click.stop="">
                                <v-menu bottom left>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" dark icon text depressed color="primary">
                                            <v-icon>more_vert</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                    </v-list>
                                </v-menu>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </az-form>
        </az-container>
    </div>
</template>

<script>
    export default {
        name: 'representative-search-table',
        props: {
            representatives: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                headers: [
                    {
                        text: this.$t('representatives.search.tableColumns.name'),
                        align: 'left',
                        sortable: false,
                        width: '35%',
                        class: 'primary--text'
                    },
                    {
                        text: this.$t('representatives.search.tableColumns.email'),
                        align: 'left',
                        sortable: false,
                        width: '35%',
                        class: 'primary--text'
                    },
                    {
                        text: this.$t('representatives.search.tableColumns.status'),
                        align: 'left',
                        sortable: false,
                        width: '20%',
                        class: 'primary--text'
                    },
                    {
                        text: this.$t('representatives.search.tableColumns.actions'),
                        sortable: false,
                        align: 'right',
                        width: '1%',
                        class: 'primary--text'
                    }
                ]
            }
        },
        computed: {
            hasRepresentatives() {
                return this.representatives.length
            }
        }
    }
</script>
