<script>
    import UserForm from './components/UserForm'
    import UserActions from './components/UserActions'
    import UserBack from './components/UserBack'
    import {TextUtils, ObjectUtils} from '@/commons/utils'
    import {actionTypes} from '@/commons/constants'

    export default {
        components: {
            UserForm,
            UserActions,
            UserBack
        },
        data() {
            return {
                userId: ''
            }
        },
        mounted() {
            this.getUserIdFromUrl()
        },
        methods: {
            getUserIdFromUrl() {
                this.userId = this.$route.params.id
            },
            async getFromRemoteServer() {
                this.user = await this.$store.dispatch(actionTypes.FIND_USER_BY_ID, this.userId)
            },
            cancel() {
                this.goToSearchListPage()
            },
            async hasValidationErrors() {
                return !(await this.$validator._base.validateAll())
            },
            preparedModel(model) {
                const data = ObjectUtils.clone(model)
                TextUtils.unmask(data, 'phoneNumber1', 'phoneNumber2')
                return data
            },
            goToSearchListPage() {
                this.$router.push({name: 'users'})
            },
            goToEditPage(id) {
                this.$router.push({name: 'editUser', params: {id}})
            }
        }
    }
</script>
