<template>
    <div>
        <v-text-field
                :name="$t('company.edit.form.fields.address.state.name')"
                :label="$t('company.edit.form.fields.address.state.label')"
                :placeholder="$t('company.edit.form.fields.address.state.placeholder')"
                :error-messages="errors.collect(`${$t('company.edit.form.fields.address.state.name')}`)"
                v-model="value.address.state"
                v-if="editing"
                v-validate="'required'"/>
        <az-text-view
                :label="$t('company.edit.form.fields.address.state.label')"
                :text="value.address.state"
                v-else/>
    </div>
</template>
<script>
    export default {
        name: 'company-form-state',
        props: {
            editing: {
                type: Boolean,
                default: true
            },
            value: {
            }
        }
    }
</script>
