<template>
    <div style="padding: 20px" class="fa-detail-project-document-table">
        <div style="display: flex; justify-content: center" v-show="false">
            <az-file-upload
                    repository=""
                    ref="azFileUploadDocuments"
                    style="margin: 20px; width: 600px"
                    @error="treatFileUploadError">
            </az-file-upload>
        </div>
        <v-flex xs12 style="width:100%; border-bottom: 1px solid #ddd" class="py-3 text-md-center">
            <span class="title font-weight-bold primary--text">{{$t('project.participant.documents.title')}}</span>
            -
            <span class="body-2">({{$t('project.participant.documents.finishAt')}}: {{project.calendar.basicDocuments | azDate('DD/MM/YYYY HH:mm')}})</span>
        </v-flex>

        <v-flex xs12 style="width:100%" class="py-3 text-md-right">
            <v-btn small outlined tile color="primary" class="text-none">{{$t('project.participant.documents.dismiss')}}
            </v-btn>
            <v-btn small depressed dark tile color="primary" class="text-none ml-4" @click="openDialogDocuments">
                <v-icon size="18" class="mr-2">near_me</v-icon>
                {{$t('project.participant.documents.sendFiles')}}
            </v-btn>
        </v-flex>

        <v-dialog
                v-model="dialogEnvioEmMassa"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
                scrollable
        >
            <v-card tile class="fa-modal-envio-massa">
                <v-app-bar dense dark class="d-lg-table" color="primary">
                    <v-btn icon dark @click="cancel">
                        <v-icon>close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{$t('project.participant.documents.sendBulkFiles')}}</v-toolbar-title>
                </v-app-bar>
                <div style="border-top: 1px solid #eee">
                    <v-data-table
                            :items="documentsToBeSaved"
                            :loading="false"
                            hide-default-header
                            hide-default-footer
                            disable-pagination
                            class="tabela-adicionar">
                        <template v-slot:item="props">
                            <div style="; display: flex; border-bottom: 1px solid #eee; padding: 15px 20px 5px 20px">
                                <v-flex xs3 class="mr-4">
                                    <span class="font-weight: lighter;font-size: 13px;">{{$t('project.participant.documents.tableColumns.file')}}</span><br>
                                    <div style="font-size: 17px;
                                        color: rgb(170, 170, 170);
                                        font-weight: lighter;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        white-space: nowrap;
                                        width: 240px;">{{props.item.filename}}
                                    </div>
                                </v-flex>
                                <v-flex xs5 class="mr-4">
                                    <span style="font-size: 13px;">{{$t('project.participant.documents.tableColumns.type')}}</span><span
                                        style="color: red"> *</span>
                                    <v-select
                                            style="padding-top: 0px;margin-top: -5px;"
                                            :items="translatedDocumentTypes"
                                            hide-details
                                            placeholder="Selecione"
                                            v-model="props.item.type">
                                    </v-select>
                                </v-flex>
                                <v-flex xs3 class="mr-4">
                                    <span style="font-size: 13px;">{{$t('project.participant.documents.tableColumns.expiresAt')}}</span>
                                    <az-date v-model="props.item.expiresAt" style="margin-top: -21px;"/>
                                </v-flex>
                                <v-flex xs1 class="text-md-center">
                                    <a @click.prevent="removeDocument(props.item)">
                                        <v-icon style="font-size: 28px; margin-top: 15px;">close</v-icon>
                                    </a>
                                </v-flex>
                            </div>
                        </template>
                    </v-data-table>
                </div>
                <div class="az-actions-form" v-if="hasDocumentsToBeSaved">
                    <div class="align-left">
                    </div>
                    <div class="align-right" style="margin: 0 14px;">
                        <button class="action-secundary mr-2" @click.prevent="cancel">
                            {{$t('project.participant.documents.cancel')}}
                        </button>
                        <button class="action-primary" @click.prevent="saveAllDocs">
                            Salvar Todos
                        </button>
                    </div>
                </div>
            </v-card>
        </v-dialog>
        <v-flex xs12 sm12 style="margin: 14px;">
            <v-data-table
                    :headers="headers"
                    :items="documents"
                    :loading="false"
                    hide-default-footer
                    disable-pagination
                    class="az-table-list">
                <template v-slot:item="props">
                    <tr>
                        <td>
                            <span>{{$t(`project.requiredDocuments.${props.item.type}`)}}</span>
                        </td>
                        <td :class="getExpirationDateClass(props.item)" style="width: 200px">
                            <span v-if="props.item.expiresAt">{{props.item.expiresAt | azDate}} </span>
                            <span v-if="props.item.expiresAt">(</span>
                            <span>{{getExpirationDateLabel(props.item)}}</span>
                            <span v-if="props.item.expiresAt">)</span>
                        </td>
                        <td style="width: 150px">
                            <div v-if="!props.item.status" class="text-md-center"> -</div>
                            <div v-bind:class="{'reproved': props.item.status === 'REPROVED',
                            'approved': props.item.status === 'APPROVED',
                            'analysing': props.item.status === 'ANALYSING'}"
                                 v-if="props.item.status && !props.item.exemption">{{props.item.status ?
                                $t(`project.participant.documents.status.${props.item.status}`) :
                                $t('project.participant.documents.status.NOT_UPLOADED')}}
                            </div>
                            <div v-if="props.item.exemption" class="analysing">
                                {{$t('project.participant.documents.status.EXEMPT')}}
                            </div>
                        </td>
                        <td class="table-actions text-md-center">
                            <v-btn text small class="text-none" v-if="!props.item.filename" color="#7f7f7f"
                                   @click.prevent="reUploadDocument = null; setTypeToBeSaved(props.item.type); $refs.azFileUploadDocuments.openFileSelector()">
                                <v-icon>near_me</v-icon>
                                {{$t('project.participant.detail.documents.send')}}
                            </v-btn>
                            <v-btn text small class="text-none" v-if="props.item.status === 'REPROVED'" color="#7f7f7f"
                                   @click.prevent="reUploadDocument = props.item; $refs.azFileUploadDocuments.openFileSelector()">
                                <v-icon>cached</v-icon>
                                {{$t('project.participant.detail.documents.reSend')}}
                            </v-btn>
                            <v-menu offset-y
                                    v-if="props.item.status === 'APPROVED' || props.item.status === 'ANALYSING'">
                                <template v-slot:activator="{ on }">
                                    <v-icon color="#aaa" v-on="on">more_vert</v-icon>
                                </template>
                                <v-list>
                                    <v-list-item style="border-bottom: 1px solid #ddd" class="menu-acoes"
                                                 @click="downloadDocument(props.item.filename)">
                                        <v-icon size="18" class="mr-1">get_app</v-icon>
                                        <v-list-item-title>{{$t('project.participant.detail.documents.download')}}
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item class="menu-acoes" @click="removeDocument(props.item)">
                                        <v-icon size="18" class="mr-1">close</v-icon>
                                        <v-list-item-title>{{$t('project.participant.detail.documents.remove')}}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-flex>
        <az-confirm
                v-if="dialogRemove"
                :label-confirm="$t('confirmation.yes')"
                :label-decline="$t('confirmation.no')"
                @onConfirm="removeDocumentOnBackend"
                @onDecline="hideDialog">
            {{$t('project.participant.documents.confirmRemoveDocument')}}
        </az-confirm>
    </div>
</template>

<script>
    import moment from 'moment-timezone'
    import {actionTypes} from '@/commons/constants'
    import {mapState} from 'vuex'

    export default {
        name: "detail-project-document-table",
        props: ['project'],
        data() {
            return {
                reUploadDocument: null,
                selectedTypeToBeSaved: null,
                dialogRemove: false,
                documentToRemove: null,
                documents: [],
                documentsToBeSaved: [],
                translatedDocumentTypes: [],
                dialogEnvioEmMassa: false,
                headers: [
                    {
                        text: this.$t('project.participant.detail.documents.tableColumns.documents'),
                        sortable: false,
                        class: 'document-table-column'
                    },
                    {
                        text: this.$t('project.participant.detail.documents.tableColumns.expireDate'),
                        sortable: false,
                        class: 'document-table-column'
                    },
                    {
                        text: this.$t('project.participant.detail.documents.tableColumns.status'),
                        sortable: false,
                        class: 'document-table-column'
                    },
                    {
                        text: this.$t('project.participant.detail.documents.tableColumns.actions'),
                        sortable: false,
                        align: 'center',
                        class: 'document-table-column'
                    }
                ]
            }
        },
        computed: {
            hasDocumentsToBeSaved() {
                return this.documentsToBeSaved.length
            },
            ...mapState(['company', 'loki'])
        },
        watch: {
            async 'loki.uploadedFiles'() {
                const uploadedFiles = this.loki.uploadedFiles
                const newDocuments = []
                if (this.reUploadDocument) {
                    this.reUploadDocument.filename = uploadedFiles[uploadedFiles.length - 1].filename
                    this.reUploadDocument.status = 'ANALYSING'
                    await this.updateDocument(this.reUploadDocument)
                    this.reUploadDocument = null
                    uploadedFiles.splice(uploadedFiles.length - 1, 1)
                    this.$store.commit('SET_UPLOADED_FILES', uploadedFiles)
                    return
                }
                for (let i = 0; i < uploadedFiles.length; i++) {
                    const file = uploadedFiles[i]
                    if (!this.containsInDocumentsToBeSaved(file.filename)) {
                        const doc = {
                            filename: file.filename,
                            exemption: false
                        }
                        if (this.selectedTypeToBeSaved) {
                            doc.type = this.selectedTypeToBeSaved
                            this.selectedTypeToBeSaved = null
                        }
                        newDocuments.push(doc)
                    }
                }
                this.documentsToBeSaved = this.documentsToBeSaved.concat(newDocuments)
            },
            'documents'() {
                this.translateDocumentTypes()
            },
            'documentsToBeSaved'() {
                if (this.hasDocumentsToBeSaved)
                    this.dialogEnvioEmMassa = true
                else
                    this.dialogEnvioEmMassa = false
            }
        },
        methods: {
            openDialogDocuments() {
                this.reUploadDocument = null
                this.$refs.azFileUploadDocuments.openFileSelector()
            },
            setTypeToBeSaved(type) {
                this.selectedTypeToBeSaved = type
            },
            hideDialog() {
                this.dialogRemove = false
            },
            confirmBeforeRemove() {
                this.dialogRemove = true
            },
            containsInDocumentsToBeSaved(filename) {
                return this.documentsToBeSaved.filter(e => e.filename === filename).length > 0
            },
            containsInDocuments(type) {
                if (this.documents.length === 0)
                    return false
                return this.documents.find(e => e.type === type).filename
            },
            async findDocumentsByCompanyId() {
                try {
                    this.documents = await this.$store.dispatch(actionTypes.FIND_DOCUMENTS_BY_COMPANY_ID, this.company._id)
                    this.documents = this.documents.filter(doc => this.project.requiredDocuments.filter(req => req.mandatory).findIndex(req => req.type === doc.type) !== -1)
                    this.project.requiredDocuments.forEach(doc => {
                        if (doc.mandatory && this.documents.findIndex(d => d.type === doc.type) === -1) {
                            this.documents.push({type: doc.type})
                        }
                    })
                } catch (e) {
                    this.showErrorFrontEnd('project.participant.documents.errors.findDocs')
                }
            },
            translateDocumentTypes() {
                this.translatedDocumentTypes = []
                this.project.requiredDocuments.map(doc => {
                    const key = `project.requiredDocuments.${doc.type}`
                    if (doc.mandatory && !this.containsInDocuments(doc.type)) {
                        this.translatedDocumentTypes.push({text: this.$t(key), value: doc.type})
                    }
                })
                this.translatedDocumentTypes.sort((a, b) => a.text.localeCompare(b.text))
            },
            removeDocFromDocsToSave(doc) {
                const idxToBeRemoved = this.documentsToBeSaved.findIndex(docToSave => docToSave.filename === doc.filename);
                this.documentsToBeSaved.splice(idxToBeRemoved, 1)
            },
            removeDocOnFrontend(doc) {
                const uploadedFiles = this.loki.uploadedFiles
                const uploadedFileIdx = uploadedFiles.findIndex(file => file.filename === doc.filename)
                uploadedFiles.splice(uploadedFileIdx, 1)
                this.removeDocFromDocsToSave(doc)
                this.$store.commit('SET_UPLOADED_FILES', uploadedFiles)
            },
            removeAllDocsOnFrontEnd() {
                this.documentsToBeSaved = []
                this.$store.commit('SET_UPLOADED_FILES', [])
            },
            docToSaveIsValid: function (doc) {
                return doc.type
            },
            anyDocTypeRepeated(document, docs) {
                const countDocsByType = docs.filter((doc) => (doc.type === document.type)).length
                return countDocsByType > 1
            },
            docsAreValid(docs) {
                let valid = true
                for (let doc of docs) {
                    if (!this.docToSaveIsValid(doc)) {
                        this.showErrorFrontEnd('project.participant.documents.errors.docToSaveIsInvalid')
                        valid = false
                        break
                    }
                    if (this.isExpired(doc.expiresAt)) {
                        this.showErrorFrontEnd('project.participant.documents.errors.docToSaveIsExpired')
                        valid = false
                        break
                    }
                    if (this.anyDocTypeRepeated(doc, docs)) {
                        this.showErrorFrontEnd('project.participant.documents.errors.docToSaveIsRepeatedType')
                        valid = false
                        break
                    }
                }
                return valid
            },
            async saveDocument(doc) {
                if (!this.docsAreValid([doc])) {
                    return
                }
                await this.$store.dispatch(actionTypes.CREATE_DOCUMENT, {
                    companyId: this.company._id,
                    documents: [doc]
                })
                this.removeDocOnFrontend(doc)
                await this.findDocumentsByCompanyId()
                this.showSuccess()
            },
            async saveAllDocs() {
                if (!this.docsAreValid(this.documentsToBeSaved)) {
                    return
                }
                await this.$store.dispatch(actionTypes.CREATE_DOCUMENT, {
                    companyId: this.company._id,
                    documents: this.documentsToBeSaved.reverse()
                })
                this.removeAllDocsOnFrontEnd()
                await this.findDocumentsByCompanyId()
                this.showSuccess()
            },
            cancel() {
                this.removeAllDocsOnFrontEnd()
            },
            async updateDocument(doc) {
                await this.$store.dispatch(actionTypes.UPDATE_DOCUMENT, {
                    companyId: this.company._id,
                    documentId: doc._id,
                    document: doc
                })
                await this.findDocumentsByCompanyId()
                this.showSuccess()
            },
            isDefinitiveDoc(doc) {
                return doc._id
            },
            removeDocument(doc) {
                if (this.isDefinitiveDoc(doc)) {
                    this.documentToRemove = doc
                    this.confirmBeforeRemove()
                } else {
                    this.removeDocOnFrontend(doc)
                }
            },
            async removeDocumentOnBackend() {
                this.hideDialog()
                await this.$store.dispatch(actionTypes.REMOVE_DOCUMENT, {
                    companyId: this.company._id,
                    documentId: this.documentToRemove._id
                })
                await this.findDocumentsByCompanyId()
            },
            getExpirationDateClass(item) {
                if (item.status === 'REPROVED' || this.isExpired(item.expiresAt)) {
                    return 'red--text font-weight-bold'
                }
            },
            getExpirationDateLabel(item) {
                if (!this.hasExpirationDate(item.expiresAt)) {
                    return item.exemption || !item.status ? '-' : this.$t('project.participant.documents.indefinitePeriod')
                } else if (this.isExpired(item.expiresAt)) {
                    return this.$t('project.participant.documents.expired')
                } else {
                    const today = moment().endOf('day')
                    const endOfExpirationDate = moment(item.expiresAt).endOf('day')
                    const daysLeft = Math.abs(today.diff(endOfExpirationDate, 'days'))
                    const template = this.$t('project.participant.documents.expiresIn')
                    return template.replace('$_X_DAYS_$', daysLeft)
                }
            },
            hasExpirationDate(expDate) {
                return expDate
            },
            isExpired(exp) {
                const now = moment()
                const expDate = moment(exp)
                return now.isAfter(expDate)
            },
            downloadDocument(slug) {
                let a = window.document.createElement("a")
                a.href = this.buildDownloadLink(slug)
                a.download = slug
                window.document.body.appendChild(a)
                a.click()
            }
        },
        mounted() {
            this.findDocumentsByCompanyId()
            this.translateDocumentTypes()
        }
    }
</script>

<style lang="stylus">
    .fa-modal-envio-massa
        .tabela-adicionar
            max-height 400px
            overflow-y auto

        .az-actions-form
            margin: 0 !important
            padding: 10px 20px !important

            .action-primary
                margin 0 !important

    .fa-detail-project-document-table
        .v-input
            margin-top 0 !important
            padding-top 0 !important

            .v-input__slot
                height 24px !important

        .tabela-adicionar
            border 1px solid #e9e9e9
            padding 0 !important

    .reproved
        width 120px
        background-color red
        color white
        padding 2px 10px
        border-radius 20px
        text-align center

    .approved
        width 120px
        background-color green
        color white
        padding 2px 10px
        border-radius 20px
        text-align center

    .analysing
        width 120px
        background-color #777
        color white
        padding 2px 10px
        border-radius 20px
        text-align center

    .document-table-column
        color #153c5c !important

    .menu-acoes:hover
        background-color #ddd
        cursor pointer

    .v-dialog
        .v-card__text
            padding 10px 15px 5px 15px !important
</style>
