<template>
    <v-card color="primary" style="border-radius: 4px 4px 0px 0px !important" flat>
        <v-container pa-0>
            <v-layout align-center justify-center fill-height>
                <img src="/img/main-logo.svg"/>
            </v-layout>
        </v-container>
    </v-card>
</template>
<script>
    export default {
        name: 'login-header'
    }
</script>
