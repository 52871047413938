export default [
    'iron',
    'ironless',
    'platic',
    'rubber',
    'wood',
    'paperboard',
    'eletronic',
    'oil'
]
