<template>
    <v-card-text>
        <v-container>
            <titlebar :title="$t('register.title')" :subtitle="$t('register.subtitle')"/>
            <v-form v-on:submit.prevent="submitForm" autocomplete="off">

                <v-text-field prepend-icon="person" name="fullname" type="text" maxlength="255"
                              :data-vv-as="$t('register.fullname')"
                              :placeholder="$t('register.fullname')"
                              :error-messages="errors.collect('fullname')"
                              v-model="name"
                              v-validate="'required'"/>

                <v-text-field prepend-icon="email" name="email" type="text" maxlength="255"
                              :data-vv-as="$t('register.email')"
                              :placeholder="$t('register.email')"
                              :error-messages="errors.collect('email')"
                              v-model="email"
                              v-validate="'required|email'"/>

                <v-text-field prepend-icon="phone" name="phoneNumber1" type="text"
                              :data-vv-as="$t('register.phones.number1')"
                              :placeholder="$t('register.phones.number1')"
                              :error-messages="errors.collect('phoneNumber1')"
                              v-mask="JSON.parse($t('register.phones.mask'))"
                              v-model="phoneNumber1"
                              v-validate="'required'"/>

                <v-text-field prepend-icon="phone" name="phoneNumber2" type="text"
                              :data-vv-as="$t('register.phones.number2')"
                              :placeholder="$t('register.phones.number2')"
                              :error-messages="errors.collect('phoneNumber2')"
                              v-mask="JSON.parse($t('register.phones.mask'))"
                              v-model="phoneNumber2"/>

                <v-text-field prepend-icon="lock" name="password" type="password" maxlength="20"
                              :data-vv-as="$t('register.password')"
                              :placeholder="$t('register.password')"
                              :error-messages="errors.collect('password')"
                              v-model="password"
                              v-validate="'required'"
                              ref="passwordRef"/>

                <v-text-field prepend-icon="lock" name="confirmation" type="password" maxlength="20"
                              :data-vv-as="$t('register.confirmation')"
                              :placeholder="$t('register.confirmation')"
                              :error-messages="errors.collect('confirmation')"
                              v-model="confirmation"
                              v-validate="'required|confirmed:passwordRef'"/>
                <v-btn color="success" class="mt-4" type="submit" block large
                       :loading="$store.state.loki.isLoading"
                       :disabled="(submitted && errors.any()) || $store.state.loki.isLoading">
                    {{$t('register.submit')}}
                </v-btn>
            </v-form>
        </v-container>
    </v-card-text>
</template>

<script>
    import Titlebar from '../../commons/Titlebar'
    import {TextUtils} from '@/commons/utils'

    export default {
        name: 'register-form',
        components: {
            Titlebar
        },
        data() {
            return {
                name: '',
                email: '',
                phoneNumber1: '',
                phoneNumber2: '',
                password: '',
                confirmation: '',
                submitted: false
            }
        },
        methods: {
            removeSpecialCharacteres(phone) {
                if (phone) {
                    const unmaskedValue = TextUtils.removeSpecialCharacters(phone)
                    return unmaskedValue
                } else {
                    return ''
                }
            },
            async submitForm() {
                this.submitted = true
                const isValid = await this.$validator.validateAll()
                if (isValid) {
                    const userData = {
                        name: this.name,
                        email: this.email,
                        phoneNumber1: this.removeSpecialCharacteres(this.phoneNumber1),
                        phoneNumber2: this.removeSpecialCharacteres(this.phoneNumber2),
                        password: this.password
                    }
                    this.$emit('submit', userData)
                }
            }
        }
    }
</script>
