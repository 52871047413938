<template>
    <v-dialog v-model="openDialog" persistent max-width="600">
        <v-card>
            <v-card-title class="headline">{{title}}</v-card-title>
            <v-card-text>{{text}}</v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="text--secondary" text @click="$emit('reject')">
                {{rejectText}}
            </v-btn>
            <v-btn color="error" text @click="$emit('accept')">
                {{acceptText}}
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'dialog-view',
    props: {
        openDialog: {
            type: Boolean,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        rejectText: {
            type: String,
            required: true
        },
        acceptText: {
            type: String,
            required: true
        }
    }
}
</script>
