<template>
    <div class="az-actions-form">
        <div class="align-left"></div>
        <div class="align-right">
            <button class="action-secundary" @click.prevent="$emit('cancel')">
                {{$t('users.edit.cancel')}}
            </button>
            <button class="action-primary" @click.prevent="$emit('save')">
                {{$t('users.edit.save')}}
            </button>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'user-actions'
    }
</script>
