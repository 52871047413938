import store from '@/commons/store'
import {mutationTypes} from '@/commons/constants'

class ConfigureFileManager {
    async execute() {
        return new Promise((resolve) => {
            store.commit(mutationTypes.LOKI.SET_FILES_CONFIG, {
                api: 'api/files',
                maxSize: '20Mb'
            })
            resolve()
        })
    }
}

export default new ConfigureFileManager()
