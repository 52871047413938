<template>
    <div>
        <user-back/>
        <az-container>
            <div class="az-form-content">
                <user-form :editing="editing" v-model="user"/>
            </div>
        </az-container>
    </div>
</template>

<script>
    import AbstractUser from './AbstractUser'

    export default {
        name: 'user-view',
        extends: AbstractUser,
        data() {
            return {
                editing: false,
                userId: null,
                user: {}
            }
        },
        async mounted() {
            this.closeAsideMenu()
            this.getUserIdFromUrl()
            await this.getFromRemoteServer()
        }
    }
</script>
