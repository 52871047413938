<template>
    <v-dialog
        :value=true
        persistent
        scrollable
        width="800">
        <v-card>
            <v-card-title
              class="headline primary white--text" primary-title>
              {{$t('project.admin.edit.tabs.participants.documents.title')}}
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="participant.documents"
                    :loading="false"
                    hide-default-footer
                    disable-pagination>
                    <template v-slot:item="props">
                        <tr>
                            <td>{{ props.item.typeLabel }}</td>
                            <td>{{ props.item.createdAt | azDate('DD/MM/YYYY HH:MM')}}</td>
                            <td :class="props.item.expirationClass">{{props.item.expirationLabel}}</td>
                            <td :class="props.item.statusClass">{{ props.item.statusLabel }}</td>
                            <td v-if="showAnalysisButton(props.item)">
                                <v-btn outlined primary small @click="analyzeDoc(props.item)">
                                    {{$t('project.admin.edit.tabs.participants.documents.analyze')}}
                                </v-btn>
                            </td>
                            <td v-else>{{ props.item.analysisNotes }}</td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray" text @click="$emit('close')">
                    {{$t('project.admin.edit.close')}}
                </v-btn>
            </v-card-actions>
        </v-card>
        <project-participant-doc-analysis
            v-if="showAnalysisModal"
            :document="document"
            @save="updateDocAnalysis"
            @cancel="hideDocumentAnalysis"/>
    </v-dialog>
</template>

<script>
    import moment from 'moment-timezone'
    import {actionTypes} from '@/commons/constants'
    import ProjectParticipantDocAnalysis from './DocAnalysis'

    export default {
        name: 'project-participants-docs',
        components: {ProjectParticipantDocAnalysis},
        props: {
            participant: {
                required: true
            }
        },
        data() {
            return {
                document: {},
                headers: [
                    {
                        text: 'Tipo',
                        align: 'left',
                        sortable: false,
                        class: 'primary--text'
                    },
                    {
                        text: this.$t('project.admin.edit.tabs.participants.documents.tableColumns.createdAt'),
                        align: 'left',
                        sortable: false,
                        class: 'primary--text'
                    },
                    {
                        text: this.$t('project.admin.edit.tabs.participants.documents.tableColumns.expiresAt'),
                        align: 'left',
                        sortable: false,
                        class: 'primary--text'
                    },
                    {
                        text: this.$t('project.admin.edit.tabs.participants.documents.tableColumns.status'),
                        align: 'left',
                        sortable: false,
                        class: 'primary--text'
                    },
                    {
                        text: this.$t('project.admin.edit.tabs.participants.documents.tableColumns.notes'),
                        align: 'left',
                        sortable: false,
                        class: 'primary--text'
                    }
                ],
                showAnalysisModal: false
            }
        },
        computed: {
            projectFinished() {
                return this.$store.state.project.status === 'FINISHED'
            }
        },
        created() {
            this.setViewAttributes()
        },
        methods: {
            analyzeDoc(doc) {
                this.document = doc
                this.showAnalysisModal = true
            },
            getExpirationDateClass(expDate) {
                if (this.isExpired(expDate)) {
                    return 'red--text font-weight-bold'
                }
            },
            getExpirationDateLabel(expDate) {
                if (!this.hasExpirationDate(expDate)) {
                    return '-'
                } else if (this.isExpired(expDate)) {
                    return this.$t('project.admin.edit.tabs.participants.documents.expired')
                } else {
                    const today = moment().endOf('day')
                    const endOfExpirationDate = moment(expDate).endOf('day')
                    const daysLeft = Math.abs(today.diff(endOfExpirationDate, 'days'))
                    const template = this.$t('project.admin.edit.tabs.participants.documents.expiresIn')
                    return template.replace('$_X_DAYS_$', daysLeft)
                }
            },
            getStatusClass(status) {
                if (status === 'REPROVED') {
                    return 'red--text font-weight-bold'
                }
            },
            getStatusLabel(doc) {
                if (doc.exemption) {
                    return this.$t(`project.admin.edit.tabs.participants.documents.status.EXEMPT`)
                }
                return this.$t(`project.admin.edit.tabs.participants.documents.status.${doc.status}`)
            },
            getTypeLabel(type) {
                return this.$t(`project.requiredDocuments.${type}`)
            },
            hasExpirationDate(expDate) {
                return expDate
            },
            hideDocumentAnalysis() {
                this.document = {}
                this.showAnalysisModal = false
            },
            inAnalysis(status) {
                return status === 'ANALYSING'
            },
            isExpired(exp) {
                const now = moment()
                const expDate = moment(exp)
                return now.isAfter(expDate)
            },
            setViewAttributes() {
                for (let doc of this.participant.documents) {
                    doc.typeLabel = this.getTypeLabel(doc.type)
                    doc.expirationLabel = this.getExpirationDateLabel(doc.expiresAt)
                    doc.expirationClass = this.getExpirationDateClass(doc.expiresAt)
                    doc.statusLabel = this.getStatusLabel(doc)
                    doc.statusClass = this.getStatusClass(doc.status)
                    doc.inAnalysis = this.inAnalysis(doc.status)
                    doc.isExpired = this.isExpired(doc.expiresAt)
                }
            },
            showAnalysisButton(doc) {
                return doc.inAnalysis && !doc.exemption && !doc.isExpired && !this.projectFinished
            },
            async updateDocAnalysis(document) {
                await this.$store.dispatch(actionTypes.UPDATE_DOCUMENT_STATUS, {
                    companyId: document.companyId,
                    documentId: document._id,
                    project: this.$store.state.project,
                    document
                })
                this.hideDocumentAnalysis()
                this.$emit('refresh')
            }
        }
    }
</script>