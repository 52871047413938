<template>
    <container>
        <v-card>
            <headerbar/>
            <register-form @submit="register"/>
            <footerbar target="login"/>
        </v-card>
    </container>
</template>

<script>
    import {actionTypes} from '@/commons/constants'
    import Container from "../../commons/Container"
    import Headerbar from "../../commons/Headerbar"
    import Footerbar from "../../commons/Footerbar"
    import RegisterForm from "./RegisterForm"

    export default {
        components: {Footerbar, Container, Headerbar, RegisterForm},
        data() {
            return {
                name: '',
                email: '',
                password: '',
                confirmation: '',
                submitted: false
            }
        },
        methods: {
            async register(userData) {
                try {
                    await this.$store.dispatch(actionTypes.REGISTER, userData)
                    this.redirectToConfirmation()
                } catch (err) {
                    this.showError(err)
                }
            },
            redirectToConfirmation() {
                this.$router.push({name: 'success', params: {action: 'REGISTER'}})
            }
        }
    }
</script>
