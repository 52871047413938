import Vue from 'vue'
import VeeValidate from 'vee-validate'
import ptBR from 'vee-validate/dist/locale/pt_BR'
import en from 'vee-validate/dist/locale/en'
import i18n from '@/commons/i18n'

Vue.use(VeeValidate, {
    i18nRootKey: 'validations',
    i18n,
    dictionary: {
        en: en,
        pt: ptBR
    }
})
