import publicPages from './PublicPages'
import notLoggedIn from './NotLoggedIn'
import noCompany from './NoCompany'
import inativeCompany from './InativeCompany'
import hasAuthorities from './HasAuthorities'

const conditions = {
    publicPages,
    notLoggedIn,
    noCompany,
    inativeCompany,
    hasAuthorities
}

export default (to, from, next) => {
    const tasks = Object.keys(conditions)

    const nextExecuted = tasks.some((condition) => {
        if (conditions[condition].shouldExecute(to, from)) {
            conditions[condition].execute(to, from, next)
            return true
        }
        return false
    })

    if (!nextExecuted) {
        next()
    }
}
