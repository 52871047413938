<template>
    <div>
        <az-back-button :route="{name: 'customers'}" :text="$t('customer.edit.back')"/>
        <az-container>
            <div>
                <div class="az-form-content">
                    <customer-form
                        editing
                        @save="create"
                        @cancel="cancel"
                        v-model="customer"/>
                </div>
            </div>
        </az-container>
    </div>
</template>

<script>
    import {actionTypes} from '@/commons/constants'
    import CustomerForm from './CustomerForm'

    export default {
        components: {CustomerForm},
        name: 'add-customer',
        data() {
            return {
                customer: {
                    name: '',
                    document: '',
                    logoSlug: '',
                    phones: [],
                    address: {city: ''}
                }
            }
        },
        mounted() {
            this.closeAsideMenu()
        },
        methods: {
            cancel() {
                this.$router.push({name: 'customers'})
            },
            async create() {
                const isFormValid = await this.$validator._base.validateAll()
                if (!isFormValid) {
                    return
                }
                try {
                    const createdCustomer = await this.$store.dispatch(actionTypes.CREATE_CUSTOMER, this.customer)
                    this.$router.push({name: 'editCustomer', params: {id: createdCustomer._id}, query: {editing: true}})
                    this.showSuccess()
                } catch (e) {
                    this.showError(e)
                }
            }
        }
    }
</script>