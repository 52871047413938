<template>
    <az-container>
        <div class="az-form-content">
            <company-form :editing="editing" :loggedUser="loggedUser" :showAlert="true" v-model="company"/>
            <company-actions @save="save" @cancel="cancel"/>
        </div>
    </az-container>
</template>

<script>
    import {actionTypes, mutationTypes} from '@/commons/constants'
    import CompanyForm from '@/views/pages/commons/company/CompanyForm'
    import CompanyActions from '@/views/pages/commons/company/CompanyActions'

    export default {
        name: 'add-company-page',
        components: {
            CompanyForm,
            CompanyActions
        },
        data() {
            return {
                id: null,
                company: {
                    name: '',
                    document: '',
                    logoSlug: '',
                    categories: [],
                    address: {
                        line1: '',
                        line2: '',
                        city: '',
                        state: '',
                        zipCode: '',
                        country: ''
                    },
                    representatives: []
                },
                editing: true,
                loggedUser: {}
            }
        },
        async mounted() {
            this.closeAsideMenu()
            this.getLoggedUser()
            this.addUserToRepresentativesList()
        },
        methods: {
            addUserToRepresentativesList() {
                this.company.representatives.push(this.loggedUser)
            },
            cancel() {
                this.goToParticipantProjectsPage()
            },
            getLoggedUser() {
                this.loggedUser = this.$store.state.loggedUser
                this.loggedUser._id = this.loggedUser.id
            },
            async hasValidationErrors() {
                return !(await this.$validator._base.validateAll())
            },
            async insertOnRemoteServer() {
                return this.$store.dispatch(actionTypes.CREATE_COMPANY, this.company)
            },
            insertOnStore(companyCreated) {
                this.$store.commit(mutationTypes.SET_COMPANY, companyCreated)
            },
            goToParticipantProjectsPage() {
                this.$router.push({name: 'participantMyProjects'})
            },
            goToProjectsPage() {
                this.$router.push({name: 'participantProjects'})
            },
            async save() {
                if (await this.hasValidationErrors()) return
                try {
                    const companyCreated = await this.insertOnRemoteServer()
                    this.insertOnStore(companyCreated)
                    this.goToProjectsPage()
                    this.showSuccess()    
                } catch (e) {
                    this.showError(e)
                }
            }
        }
    }
</script>
