<template>
    <v-container fluid grid-list-lg class="fa-detail-project-visitation">
        <v-layout style="margin: 10px 20px 10px 10px; min-height: 540px; display: block" class="ml-4">
            <v-flex xs12 style="width:100%; border-bottom: 1px solid #ddd" class="py-3 text-md-center">
                <span class="title font-weight-bold primary--text">
                    {{$t('project.participant.detail.visitation.title')}}
                </span>
                <span class="body-2">
                    - ({{$t('project.participant.detail.visitation.finishAt')}}: {{project.calendar.visitation | azDate('DD/MM/YYYY HH:mm')}})
                </span>
            </v-flex>
            <v-flex xs12 class="mt-10 text-center">
                <span class="subheading grey--text lighten-1">
                    {{$t('project.participant.detail.visitation.description')}}
                </span>
            </v-flex>
            <v-flex xs12 class="text-center">
                <span class="subheading primary--text font-weight-bold">
                    {{$t('project.participant.detail.visitation.fields.agreedVisitation.label')}}
                </span>
            </v-flex>
            <v-flex xs12 offset-xs5>
                <v-radio-group
                        :name="$t('project.participant.detail.visitation.fields.agreedVisitation.name')"
                        :error-messages="errors.collect(`${$t('project.participant.detail.visitation.fields.agreedVisitation.name')}`)"
                        v-validate="'required'"
                        @change="onChangeAgreedVisitation($event)"
                        hide-details
                        class="visitacao-aceite"
                        v-model="agreedVisitation"
                        row
                >
                    <v-radio :label="$t('general.yes')" value="true"></v-radio>
                    <v-radio :label="$t('general.no')" value="false"></v-radio>
                </v-radio-group>
            </v-flex>
            <v-flex offset-xs1 xs10 v-show="agreedVisitation === 'true'" class="text-md-right">
                <a @click.prevent="addVisitant()" style="color: green">
                    <v-icon color="green">add_circle</v-icon>
                    {{$t('project.participant.detail.visitation.addVisitant')}}
                </a>
            </v-flex>
            <v-flex offset-xs1 xs10 v-show="agreedVisitation === 'true'" class="listagem">
                <v-layout row wrap v-for="(visitant, index) in visitors" :key="index">
                    <v-flex xs5>
                        <v-text-field
                                :name="$t('project.participant.detail.visitation.fields.name.name')"
                                :placeholder="$t('project.participant.detail.visitation.fields.name.placeholder')"
                                :error-messages="errors.collect(`${$t('project.participant.detail.visitation.fields.name.name')}`)"
                                v-validate="'required'"
                                v-model="visitant.name"
                        />
                    </v-flex>
                    <v-flex xs3>
                        <v-text-field
                                :name="$t('project.participant.detail.visitation.fields.document.name')"
                                :placeholder="$t('project.participant.detail.visitation.fields.document.placeholder')"
                                :error-messages="errors.collect(`${$t('project.participant.detail.document.fields.name.name')}`)"
                                v-validate="'required'"
                                v-model="visitant.docNumber"
                        />
                    </v-flex>
                    <v-flex xs4 style="display: flex">
                        <v-text-field
                                :name="$t('project.participant.detail.visitation.fields.phone.name')"
                                :placeholder="$t('project.participant.detail.visitation.fields.phone.placeholder')"
                                :error-messages="errors.collect(`${$t('project.participant.detail.visitation.fields.phone.name')}`)"
                                v-validate="'required'"
                                v-mask="JSON.parse($t('project.participant.detail.visitation.fields.phone.mask'))"
                                v-model="visitant.maskedPhone"
                        />
                        <v-icon class="botao-excluir" color="#ccc" @click="removeVisitant(index)">cancel
                        </v-icon>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
        <div class="area-inferior text-center">
            <v-btn
                    class="mt-2"
                    color="green"
                    dark
                    @click.prevent="save()"
            >{{$t('project.participant.detail.visitation.save')}}
            </v-btn>
        </div>
    </v-container>
</template>

<script>
    import _ from "lodash";
    import {mapState} from "vuex";
    import {actionTypes} from "@/commons/constants";
    import {TextUtils} from "@/commons/utils";

    export default {
        name: "detail-project-visitation",
        props: ["project"],
        data() {
            return {
                agreedVisitation: null,
                visitors: []
            };
        },
        computed: {
            ...mapState(["company"])
        },
        methods: {
            addVisitant() {
                this.visitors.push({
                    name: "",
                    docNumber: "",
                    phone: "",
                    maskedPhone: ""
                });
            },
            async save() {
                const isFormValid = await this.$validator._base.validateAll();
                if (!isFormValid) {
                    return;
                }
                const project = _.clone(this.project);
                const participantIdx = this.project.participants.findIndex(
                    p => p.companyId === this.company._id
                );
                this.removeSpecialCharactersVisitorsPhone();
                project.participants[participantIdx].visitors = this.visitors;
                project.participants[participantIdx].visitation =
                    this.agreedVisitation === "true"
                        ? {
                            date: new Date(),
                            agreed: true
                        }
                        : {date: new Date(), agreed: false};
                await this.$store.dispatch(actionTypes.UPDATE_PARTICIPANT, {
                    projectId: this.project._id,
                    companyId: this.project.participants[participantIdx].companyId,
                    participant: project.participants[participantIdx]
                });
                await this.$store.dispatch(actionTypes.FIND_PROJECT_BY_ID, project._id);
                this.showSuccess();
                this.$emit("updatePhases");
            },
            onChangeAgreedVisitation(val) {
                if (val === "true") this.addVisitant();
                else this.visitors = [];
            },
            removeSpecialCharactersVisitorsPhone() {
                for (let i = 0; i < this.visitors.length; i++) {
                    this.visitors[i].phone = TextUtils.removeSpecialCharacters(
                        this.visitors[i].maskedPhone
                    );
                }
            },
            removeVisitant(index) {
                this.visitors.splice(index, 1);
            },
            copyPhoneToMaskedPhone() {
                for (let i = 0; i < this.visitors.length; i++) {
                    this.visitors[i].maskedPhone = this.visitors[i].phone;
                }
            },
            getAgreedVisitation(participantIdx) {
                if (!this.project.participants[participantIdx].visitation) return null;
                return this.project.participants[participantIdx].visitation.agreed
                    ? "true"
                    : "false";
            }
        },
        mounted() {
            const participantIdx = this.project.participants.findIndex(
                p => p.companyId === this.company._id
            );
            this.visitors = this.project.participants[participantIdx].visitors;
            this.copyPhoneToMaskedPhone();
            this.agreedVisitation = this.getAgreedVisitation(participantIdx);
        }
    };
</script>

<style lang="stylus">
    .fa-detail-project-visitation

        .visitacao-aceite
            width: 100%;
            display: flex !important;
            justify-content: center !important;

        .botao-excluir
            cursor: pointer;

            &:hover
                color var(--v-error-lighten2) !important;
        .listagem
            max-height: 350px;
            padding: 20px !important;
            border: 1px dashed #ccc;
            margin-bottom: 10px !important;
            width: 100% !important;
            overflow-y: auto;
            margin-right: 10px;

        .az-actions-form
            margin: 0 !important;
            padding: 0 !important;
            width: 100% !important;

        .area-inferior
            height: 50px;
            border-top: 1px solid #ddd;
</style>
