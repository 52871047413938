import store from '@/commons/store'
import {mutationTypes} from '@/commons/constants'
import i18n from '@/commons/i18n'

class SetMenuAvatar {
    async execute() {
        return new Promise((resolve) => {
            const locale = i18n.locale
            store.commit(mutationTypes.SET_MENU_AVATAR, {
                1: {
                    title: 'Editar Perfil',
                    icon: 'person',
                    path: `/${locale}/participant/company`
                }
            })
            resolve()
        })
    }
}

export default new SetMenuAvatar()
