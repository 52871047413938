<template>
    <div class="fa-detail-project">
        <v-layout style="height: 30px;">
            <v-flex xs6>
                <az-back-button :route="{name: 'participant'}" :text="$t('project.participant.detail.back')"/>
            </v-flex>
            <v-flex xs6>
                <div class="text-md-right font-regular mt-2 mr-1" v-if="!amIDropout">
                    <v-btn style="text-transform: none;" color="primary" text depressed @click="confirmBeforeGiveUp">
                        <v-icon>delete</v-icon>
                        {{$t('project.participant.detail.giveUpProject')}}
                    </v-btn>
                </div>
            </v-flex>
        </v-layout>
        <az-container>
            <div>
                <div class="az-form-content" style="padding: 0 !important">
                    <v-layout>
                        <div class="fa-timeline">
                            <div class="item-sem-status">
                                <div class="barra"></div>
                                <div class="detalhe">
                                    <div class="imagem">
                                        <v-img :contain=true
                                               :src="getSrcLogoCustomer()">
                                        </v-img>
                                    </div>
                                    <div class="dados">
                                        <div class="titulo">{{project.name}}</div>
                                        <div class="ver-mais" @click="dialogInfoProject = true">+ detalhes</div>
                                    </div>
                                </div>
                            </div>
                            <div class="item" @click="selectPhase('1')">
                                <div v-bind:class="{'barra-selecionada': openPhase === '1', 'barra': openPhase !== '1',
                                'barra-concluida': isPhaseCompleted('1'), 'barra-expirada': isPhaseNotCompletedAndExpired('1', project.calendar.kickoff)}"></div>
                                <div v-bind:class="{'detalhe-selecionado' : openPhase === '1', 'detalhe': openPhase !== '1'}"
                                     style="border-top: 2px solid #ddd">
                                    <div class="titulo">
                                        1. Abertura
                                    </div>
                                    <div class="data">
                                        <v-icon>calendar_today</v-icon>
                                        até {{project.calendar.kickoff | azDate('DD/MM/YYYY HH:mm')}}
                                    </div>
                                </div>
                            </div>
                            <div class="item" @click="selectPhase('2')">
                                <div v-bind:class="{'barra-selecionada': openPhase === '2', 'barra': openPhase !== '2',
                                'barra-concluida': isPhaseCompleted('2'), 'barra-expirada': isPhaseNotCompletedAndExpired('2', project.calendar.visitation)}"></div>
                                <div v-bind:class="{'detalhe-selecionado' : openPhase === '2', 'detalhe': openPhase !== '2'}"
                                     style="border-top: 2px solid #ddd">
                                    <div class="titulo">
                                        2. {{$t('project.participant.detail.timeline.visitation')}}
                                    </div>
                                    <div class="data">
                                        <v-icon>calendar_today</v-icon>
                                        até {{project.calendar.visitation | azDate('DD/MM/YYYY HH:mm')}}
                                    </div>
                                </div>
                            </div>
                            <div class="item" @click="selectPhase('3')">
                                <div v-bind:class="{'barra-selecionada': openPhase === '3', 'barra': openPhase !== '3',
                                'barra-concluida': isPhaseCompleted('3'), 'barra-expirada': isPhaseNotCompletedAndExpired('3', project.calendar.basicDocuments)}"></div>
                                <div v-bind:class="{'detalhe-selecionado' : openPhase === '3', 'detalhe': openPhase !== '3'}"
                                     style="border-top: 2px solid #ddd">
                                    <div class="titulo">
                                        3. {{$t('project.participant.detail.timeline.documentation')}}
                                    </div>
                                    <div class="data">
                                        <v-icon>calendar_today</v-icon>
                                        até {{project.calendar.basicDocuments | azDate('DD/MM/YYYY HH:mm')}}
                                    </div>
                                </div>
                            </div>
                            <div class="item" @click="selectPhase('4')">
                                <div v-bind:class="{'barra-selecionada': openPhase === '4', 'barra': openPhase !== '4',
                                'barra-concluida': isPhaseCompleted('4'), 'barra-expirada': isPhaseNotCompletedAndExpired('4', project.calendar.additionalDocuments)}"></div>
                                <div v-bind:class="{'detalhe-selecionado' : openPhase === '4', 'detalhe': openPhase !== '4'}"
                                     style="border-top: 2px solid #ddd">
                                    <div class="titulo">
                                        4. {{$t('project.participant.detail.timeline.quotation')}}
                                    </div>
                                    <div class="data">
                                        <v-icon>calendar_today</v-icon>
                                        até {{project.calendar.additionalDocuments | azDate('DD/MM/YYYY HH:mm')}}
                                    </div>
                                </div>
                            </div>
                            <div class="item" @click="selectPhase('5')">
                                <div v-bind:class="{'barra-selecionada': openPhase === '5', 'barra': openPhase !== '5',
                                'barra-concluida': isPhaseCompleted('5'), 'barra-expirada': isPhaseNotCompletedAndExpired('5', project.calendar.negociation)}"></div>
                                <div v-bind:class="{'detalhe-selecionado' : openPhase === '5', 'detalhe': openPhase !== '5'}"
                                     style="border-top: 2px solid #ddd">
                                    <div class="titulo">
                                        5. {{$t('project.participant.detail.timeline.negotiation')}}
                                    </div>
                                    <div class="data">
                                        <v-icon>calendar_today</v-icon>
                                        até {{project.calendar.negociation | azDate('DD/MM/YYYY HH:mm')}}
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="barra-sem-status"></div>
                                <div class="detalhe" style="border-top: 2px solid #ddd"></div>
                            </div>
                        </div>
                        <div style="width:100%">
                            <div class="area-interna">
                                <detail-project-terms @checkAndUpdatePhases="checkAndUpdatePhases()"
                                                      v-if="openPhase === '1'"></detail-project-terms>
                                <detail-project-visitation :project="project"
                                                           @updatePhases="checkAndUpdatePhases()"
                                                           v-if="openPhase === '2'"></detail-project-visitation>
                                <detail-project-document-table :project="project" v-if="openPhase === '3'"/>
                                <detail-project-quotation :project="project" v-if="openPhase === '4'"
                                                          @updatePhases="checkAndUpdatePhases()"/>
                                <detail-project-negotiation :project="project" v-if="openPhase === '5'"
                                                            @updatePhases="checkAndUpdatePhases()"/>

                            </div>
                        </div>
                    </v-layout>
                </div>
            </div>
        </az-container>
        <dropout-project-modal
                v-if="dialogGiveUp"
                @save="giveUpProject"
                @cancel="hideDialogGiveUp"
        ></dropout-project-modal>
        <general-info-project-modal
                :project="project"
                v-if="dialogInfoProject"
                @hideDialog="dialogInfoProject = false"
        ></general-info-project-modal>
    </div>
</template>

<script>
    import moment from 'moment-timezone'
    import {actionTypes, mutationTypes} from '@/commons/constants'
    import {mapState} from 'vuex'
    import DetailProjectTerms from "./DetailProjectTerms";
    import DetailProjectVisitation from "./DetailProjectVisitation";
    import DetailProjectDocumentTable from "./DetailProjectDocumentTable";
    import DetailProjectQuotation from "./DetailProjectQuotation";
    import DetailProjectNegotiation from "./DetailProjectNegotiation";
    import DropoutProjectModal from "../search/DropoutProjectModal";
    import GeneralInfoProjectModal from "./GeneralInfoProjectModal";

    export default {
        name: 'project-detail',
        components: {
            GeneralInfoProjectModal,
            DropoutProjectModal,
            DetailProjectNegotiation,
            DetailProjectQuotation,
            DetailProjectDocumentTable,
            DetailProjectVisitation,
            DetailProjectTerms
        },
        data() {
            return {
                openPhase: '1',
                completedPhases: [],
                dialogGiveUp: false,
                dialogInfoProject: false
            }
        },
        computed: {
            areTermsAgreed() {
                if (!this.project.participants)
                    return false
                const participantIdx = this.project.participants.findIndex(p => p.companyId === this.company._id)
                if (participantIdx !== -1 && this.project.participants[participantIdx].termsAndConditions) {
                    return true
                }
                return false
            },
            areVisitationDone() {
                if (!this.project.participants)
                    return false
                const participantIdx = this.project.participants.findIndex(p => p.companyId === this.company._id)
                if (participantIdx !== -1 && this.project.participants[participantIdx].visitation) {
                    return true
                }
                return false
            },
            areQuotationUploaded() {
                if (!this.project.participants)
                    return false
                const participantIdx = this.project.participants.findIndex(p => p.companyId === this.company._id)
                if (participantIdx !== -1 && this.project.participants[participantIdx].quotationSlug) {
                    return true
                }
                return false
            },
            areNegotiationUploaded() {
                if (!this.project.participants)
                    return false
                const participantIdx = this.project.participants.findIndex(p => p.companyId === this.company._id)
                if (participantIdx !== -1 && this.project.participants[participantIdx].negotiation) {
                    return true
                }
                return false
            },
            amIParticipating() {
                if (!this.project.participants)
                    return false
                const participantIdx = this.project.participants.findIndex(p => p.companyId === this.company._id)
                return participantIdx !== -1;
            },
            amIDropout() {
                if (!this.project.dropouts)
                    return false
                const dropoutIdx = this.project.dropouts.findIndex(p => p.companyId === this.company._id)
                return dropoutIdx !== -1;
            },
            ...mapState(['project', 'company'])
        },
        methods: {
            cleanProjectData() {
                this.$store.commit(mutationTypes.SET_PROJECT, {
                    calendar: {},
                    categories: [],
                    locations: [],
                    termsAndConditions: []
                })
            },
            async findProjectById() {
                try {
                    await this.$store.dispatch(actionTypes.FIND_PROJECT_BY_ID, this.$route.params.id)
                } catch (e) {
                    this.showErrorFrontEnd('project.participant.detail.errors.projectNotFound')
                    this.$router.push({name: 'home'})
                }
            },
            checkProjectStatus() {
                if (!this.isProjectPublished()) {
                    this.showErrorFrontEnd('project.participant.detail.errors.projectUnavailable')
                    this.$router.push({name: 'participant'})
                }
            },
            isProjectPublished() {
                return this.project.status === 'PUBLISHED'
            },
            async checkAndUpdatePhases() {
                this.completedPhases = []
                if (this.areTermsAgreed) {
                    this.completedPhases.push('1')
                }
                if (this.areVisitationDone) {
                    this.completedPhases.push('2')
                }
                const reqDocsUploaded = await this.requiredDocumentsHaveBeenUploaded()
                if (reqDocsUploaded) {
                    this.completedPhases.push('3')
                }
                if (this.areQuotationUploaded) {
                    this.completedPhases.push('4')
                }
                if (this.areNegotiationUploaded) {
                    this.completedPhases.push('5')
                }
            },
            getSrcLogoCustomer() {
                if (this.project.customer)
                    return this.buildDownloadLink(this.project.customer.logoSlug)
                return ''
            },
            selectPhase(phase) {
                if (!this.isPhaseAllowedToBeAccessed(phase))
                    return
                this.openPhase = phase
            },
            openCurrentPhase() {
                const allPhases = ['1', '2', '3', '4', '5']
                if (!this.amIParticipating)
                    this.openPhase = '1'
                else if (this.completedPhases.length === allPhases.length)
                    this.openPhase = '5'
                else
                    for (const phaseIdx in allPhases)
                        if (this.completedPhases.indexOf(allPhases[phaseIdx]) === -1) {
                            this.openPhase = allPhases[phaseIdx]
                            break
                        }
            },
            isPhaseAllowedToBeAccessed(phase) {
                return this.amIParticipating && (this.areTermsAgreed || phase === '1')
            },
            isPhaseCompleted(phase) {
                return this.amIParticipating && this.completedPhases.indexOf(phase) !== -1
            },
            isPhaseNotCompletedAndExpired(phase, date) {
                const now = moment()
                const expDate = moment(date)
                return !this.isPhaseCompleted(phase) && now.isAfter(expDate)
            },
            async findDocumentsByCompanyId() {
                try {
                    return this.$store.dispatch(actionTypes.FIND_DOCUMENTS_BY_COMPANY_ID, this.company._id)
                } catch (e) {
                    this.showErrorFrontEnd('project.participant.documents.errors.findDocs')
                }
            },
            async requiredDocumentsHaveBeenUploaded() {
                const companyDocs = await this.findDocumentsByCompanyId()
                const mandatoryDocs = this.project.requiredDocuments.filter(doc => doc.mandatory)
                for (let i = 0; i < mandatoryDocs.length; i++) {
                    const docIdx = companyDocs.findIndex(d => d.type === mandatoryDocs[i].type)
                    if (docIdx === -1 || (!companyDocs[docIdx].exemption && companyDocs[docIdx].status !== 'APPROVED')) {
                        return false
                    }
                }
                return true
            },
            hideDialogGiveUp() {
                this.dialogGiveUp = false
            },
            confirmBeforeGiveUp() {
                this.dialogGiveUp = true
            },
            async giveUpProject(dropoutReason) {
                await this.$store.dispatch(actionTypes.REMOVE_PARTICIPANT, {
                    projectId: this.project._id,
                    companyId: this.company._id
                })
                await this.$store.dispatch(actionTypes.ADD_PARTICIPANT_TO_DROPOUTS, {
                    projectId: this.project._id,
                    companyId: this.company._id,
                    reason: dropoutReason
                })
                this.showSuccess()
                this.hideDialogGiveUp()
                this.findProjectById()
                this.completedPhases = []
                this.openPhase = '1'
                this.changeRoute()
            },
            changeRoute () {
                this.$router.push({name: 'participantMyProjects'})
            }
        },
        async mounted() {
            this.closeAsideMenu()
            this.cleanProjectData()
            await this.findProjectById()
            this.checkProjectStatus()
            await this.checkAndUpdatePhases()
            this.openCurrentPhase()
        }
    }
</script>

<style lang="stylus">
    .fa-detail-project
        .fa-timeline
            display block
            padding-left 10px
            background-color #eee !important
            .item-sem-status
                background-color #e5e5e5 !important
                height 100px
                display flex
                .dados
                    display block
                    margin-left 10px
                    .ver-mais
                        font-size 12px
                        color #aaa
                        cursor pointer
                    .titulo
                        width 80px
                        text-overflow ellipsis
                        white-space nowrap
                        overflow hidden
                        margin-top 10px
                        font-size 16px
                        color var(--v-primary-base)
                        font-weight bold
                .imagem
                    width 60px
                    height 60px
                    border 1px dashed #aaa
                .detalhe
                    width 170px
                    height 100px
                    background-color #e5e5e5
                    padding 20px 10px
                    display flex
                .barra
                    width 3px
                    background-color #ddd
                    height 100px
            .item
                background-color #e5e5e5 !important
                height 100px
                display flex
                .data
                    font-size 12px
                    color #aaa
                    i
                        font-size 14px
                        color #aaa
                        margin-right 5px
                .detalhe
                    width 190px
                    height 100px
                    background-color #e5e5e5
                    padding 28px 10px 10px 25px
                    cursor pointer
                    .titulo
                        font-size 16px
                        color #999
                        font-weight bold
                .detalhe-selecionado
                    width 190px
                    height 100px
                    background-color #ddd
                    padding 28px 10px 10px 25px
                    cursor pointer
                    .titulo
                        font-size 16px
                        color var(--v-primary-base)
                        font-weight bold
                .detalhe-selecionado::before
                    margin-left 213px
                    margin-top 12px
                    content ''
                    width 0
                    height 0
                    border-top 10px solid transparent
                    border-bottom 10px solid transparent
                    border-right 10px solid white
                    left 0
                    position absolute
                .barra-sem-status
                    width 3px
                    background-color #ddd
                    height 100px
                .barra
                    width 3px
                    background-color #ddd
                    height 100px
                .barra::before
                    content ''
                    border 2px solid #ddd
                    width 30px
                    height 30px
                    background-color #fff
                    position absolute
                    border-radius 50%
                    margin-top 35px
                    left 17px
                .barra-selecionada
                    width 3px
                    background-color #aaa
                    height 100px
                .barra-selecionada::before
                    content ''
                    border 2px solid #aaa
                    width 30px
                    height 30px
                    background-color #fff
                    position absolute
                    border-radius 50%
                    margin-top 35px
                    left 17px
                .barra-concluida
                    background-color #66BB6A
                .barra-concluida::before
                    content '✔'
                    font-size 18px
                    color white
                    display flex
                    justify-content center
                    background-color #66BB6A
                    border-color #66BB6A
                .barra-expirada
                    background-color #EF5350
                .barra-expirada::before
                    content '!'
                    font-size 18px
                    color white
                    display flex
                    justify-content center
                    background-color #EF5350
                    border-color #EF5350

        .item-selecionado
            color white !important
            font-weight bold
        .v-timeline:before
            background rgba(255, 255, 255, .5) !important
        .item-com-data
            .v-timeline-item__body
                top 10px !important
        .v-timeline--dense .v-timeline-item__body
            max-width calc(100% - 45px) !important
            span
                color rgba(255, 255, 255, 0.6)
</style>
