<template>
    <v-card-text>
        <v-container>
            <titlebar :title="$t('register.title')" :subtitle="$t('register.subtitle')"/>
            <v-form v-on:submit.prevent="submitForm" autocomplete="off">

                <v-text-field prepend-icon="person" name="fullname" type="text" maxlength="255"
                              :data-vv-as="$t('register.fullname')"
                              :placeholder="$t('register.fullname')"
                              :error-messages="errors.collect('fullname')"
                              v-model="value.name"
                              v-validate="'required'"/>

                <v-text-field prepend-icon="email" name="email" type="text" maxlength="255"
                              :data-vv-as="$t('register.email')"
                              :placeholder="$t('register.email')"
                              :error-messages="errors.collect('email')"
                              v-model="value.email"
                              disabled
                              v-validate="'required|email'"/>

                <v-text-field prepend-icon="phone" name="phoneNumber1" type="text"
                              :data-vv-as="$t('register.phones.number1')"
                              :placeholder="$t('register.phones.number1')"
                              :error-messages="errors.collect('phoneNumber1')"
                              v-mask="JSON.parse($t('register.phones.mask'))"
                              v-model="value.phoneNumber1"
                              v-validate="'required'"/>

                <v-text-field prepend-icon="phone" name="phoneNumber2" type="text"
                              :data-vv-as="$t('register.phones.number2')"
                              :placeholder="$t('register.phones.number2')"
                              :error-messages="errors.collect('phoneNumber2')"
                              v-mask="JSON.parse($t('register.phones.mask'))"
                              v-model="value.phoneNumber2"/>

                <v-text-field prepend-icon="lock" name="password" type="password" maxlength="20"
                              :data-vv-as="$t('register.password')"
                              :placeholder="$t('register.password')"
                              :error-messages="errors.collect('password')"
                              v-model="value.password"
                              v-validate="'required'"
                              ref="passwordRef"/>

                <v-text-field prepend-icon="lock" name="confirmation" type="password" maxlength="20"
                              :data-vv-as="$t('register.confirmation')"
                              :placeholder="$t('register.confirmation')"
                              :error-messages="errors.collect('confirmation')"
                              v-model="value.confirmation"
                              v-validate="'required|confirmed:passwordRef'"/>

                <v-btn color="success" class="mt-4" type="submit" block large
                       :loading="$store.state.loki.isLoading"
                       :disabled="(value.submitted && errors.any()) || $store.state.loki.isLoading">
                    {{$t('register.submit')}}
                </v-btn>
            </v-form>
        </v-container>
    </v-card-text>
</template>

<script>
    import Titlebar from '../../commons/Titlebar'
    import {TextUtils} from '@/commons/utils'

    export default {
        name: 'register-form',
        components: {
            Titlebar
        },
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        methods: {
            removeSpecialCharacteres(phone) {
                if (phone) {
                    const unmaskedValue = TextUtils.removeSpecialCharacters(phone)
                    return unmaskedValue
                } else {
                    return ''
                }
            },
            formatPhoneFields() {
                this.value.phoneNumber1 = this.removeSpecialCharacteres(this.value.phoneNumber1)
                this.value.phoneNumber2 = this.removeSpecialCharacteres(this.value.phoneNumber2)
            },
            async submitForm() {
                this.value.submitted = true
                const isValid = await this.$validator.validateAll()
                if (isValid) {
                    this.formatPhoneFields()
                    this.$emit('submit')
                }
            }
        }
    }
</script>
